export default function ShowDate(date) {
  //   let date = new Date();

  let str = '';

  //   年
  let year = date.getFullYear();
  str += year;

  //   月
  str += '-';
  let month = date.getMonth() + 1;
  if (month < 10) {
    str += '0';
  }
  str += month;

  //   日
  str += '-';
  let day = date.getDate();
  if (day < 10) {
    str += '0';
  }
  str += day;

  return str;
}



export const isTest = false;

export const defaultAvatar = `${process.env.PUBLIC_URL}/img/user/default-avatar.png`;


export const anchorDefaultAvatar = `${process.env.PUBLIC_URL}/img/user/anchor-default-avatar.png`;

export const liveGoodsOnTableImg = process.env.PUBLIC_URL + '/img/live/goods-on-table.png'

export const liveTableImg = process.env.PUBLIC_URL + '/img/live/table.png'

export const liveFloatIcon = process.env.PUBLIC_URL + '/img/live/float-icon.png'

export const goodsDefaultImg = process.env.PUBLIC_URL + '/img/goods/goods-default-img.png'
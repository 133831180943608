import React from 'react';
import './step.scss';
import { inject, observer } from 'mobx-react';

const Step = props => {
  const { merchant, className } = props;

  const { menuStep, currentStepIndex } = merchant;

  const isActive = values => {
    return values.indexOf(currentStepIndex) !== -1;
  };

  return (
    <div className={`glx-merchant-step ${className}`}>
      <div className='floor-1'>
        {menuStep.map((item, i) => {
          const { label, show, values } = item;

          return (
            <div className={`item ${isActive(values) && 'active'}`} key={i}>
              {label}
            </div>
          );
        })}
      </div>
      <div className='floor-2'>
        {menuStep.map((item, i) => {
          const { label, show, values } = item;

          return (
            <div className={`item ${isActive(values) && 'active'}`} key={i}>
              {isActive(values) ? (
                <div className='dot-wrapper'>
                  <div className='dot'></div>
                </div>
              ) : (
                <div className='dot'></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default inject('merchant')(observer(Step));

import React from 'react';
import './hot-search.scss';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

function HotSearch(props) {
  const { search: store } = props;

  let history = useHistory();

  function renderList() {
    return (
      <div className='list-floor'>
        {store.hotWordList.map((item, i) => {
          const { id, hotWord } = item;
          return (
            <div
              className='item'
              key={id}
              onClick={() => {
                store.keyword = hotWord;
                history.push({ pathname: '/search/result' });
              }}
            >
              {hotWord}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className='hot-search'>
      <div className='title-floor'>
        <div className='title'>热门搜索</div>
      </div>
      {renderList()}
    </div>
  );
}

export default inject('search')(observer(HotSearch));

import React, {useCallback, useEffect, useMemo} from 'react'
import 'styles/orders/add-address.scss';
import {observer, inject} from 'mobx-react'
import Back from 'components/common/back/Back'
import * as antdMobile from 'antd-mobile';
import * as antd from 'antd';
import * as api from 'utils/api/api';
import {reaction} from "mobx";
import {createForm} from 'rc-form';
import {useHistory} from 'react-router-dom';

const AddAddress = ({addAddress: store, form}) => {

    const history = useHistory();

    const fetchProvinceList = useCallback(() => {
        api.areaList({parentId: -1}).then(list => {
            store.provinceList = list;

            if (list.length) {
                store.currentProvinceId = list[0].id;
            }
        })
    }, [])
    useEffect(() => {
        reaction(() => store.currentProvinceId, currentProvinceId => {
            if (currentProvinceId) {
                api.areaList({parentId: currentProvinceId}).then(list => {
                    store.cityList = list;

                    if (list.length) {
                        store.currentCityId = list[0].id;
                    }
                })
            }
        })
        reaction(() => store.currentCityId, currentCityId => {
            if (currentCityId) {
                api.areaList({parentId: currentCityId}).then(list => {
                    store.districtList = list;

                    if (list.length) {
                        store.currentDistrictId = list[0].id;
                    }
                })
            }
        })
    }, []);

    const addressData = useMemo(() => {
        let cityList = store.cityList.map(item => ({value: item.id, label: item.name}));

        if (store.currentCityId) {
            cityList = cityList.map(item => {
                if (store.currentCityId === item.value) {
                    item.children = store.districtList.map(item => ({value: item.id, label: item.name}));
                }

                return item;
            });
        }

        let provinceList = store.provinceList.map(item => ({value: item.id, label: item.name}));

        if (store.currentProvinceId) {
            provinceList = provinceList.map(item => {
                if (store.currentProvinceId === item.value) {
                    item.children = cityList;
                }

                return item;
            });
        }

        return provinceList;
    }, [store.provinceList, store.cityList, store.districtList]);

    useEffect(() => {
        fetchProvinceList();
    }, [fetchProvinceList]);

    const onPickerChange = ([provinceId, cityId, districtId]) => {
        if (provinceId !== store.currentProvinceId) {
            store.currentProvinceId = provinceId;
        }

        if (cityId !== store.currentCityId) {
            store.currentCityId = cityId;
        }

        if (districtId !== store.currentDistrictId) {
            store.currentDistrictId = districtId;
        }
    }

    const ongSelectAddress = ([provinceId, cityId, districtId]) => {

        if (provinceId) {
            store.provinceId = provinceId
        }

        if (cityId) {
            store.cityId = provinceId
        }

        if (districtId) {
            store.districtId = districtId
        }
    }
    const onSelectAddressCancel = () => {
        store.currentProvinceId = store.provinceId;
        store.currentCityId = store.cityId;
        store.currentDistrictId = store.districtId;
    }

    const defaultButton = useMemo(() => {
        let text = "设为默认";

        if (store.isDefault) {
            text = "取消默认";
        }

        const toggleIsDefault = () => {
            store.isDefault = !store.isDefault;
        }

        return <button className="is-default-btn" onClick={toggleIsDefault}>{text}</button>
    }, [store.isDefault])

    const onSubmit = () => {
        form.validateFields((error, value) => {
            if (!error) {
                const {addressField, detailInfo, name, telNumber} = value;
                const [provinceId, cityId, districtId] = addressField;

                const province = store.provinceList.find(item => item.id === provinceId)?.name;
                const city = store.cityList.find(item => item.id === cityId)?.name;
                const district = store.districtList.find(item => item.id === districtId)?.name;
                const {isDefault} = store;

                api.addAddress({name, telNumber, province, city, district, detailInfo, isDefault}).then(() => {
                    antd.message.success("添加成功");
                    history.goBack();
                });
            }
        });
    }

    return (
        <div className='glx-add-address'>
            <Back className='top'>
                <div className='title'>新建地址</div>
            </Back>
            <div className="form">
                <antdMobile.List>
                    <antdMobile.InputItem {...form.getFieldProps('name')}
                                          clear
                                          placeholder='请输入收货人姓名'
                    >
                        收货人
                    </antdMobile.InputItem>
                    <antdMobile.InputItem {...form.getFieldProps('telNumber')}
                                          clear
                                          placeholder='请输入收货人手机号码'
                    >
                        手机号码
                    </antdMobile.InputItem>
                    <antdMobile.Picker extra="请选择省市区"
                                       {
                                           ...form.getFieldProps('addressField', {
                                               initialValue: [store.currentProvinceId, store.currentCityId, store.currentDistrictId]
                                           })
                                       }
                                       data={addressData}
                                       title="地址"
                                       onOk={ongSelectAddress}
                        // onChange={onPickerChange}
                                       onPickerChange={onPickerChange}
                                       onDismiss={onSelectAddressCancel}
                    >
                        <antdMobile.List.Item arrow="horizontal">省市区</antdMobile.List.Item>
                    </antdMobile.Picker>
                    <antdMobile.TextareaItem {...form.getFieldProps('detailInfo')}
                                             clear
                                             title='详细地址'
                                             placeholder="请输入详细地址"
                                             rows={3}
                    />
                    <antdMobile.List.Item extra={defaultButton}>
                        设置为默认地址
                        <antdMobile.List.Item.Brief>每次下单会默认使用改地址</antdMobile.List.Item.Brief>
                    </antdMobile.List.Item>
                </antdMobile.List>
            </div>
            <div className="btn-floor">
                <button className="submit" onClick={onSubmit}>保存地址</button>
            </div>
        </div>
    )
}

export default createForm()(inject('addAddress')(observer(AddAddress)))

import React, {useCallback, useEffect, useMemo} from 'react'
import './order-deliver.scss'
import moment from 'moment'
import {inject, observer} from "mobx-react";
import OrderStatusEnum from "enum/OrderStatusEnum";
import {useHistory} from 'react-router-dom';
import * as api from 'utils/api/api';

const OrderDeliver = ({order: store, selectOrderAddress,fetchInfo}) => {

    const {delivery} = store;
    const history = useHistory();

    const lastTrace = useMemo(() => {
        if (!delivery || !delivery.traceList || !delivery.traceList.length) {
            return null;
        }

        return delivery.traceList[delivery.traceList.length - 1];
    }, [delivery])

    const toSelectAddress = () => {
        history.push('/orders/select-address');
    }

    const modifyAddress = useCallback(() => {
        console.log('order-info-select-address')
        const address = selectOrderAddress.currentAddress;
        if (!address) {
            return;
        }

        const orderAddress = {
            name: address.name,
            phone: address.telNumber,
            province: address.province,
            city: address.city,
            district: address.district,
            address: address.detailInfo
        }
        api.modifyOrder({orderId: store.id, orderAddress}).then(() => {
            fetchInfo();
        })
    }, [selectOrderAddress.currentAddress])
    useEffect(() => {
        modifyAddress();
    }, [modifyAddress])

    return (
        <div className='order-deliver'>
            {
                store.status === OrderStatusEnum.HAS_DELIVERED.value &&
                lastTrace?.acceptStation &&
                lastTrace?.acceptTime &&
                <div className='order-deliver-info'>
                    <p className='order-deliver-info__text'>{lastTrace?.acceptStation}</p>
                    <p className='order-deliver-info__time'>
                        {moment(lastTrace?.acceptTime).format('YYYY-MM-DD hh:mm::ss')}
                    </p>
                </div>
            }
            {

                store.orderAddress ?
                    <div className='order-deliver-address'>
                        <div>
                            <span className='order-deliver-address__name'>{store.orderAddress?.name}</span>
                            <span className='order-deliver-address__phone'>{store.orderAddress?.phone}</span>
                        </div>
                        <p className='order-deliver-address__address'>
                            地址:{store.orderAddress?.province}{store.orderAddress?.city}{store.orderAddress?.district}{store.orderAddress?.address}
                        </p>
                    </div> :
                    <button className="add-address" onClick={toSelectAddress}>选择地址</button>
            }
        </div>
    )
}

export default inject('order', 'selectOrderAddress')(observer(OrderDeliver))

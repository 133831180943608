import React from "react";
import css from 'styles/home/homeSearch.module.scss'
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";
import {useHistory} from "react-router-dom";

class SearchStore {
    keyword = ''

    setKeyword(value) {
        this.keyword = value
    }

    constructor() {
        makeAutoObservable(this)
    }
}

const searchStore = new SearchStore()

const Index = ({city}) => {
    const history = useHistory()

    const toSearch = () => {
        history.push({
            pathname: '/search'
        });
    };

    // todo to cart
    const toCart = () => {
        console.log('to cart')
    }

    const toPersonal = () => {
        history.push({
            pathname: '/my'
        })
    }

    return (
        <div className={css.index}>
            <div className={css.iconLocation}>
                <img src={require('images/home/位置.png')} alt=""/>
            </div>
            <div className={css.cityWrapper}>
                <div className={css.city}>
                    {
                        !!city && city
                    }
                </div>
            </div>
            <div className={css.inputWrapper}>
                <div className={css.iconWrapper}>
                    <img src={require('images/home/搜索.png')} alt=""/>
                </div>
                <input
                    type="text"
                    className={css.input}
                    // value={searchStore.keyword}
                    placeholder='搜索商品'
                    onFocus={toSearch}
                    // onChange={e => searchStore.setKeyword(e.currentTarget.value)}
                />
            </div>
            <div className={css.cartWrapper} onClick={toCart}>
                <img src={require('images/home/购物车.png')} alt=""/>
            </div>
            <div className={css.personalWrapper} onClick={toPersonal}>
                <img src={require('images/home/个人中心.png')} alt=""/>
            </div>
        </div>
    )
}

export default observer(Index)
import React from 'react';
import './history-search.scss';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

function HistorySearch(props) {
  const { search: store } = props;

  let history = useHistory();

  function renderList() {
    return (
      <div className='list-floor'>
        {store.historyWordList.map((item, i) => {
          const { id, userId, word } = item;
          return (
            <div
              className='item'
              key={id}
              onClick={() => {
                store.keyword = word;
                history.push({ pathname: '/search/result' });
              }}
            >
              {word}
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className='history-search'>
      <div className='title-floor'>
        <div className='title'>最近搜索</div>
      </div>
      {renderList()}
    </div>
  );
}

export default inject('search')(observer(HistorySearch));

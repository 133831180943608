import React from 'react';
import { NavLink } from 'react-router-dom';

import HeaderIcon from '../../../images/news/header.png';
import BackIcon from '../../../images/common/dark-back.png';
// import NewsEntries from '../../../constants/newsConst';

import './news-header.scss';
import { inject, observer } from 'mobx-react';
const NewsHeader = props => {
  const { news } = props;
  return (
    <div className='news-header'>
      <NavLink to='/home'>
        <img className='news-header__back' src={BackIcon} alt='' />
      </NavLink>
      <img className='news-header__img' src={HeaderIcon} alt='' />
      <div className='news-header__tabs'>
        {news.categoryList.map((item, i) => {
          const { id, name, isUrl } = item;
          const active = id === news.categoryId;
          return (
            <span
              className={`news-header__tab${
                active ? ' news-header__tab--active' : ''
              }`}
              key={i}
            >
              <NavLink
                to={{
                  pathname: '/news',
                  search: `?categoryId=${id}`
                }}
                replace
              >
                {name}
              </NavLink>
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default inject('news')(observer(NewsHeader));

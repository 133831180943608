import {makeAutoObservable} from "mobx";
import Gender from "enum/Gender";
import GlxQueueStore from '../../../utils/GlxQueueStore'

class GroupStore {
    memberList = [];
    info = undefined;

    constructor() {
        makeAutoObservable(this)
    }
}

class ImUserStore {
    id = 0
    userId = 0
    nickname = ''
    avatar = ''
    gender = Gender.unknown

    constructor() {
        makeAutoObservable(this)
    }
}

class LiveStore {
    group = new GroupStore();
    messageList = [];
    bulletComment = ''

    imUserList = [];
    imUser = new ImUserStore()

    liveGoodsList = []
    curLiveGoodsIdx = 0;

    liveRecord = null;
    liveContext = null;

    aivideoQueue = new GlxQueueStore()

    constructor() {
        makeAutoObservable(this)
    }

    get curLiveGoods() {
        return this.liveGoodsList?.[this.curLiveGoodsIdx]
    }

    // get curGoods() {
    //     return this.liveGoodsList?.[1]
    // }

    setCurLiveGoodsIdxByLiveGoodsId(id) {
        const curIdx = this.liveGoodsList.findIndex(item => item.id === id);
        if (curIdx != -1) {
            this.curLiveGoodsIdx = curIdx
        }
    }

    setLiveRecord(value) {
        this.liveRecord = value;
    }


    setLiveGoodsList(value) {
        this.liveGoodsList = value
    }

    get heat() {
        return this.imUserList.length * 12345
    }

    get liveGoodsCount() {
        return this.liveGoodsList.length
    }

    updateImUser(imUser) {
        this.imUser.id = imUser.id
        this.imUser.userId = imUser.userId
        this.imUser.nickname = imUser.nickname
        this.imUser.avatar = imUser.avatar
        this.imUser.gender = imUser.gender
    }

    putMessage(message) {
        // this.messageList = [...this.messageList, message]
        this.messageList.push(message)
    }

}


export default LiveStore
import {useLocation} from "react-router-dom";
import isNumber from "utils/IsNumber";

function useQuery() {
    let urlSearchParams = new URLSearchParams(useLocation().search);

    let param = {};

    for (let [key, value] of urlSearchParams) {
        if (value === "null") {
            value = null;
        } else if (value === "undefined") {
            value = undefined;
        } else if (value === "NaN") {
            value = null;
        } else if (value === "false") {
            value = false;
        } else if (value === "true") {
            value = true;
        } else if (isNumber(value)) {
            value = Number(value);
        }
        param[key] = value;
    }

    return param;
}

export default useQuery;

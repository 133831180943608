import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

import GoodsType from "enum/GoodsType";

export async function jdGoodsPromotionUrl({skuId}) {
    return http({
        url: DOMAIN + '/api/jd/goods/promotion-url',
        method: Method.GET,
        params: {
            skuId
        }
    }).then(res => res.data)
}

export async function pddGoodsPromotionUrl({goodsSign}) {
    return http({
        url: DOMAIN + '/api/pdd/goods/promotion-url',
        method: Method.GET,
        params: {
            goodsSign
        }
    }).then(res => res.data)
}

export async function goodsPromotionUrl({goodsType, skuId}) {
    let shotUrl = '';

    if (goodsType === GoodsType.PDD) {
        const data = await pddGoodsPromotionUrl({goodsSign: skuId})
        shotUrl = data.shotUrl
    } else {
        const data = await jdGoodsPromotionUrl({skuId})
        shotUrl = data.shotUrl
    }

    return {
        shotUrl
    }
}
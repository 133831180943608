import React from 'react'
import './goods-list-panel.scss'
import showPrice from "utils/show-price";

const GoodsListPanel = ({goodsList, toBuy}) => {
    return (
        <div className="glx-live-room__goods-list-panel">
            <div className="title-container">
                <div className="title">共{goodsList.length}件商品</div>
            </div>
            <div className="goods-list-container">
                {
                    goodsList.map((item, index) => (
                        <div className={`goods ${item.show ? 'active' : ''}`} key={item.goodsId}>
                            <div className='img-container'>
                                <img src={item.mainPic} alt="" className="img"/>
                                {
                                    item.show &&
                                    <div className="showing">
                                        <img src={require('./img/讲解中.png')} alt="" className="icon"/>
                                        <div className="text">讲解中</div>
                                    </div>
                                }

                                <div className="number">{index + 1}</div>
                            </div>
                            <div className="info-container">
                                <div className="name">{item.name}</div>
                                <div className="price-container">
                                    <div className="price">{showPrice(item.currentPrice)}</div>
                                    <button
                                        className="to-buy"
                                        onClick={() => {
                                            toBuy(item.goodsId)
                                        }}>
                                        立即抢购
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default GoodsListPanel;
const type = {
    SEND_GIFT: {
        value: 1,
        label: '礼物'
    },
    CHANGE_CURRENT_GOODS: {
        value: 2,
        label: '修改当前展示商品'
    },
    UPDATE_LIVE_INFO: {
        value: 3,
        label: '直播间信息发生修改'
    },
    getByValue(value) {
        for (let key in type) {
            if ((type[key].value = value)) {
                return type[key];
            }
        }
    }
};

Object.defineProperty(type, 'getByValue', {
    enumerable: false
});

export default type;
import React, {useEffect, useState} from 'react'

import css from 'styles/home/index.module.scss'
import {observer} from 'mobx-react'
import {useHistory} from 'react-router-dom'
import Location from 'partials/home/Location'
import HomeSearch from "partials/home/HomeSearch";
import HomeCarousel from "partials/home/HomeCarousel";
import {makeAutoObservable, when} from "mobx";
import * as Api from "utils/api/api";
import AdvImgType from "enum/AdvImgType";
import HomeMenu from 'partials/home/HomeMenu'
import HomeHot from "partials/home/HomeHot";
import HomeSecKill from "partials/home/HomeSecKill";
import HomeAdv1 from "partials/home/HomeAdv1";
import HomeFloor from "partials/home/HomeFloor";
import HomeLookAround from "partials/home/HomeLookAround";
import HomeGuessYouLike from "partials/home/HomeGuessYouLike";
import Live from "components/live/live";
import useFetchDefaultLive from "hooks/useFetchDefaultLive";
import useMyStores from "hooks/useMyStores";
import liveStatusEnum from "enum/LiveStatusEnum";

class HomeCarouselStore {

    loading = true
    list = []

    async fetchData() {
        this.list = await Api.advImgList({
            type: AdvImgType.app_home_carousel
        })
        this.loading = false
    }

    constructor() {
        makeAutoObservable(this)
    }
}

class HomeAdv1Store {
    loading = true
    adv = undefined

    async fetchData() {
        const list = await Api.advImgList({type: AdvImgType.app_home_capsule});
        this.adv = list?.[0]
        this.loading = false
    }

    constructor() {
        makeAutoObservable(this)
    }
}

class HomeFloorStore {

    list = []
    loading = true

    async fetchData() {
        const list = await Api.fetchFloorList({
            page: 1,
            size: 4
        })
        this.list = list
        this.loading = false
    }

    constructor() {
        makeAutoObservable(this)
    }

}

class HomeGuessYouLikeStore {

    loading = true
    list = []

    async fetchData() {
        this.list = await Api.fetchGuessYouLike()
        this.loading = false
    }

    constructor() {
        makeAutoObservable(this)
    }
}

class HomeLookAroundStore {
    loading = true
    list = []

    fetchCount = 0;

    async fetchData() {
        this.loading = true
        const list = await Api.fetchLookAround();
        this.list = [
            ...this.list,
            ...list
        ]
        this.loading = false
        this.fetchCount = this.fetchCount + 1
    }

    get canLoadMoreData() {
        return (this.fetchCount <= 3 && !this.loading);
    }

    get notFetch() {
        return this.fetchCount === 0
    }

    constructor() {
        makeAutoObservable(this)
    }
}

const carouselStore = new HomeCarouselStore();
const adv1Store = new HomeAdv1Store()
const floorStore = new HomeFloorStore();
const guessYouLikeStore = new HomeGuessYouLikeStore();
const lookAroundStore = new HomeLookAroundStore()

when(() => true, async () => {
    await carouselStore.fetchData()
})
when(() => !carouselStore.loading, async () => {
    await adv1Store.fetchData()
})

when(() => !adv1Store.loading, async () => {
    await floorStore.fetchData()
})

when(() => !floorStore.loading, async () => {
    await guessYouLikeStore.fetchData()
})

when(() => !guessYouLikeStore.loading, async () => {
    await lookAroundStore.fetchData()
})

function Home({home: store, search}) {
    const history = useHistory()
    const [city, setCity] = useState()
    const {data: liveInfo} = useFetchDefaultLive()
    const {user: userStore, constant: constantStore} = useMyStores();


    // 获取数据
    useEffect(() => {
        constantStore.fetchJson();
    }, []);


    return (
        <div className={css.index}>
            <Location setCity={setCity}/>
            <div className={css.container}>
                {/*搜索*/}
                <HomeSearch city={city}/>
                {/*轮播*/}
                <HomeCarousel advImgList={carouselStore.list}/>
                {/*导航*/}
                <HomeMenu/>
                {/*热搜*/}
                <HomeHot/>
                {/*秒杀*/}
                <HomeSecKill/>
                {/*广告（胶囊图）*/}
                {
                    adv1Store.adv &&
                    <HomeAdv1 advImg={adv1Store.adv}/>
                }

                {/*楼层*/}
                {
                    !floorStore.loading &&
                    <div className={css.floorList}>
                        {
                            floorStore.list.map((item, i) => {
                                return (
                                    <HomeFloor key={i} className={css.floor} data={item}/>
                                )
                            })
                        }
                    </div>
                }
                {/*猜你喜欢*/}
                {
                    !guessYouLikeStore.loading &&
                    <HomeGuessYouLike goodsList={guessYouLikeStore.list}/>
                }
                {/*随便看看*/}
                {
                    !lookAroundStore.loading &&
                    <HomeLookAround lookAroundStore={lookAroundStore}/>
                }

                {/*直播间*/}
                {
                    liveInfo?.live.status === liveStatusEnum.OPEN &&
                    <Live
                        containerClassName={css.liveContainer}
                        userStore={userStore}
                        liveInfo={liveInfo}
                    />
                }
            </div>
        </div>
    )
}

export default observer(Home)

import {observable, action, makeAutoObservable} from 'mobx';
import ChatMessage from 'enum/ChatMessage';
import * as Api from 'utils/api/api';

export const Message = class {
  content;
  userType;

  constructor(content, userType) {
    this.content = content;
    this.userType = userType;
  }
};

class Service {
  @observable _show = false;
  @observable _message = '';
  @observable _messageList = [];

  constructor() {
    makeAutoObservable(this)
  }
  get show() {
    return this._show;
  }
  set show(value) {
    this._show = value;
  }
  get message() {
    return this._message;
  }
  set message(value) {
    this._message = value;
  }
  get messageList() {
    return this._messageList;
  }
  set messageList(value) {
    this._messageList = value;
  }

  @action async addMessage({ message, userType }) {
    this._messageList.push(new Message(message, userType));
  }

  @action async sendMessage(message, callback) {
    try {
      this._message = '';

      const { answer } = await Api.askQuestion({ question: message });

      this.addMessage({ message: answer, userType: ChatMessage.TO });
      if (callback) {
        callback();
      }
    } catch (error) {}
  }
  @action init() {
    this._messageList = [
      new Message('你好，有什么可以帮到你呢?', ChatMessage.TO)
    ];
  }
}

const store = new Service();

export default store;

import React, {useEffect, useRef} from 'react'
import css from "./messageList.module.scss";
import ImMsgType from "enum/ImMsgType";
import {observer} from "mobx-react";

const Index = ({store}) => {
    const chatMessageListRef = useRef(undefined);

    /**
     * 控制弹幕滚动条
     */
    useEffect(() => {
        if (!chatMessageListRef.current) {
            return;
        }

        chatMessageListRef.current.scrollTop =
            chatMessageListRef.current.scrollHeight;
    }, [store.messageList.length, chatMessageListRef.current]);

    return (

        <div className={css.messageList} ref={chatMessageListRef}>
            {
                store.messageList.map((item, i) => {
                    return (
                        <div key={i} className={css.messageLine}>
                            <div className={css.messageWrapper}>
                                        <span className={css.message}>
                                            {
                                                item.msgBody.type === ImMsgType.TEXT &&
                                                <>
                                                    <em className={css.nickname}>{item.nickname}:</em>
                                                    {item.msgBody.payload.text}
                                                </>
                                            }
                                            {
                                                item.msgBody.type === ImMsgType.USER_ENTER &&
                                                <>
                                                    <em className={css.nickname}>{item.msgBody.payload.text}</em>
                                                    进入直播间
                                                </>
                                            }
                                        </span>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default observer(Index)
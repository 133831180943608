import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from './api-utils';

/**
 * 根据goods和规格获取sku信息
 * @param {*} param0
 */
export const skuInfoByGoodsAndSpecification = async ({
  goodsId,
  specificationIdListStr
}) => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/api/goods/specification/sku',
    params: {
      goodsId,
      specificationIds: specificationIdListStr
    }
  });

  const { sku } = data;

  return { sku };
};

export const skuListByGoodsId = async ({ goodsId }) => {
  const { data } = await http({
    method: Method.POST,
    url: DOMAIN + '/product/list/all',
    data: {
      goodsId
    }
  });

  const list = data.msg;

  return { list };
};

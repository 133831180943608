import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

export const recordPddGoods = (goodsId) => {
    return http({
        method: Method.PUT,
        url: DOMAIN + "/pdd/record/goods",
        data: {
            goodsId
        }
    }).then(res => res.data)
}

export const record = () => {
    return http({
        method: Method.PUT,
        url: DOMAIN + "/pdd/record"
    }).then(res => res.data)
}
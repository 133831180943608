import React from 'react';
import './panel.scss';
import { InputItem, Radio, List, Picker, DatePicker } from 'antd-mobile';
import { createForm } from 'rc-form';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';

const Panel = props => {
  const { className, onClose, merchant } = props;
  const { shop2 } = merchant;

  useEffect(() => {
    shop2.childCategoryList = [];
    shop2.selectedCategoryId = 0;
  }, []);

  const renderChildCategoryList = list => {
    return list.map((item, i) => {
      const { id, value, active } = item;

      return (
        <Radio
          key={id}
          className='item'
          checked={active}
          onClick={e => {
            shop2.childCategoryList[i].active = !active;
          }}
        >
          {value}
        </Radio>
      );
    });
  };

  return (
    <div className={`glx-merchant-add-category-panel ${className}`}>
      {/* 一级类目 */}
      <div className='category-floor-1'>
        <div className='title-floor'>
          <span className='title'>1级类目</span>
        </div>
        <Picker
          title='一级类目'
          cols={1}
          data={shop2.categoryList.map(item => ({
            ...item,
            value: item.id,
            label: item.value
          }))}
          value={[shop2.selectedCategoryId]}
          onOk={value => {
            const [selectedCategoryId] = value;

            shop2.selectedCategoryId = selectedCategoryId;
            shop2.fetchChildCategoryList({ parentId: selectedCategoryId });
          }}
        >
          <List.Item>一级类目</List.Item>
        </Picker>
      </div>
      {/* 二级类目 */}
      <div className='category-floor-2'>
        <div className='title-floor'>
          <span className='title'>二级类目</span>
          <Radio
            className='radio'
            name='select-all-category'
            checked={shop2.childCategoryListAllActive}
            onClick={e => {
              shop2.childCategoryListAllActive = !shop2.childCategoryListAllActive;
            }}
          >
            全选
          </Radio>
        </div>
        <div className='list-floor'>
          {renderChildCategoryList(shop2.childCategoryList)}
        </div>
      </div>
      {/* 按钮 */}
      <div className='button-container'>
        <div className='button-floor'>
          <button
            className='confirm-btn'
            onClick={() => {
              shop2.selectChildCategory({
                parentId: shop2.selectedCategoryId,
                childList: shop2.childCategoryList
              });
              onClose();
            }}
          >
            确定
          </button>
        </div>
        <div className='button-floor'>
          <button
            className='cancel-btn'
            onClick={() => {
              onClose();
            }}
          >
            取消
          </button>
        </div>
      </div>
    </div>
  );
};

export default createForm()(inject('merchant')(observer(Panel)));

import React from 'react';
import './bottom-button.scss';
import { inject, observer } from 'mobx-react';

const BottomButton = props => {
  const { className, merchant, children, onClick } = props;

  return (
    <div className={`glx-merchant-bottom-btn ${className}`}>
      <button
        className='button'
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            merchant.toNextStep();
          }
        }}
      >
        {children || '下一步'}
      </button>
    </div>
  );
};

export default inject('merchant')(observer(BottomButton));

// 这个必须在最前面
import {isProd} from "src/utils/envUtil";

export {default as GetFormData} from "utils/GetFormData";


const Global = {};
Global.online = isProd;

if (Global.online) {
    Global.DOMAIN = window.BASE_URL;
    Global.BASE_SOCKET = window.BASE_SOCKET;
    Global.LIVE_BASE_URL = window.LIVE_BASE_URL;
    Global.MINI_BASE_URL = window.MINI_BASE_URL;
} else {
    const domain = "glx.wk-mac.com";
    // const domain = 'localhost'

    Global.DOMAIN = `http://${domain}:8090`;
    Global.BASE_SOCKET = `ws://${domain}:8090`;
    Global.LIVE_BASE_URL = `http://${domain}:8110/live/api`;
    Global.MINI_BASE_URL = `http://${domain}:8100`;
}

export const DOMAIN = Global.DOMAIN;

export const BASE_SOCKET = Global.BASE_SOCKET

export const LIVE_BASE_URL = Global.LIVE_BASE_URL;
export const MINI_BASE_URL = Global.MINI_BASE_URL;
export const ONLINE = Global.online;

export const fetchJson = (url, arg) => {
    return fetch(
        url,
        Object.assign(
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
            arg
        )
    );
};

export const Header = {
    ContentType: "content-type",
};

export const ContentType = {
    FormData: "application/x-www-form-urlencoded;charset=UTF-8",
    JSON: "application/json",
};

export const Method = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete",
};

export {default as http} from "./http";

import React from 'react';
import 'styles/news-info.scss';
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const NewsInfo = props => {
  const { newsInfo: store } = props;

  const params = useParams();
  const history = useHistory();

  let id = Number(params.id);

  /**
   *  初始化
   */
  useEffect(() => {
    store.init();
  }, []);

  /**
   * 获取信息
   */
  useEffect(() => {
    store.fetchInfo(id);
    store.fetchRecommendList();
  }, [id, store]);

  const {
    title,
    content,
    publishTime,
    updateTime,
    source,
    author,
    watchTimes,
    thumbnail,
    categoryId,
    categoryName,
    pre,
    next
  } = store;

  const renderRecommendList = list => {
    return list.map((item, i) => {
      const {
        id,
        title,
        author,
        watchTimes,
        content,
        publishTime,
        thumbnail,
        categoryId,
        categoryName,
        netEase
      } = item;

      return (
        <div
          className='floor'
          key='id'
          onClick={() => {
            history.replace({ pathname: `/news/info/${id}` });
          }}
        >
          <div className='info-block'>
            <div className='title'>{title}</div>
          </div>
          <div className='img-block'>
            <img src={thumbnail} alt='' className='img' />
          </div>
        </div>
      );
    });
  };

  return (
    <div className='glx-news-info'>
      <div className='head'>
        <div
          className='back-block'
          onClick={() => {
            history.goBack();
          }}
        >
          <img
            src={require('images/news-info/back.png')}
            alt=''
            className='back-img'
          />
        </div>
        <div className='title'>资讯信息</div>
      </div>
      <div className='info'>
        <div className='title-floor'>{title}</div>
        <div
          className='content'
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>

      <div className='recommend-content'>
        <div className='title-floor'>
          <div className='line'></div>
          <div className='title'>推荐资讯</div>
        </div>
        <div className='list'>{renderRecommendList(store.recommendList)}</div>
      </div>
    </div>
  );
};

export default inject('newsInfo')(observer(NewsInfo));

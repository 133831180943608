import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from './api-utils';

/**
 * 获取视频列表
 * @param {*} param0
 */
export const videoList = async ({ pageSize, pageNum, title }) => {
  const { data } = await http({
    method: Method.POST,
    url: DOMAIN + '/small/video/list',
    data: {
      page: pageNum,
      size: pageSize,
      title,
      type: 0 //type:视频分类id 不传或者传0会返回所有类型视频
    }
  });

  const list = data.msg.objectList.map(item => {
    const { id, path, videoFrame, title, content, type, typeName } = item;

    return {
      id,
      path,
      videoFrame,
      title,
      content,
      type,
      typeName
    };
  });

  const { totalNum } = data.msg;

  return {
    list,
    totalNum
  };
};

/**
 * 获取视频信息
 * @param {*} param0
 */
export const videoInfo = async ({ id }) => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/small/video/info',
    params: { id }
  });

  const { path, videoFrame, title, content, type, typeName } = data.msg;

  return {
    info: {
      id,
      path,
      videoFrame,
      title,
      content,
      type,
      typeName
    }
  };
};

import React, { useEffect } from 'react';

import './account-header.scss';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const AccountHeader = props => {
  const { my: store, app } = props;
  const { id, username, nickname, avatar, gender, birthday } = store;
  let history = useHistory();

  return (
    <div className='account-header'>
      <div className='bg'></div>
      <div
        className='back'
        onClick={() => {
          history.go(-1);
        }}
      >
        <img src={require('images/account/back.png')} alt='' className='img' />
      </div>
      <div className='info-floor'>
        <div className='avatar-wrapper'>
          <img
            src={
              app.hasLogin && avatar
                ? avatar
                : require('images/account/avatar.png')
            }
            alt=''
            className='avatar'
          />
        </div>
        <div className='info'>
          <div className='nickname'>{nickname}</div>
          <div className='username'>{username}</div>
        </div>
      </div>
    </div>
  );
};

export default AccountHeader;

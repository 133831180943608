import React from 'react';
import './service.scss';
import { observer, inject } from 'mobx-react';
import Message from './partials/message/Message';
import ChatMessage from 'enum/ChatMessage';
import { useRef } from 'react';
import { useEffect } from 'react';

const Service = props => {
  const { service: store, my, app } = props;
  const messageListRef = useRef(null);

  /**
   * 获取用户信息
   */
  useEffect(() => {
    if (app.hasLogin) {
      my.fetchInfo();
    }
  }, []);

  /**
   * 初始化
   */
  useEffect(() => {
    store.init();
  }, []);

  function scroll() {
    messageListRef.current.scrollTo(0, 10000);
  }

  function renderMessage() {
    return store.messageList.map((message, index) => {
      return <Message key={index} {...message} {...{ avatar: my.avatar }} />;
    });
  }

  return (
    <div className='glx-service'>
      <div className='title-floor'>
        <div className='title'>
          <div className='bg'></div>
        </div>
        <button
          className='close-btn'
          onClick={() => {
            store.show = false;
          }}
        >
          <img
            src={require('./img/close/icon.png')}
            alt=''
            className='icon-img'
          />
          <div className='text'>关闭</div>
        </button>
      </div>
      <div className='container'>
        <div className='bg'></div>
        <div className='content'>
          <div className='wrapper' ref={messageListRef}>
            {renderMessage()}
          </div>
        </div>
        <div className='input-floor'>
          <div className='input-wrapper'>
            <form
              className='input-form'
              onSubmit={e => {
                e.preventDefault();

                if (!store.message || /\s/.test(store.message)) {
                  store.message = '';
                  return;
                }

                store.addMessage({
                  message: store.message,
                  userType: ChatMessage.FROM
                });
                store.sendMessage(store.message, () => {
                  scroll();
                });
              }}
            >
              <input
                type='search'
                className='input'
                value={store.message}
                placeholder='你有什么疑问吗？'
                onChange={e => {
                  const { value } = e.currentTarget;

                  store.message = value;
                }}
              />
            </form>

            <img
              src={require('./img/input-left.png')}
              alt=''
              className='left-decorate'
            />
            <img
              src={require('./img/input-right.png')}
              alt=''
              className='right-decorate'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject('service', 'my', 'app')(observer(Service));

import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from './api-utils';

export const VerifySrc = DOMAIN + '/api/common/imgeverify';

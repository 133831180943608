import React, {useCallback} from 'react';
import './confirm-goods.scss';
import {inject, observer} from 'mobx-react';
import showPrice from 'utils/show-price';
import {useEffect} from 'react';
import * as api from 'utils/api/api';

const ConfirmGoods = props => {
    const {confirmGoods: store} = props;

    const fetchContact = useCallback(async () => {
        const {name, phone} = await api.dbContact();
        store.name = name;
        store.phone = phone;
    }, []);

    useEffect(() => {
        fetchContact();
    }, []);

    function showSpecification(map) {
        let str = '';
        for (const [key, value] of map) {
            str += value;
        }

        return str;
    }

    function renderGoodsList() {
        let list = store.list;

        return list.map((item, i) => {
            const {
                id,
                name,
                currentPrice,
                originPrice,
                count,
                img,
                specificationMap
            } = item;

            return (
                <div className='item' key={i}>
                    <div className='img-block'>
                        <img src={img} alt='' className='img'/>
                    </div>
                    <div className='info-block'>
                        <div className='name-floor'>{name}</div>
                        <div className='specification-floor'>
                            <div className='specification'>
                                {showSpecification(specificationMap)}
                            </div>
                        </div>
                        <div className='price-floor'>
                            <div className='current-price'>{showPrice(currentPrice)}</div>
                            <div className='count'>数量：{count}</div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    return (
        <div className='glx-confirm-goods'>
            <div className='card'>
                <div className='bg'></div>
                <div className='title-floor'>
                    <div className='title'>确认商品信息</div>
                </div>
                <div className='content'>{renderGoodsList()}</div>
            </div>
            <div className='contact-block'>
                <div className='user-floor'>
                    请联系店家({store.name})电话，完成购买
                </div>
                <div className='phone-floor'>
                    <img
                        src={require('./img/phone-icon.png')}
                        alt=''
                        className='phone-icon'
                    />
                    <div className='phone'>
                        <div className='bg'></div>
                        {store.phone}
                    </div>
                </div>
            </div>
            <div className='button-block'>
                <button
                    className='call-btn'
                    onClick={() => {
                        if(store.onClick){
                            store.onClick();
                        }
                    }}
                >
                    确认
                </button>
                <button
                    className='cancel-btn'
                    onClick={() => {
                        store.show = false;
                    }}
                >
                    取消
                </button>
            </div>
        </div>
    );
};

export default inject('confirmGoods')(observer(ConfirmGoods));

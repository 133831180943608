import React from 'react';
import './back-icon.scss';

const BackIcon = () => {

    return <div className="glx-back-icon">
        <img src={require('./img/back-icon.png')} alt='' className='icon'/>
    </div>
}

export default BackIcon;
import React from "react";
import './back.scss'
import {useHistory, NavLink} from "react-router-dom";
import BackIcon from './partials/BackIcon';

const Back = props => {
    const {children, onClick, hideBackIcon, className, ...otherProps} = props;
    const history = useHistory();

    const doClick = () => {
        if (onClick) {
            onClick();
        } else {
            history.goBack()
        }
    }

    return (
        <div
            {...otherProps}
            onClick={doClick}
            className={`glx-back ${className}`}
        >
            {hideBackIcon ? "" : <BackIcon />}
            {children}
        </div>
    );
};

export default Back;

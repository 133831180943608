import React from 'react';
import './condition.scss';
import { inject, observer } from 'mobx-react';

function Condition(props) {
  const { search: store } = props;
  return (
    <div className='condition'>
      <div
        className={`item ${store.sortFiled === null &&
          store.order === null &&
          'active'}`}
      >
        <div
          className='text'
          onClick={() => {
            store.sortFiled = null;
            store.order = null;
          }}
        >
          综合
        </div>
      </div>
      <div
        className={`item ${store.sortFiled === store.sortFieldEnum.SOLD_NUM &&
          'active'}`}
      >
        <div
          className='text'
          onClick={() => {
            if (store.sortFiled !== store.sortFieldEnum.SOLD_NUM) {
              store.sortFiled = store.sortFieldEnum.SOLD_NUM;
              store.order = store.orderEnum.ASC;
            } else if (store.order !== store.orderEnum.ASC) {
              store.order = store.orderEnum.ASC;
            } else {
              store.order = store.orderEnum.DESC;
            }
          }}
        >
          销量
          <div className='img-wrapper'>
            {store.sortFiled === store.sortFieldEnum.SOLD_NUM &&
            store.order === store.orderEnum.ASC ? (
              <img
                src={require('images/search-result/arrow-up-active.png')}
                alt=''
                className='arrow arrow-up'
              />
            ) : (
              <img
                src={require('images/search-result/arrow-up.png')}
                alt=''
                className='arrow arrow-up'
              />
            )}
            {store.sortFiled === store.sortFieldEnum.SOLD_NUM &&
            store.order === store.orderEnum.DESC ? (
              <img
                src={require('images/search-result/arrow-down-active.png')}
                alt=''
                className='arrow arrow-down'
              />
            ) : (
              <img
                src={require('images/search-result/arrow-down.png')}
                alt=''
                className='arrow arrow-down'
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`item ${store.sortFiled === store.sortFieldEnum.PRICE &&
          'active'}`}
      >
        <div
          className='text'
          onClick={() => {
            if (store.sortFiled !== store.sortFieldEnum.PRICE) {
              store.sortFiled = store.sortFieldEnum.PRICE;
              store.order = store.orderEnum.ASC;
            } else if (store.order !== store.orderEnum.ASC) {
              store.order = store.orderEnum.ASC;
            } else {
              store.order = store.orderEnum.DESC;
            }
          }}
        >
          价格
          <div className='img-wrapper'>
            {store.sortFiled === store.sortFieldEnum.PRICE &&
            store.order === store.orderEnum.ASC ? (
              <img
                src={require('images/search-result/arrow-up-active.png')}
                alt=''
                className='arrow arrow-up'
              />
            ) : (
              <img
                src={require('images/search-result/arrow-up.png')}
                alt=''
                className='arrow arrow-up'
              />
            )}
            {store.sortFiled === store.sortFieldEnum.PRICE &&
            store.order === store.orderEnum.DESC ? (
              <img
                src={require('images/search-result/arrow-down-active.png')}
                alt=''
                className='arrow arrow-down'
              />
            ) : (
              <img
                src={require('images/search-result/arrow-down.png')}
                alt=''
                className='arrow arrow-down'
              />
            )}
          </div>
        </div>
      </div>
      <div
        className='item'
        onClick={() => {
          store.showSelect = true;
        }}
      >
        <div className='text select'>
          筛选
          {store.selectNum > 0 && <div className='num'>{store.selectNum}</div>}
        </div>
      </div>
    </div>
  );
}

export default inject('search')(observer(Condition));

import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

export const addressList = () => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/address/list/all'
    }).then(res => res.data)
        .then(data => data.msg);
}

export const addressInfo = ({id}) => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/address/detail',
        params: {
            id
        }
    }).then(res => res.data)
        .then(data => data.msg);
}

export const addAddress = ({name, telNumber, province, city, district, detailInfo,isDefault}) => {
    return http({
        method: Method.POST,
        url: DOMAIN + '/address/save',
        data: {
            name, telNumber, province, city, district, detailInfo,isDefault
        }
    })
}

export const modifyAddress = ({id,name, telNumber, province, city, district, detailInfo, isDefault}) => {
    return http({
        method: Method.PUT,
        url: DOMAIN + '/address/update',
        data: {
            id,name, telNumber, province, city, district, detailInfo, isDefault
        }
    })
}
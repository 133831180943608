import React from 'react';
import './head.scss';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const Head = props => {
  const { merchant: store, goBack } = props;

  return (
    <div className='glx-merchant-head'>
      <div
        className='back'
        onClick={() => {
          if (goBack) {
            goBack();
          } else {
            store.toPrevStep();
          }
        }}
      >
        <img
          src={require('images/merchant/need-know/back.png')}
          alt=''
          className='img'
        />
      </div>
    </div>
  );
};

export default inject('merchant')(observer(Head));

import React, {useEffect} from 'react'
import {observer, inject} from 'mobx-react'
import {Redirect, NavLink} from 'react-router-dom'
import AccountHeader from 'partials/account/account-header/AccountHeader'
import * as Api from 'utils/api/api'
import * as antd from 'antd'
import 'styles/account.scss'
import userStore from "../../stores/UserStore";
import useMyStores from "../../hooks/useMyStores";

const Account = props => {
    const {home} = props
    const {id, username, nickname, avatar, gender, birthday} = store

    const {my: store, app, user: userStore} = useMyStores()

    useEffect(() => {
        if (app.hasLogin) {
            store.fetchInfo()
        }
    }, [])

    async function logout() {
        try {
            await Api.logout()
            app.token = ''
            userStore.logout()
            antd.message.success('退出登录成功')
        } catch (error) {
        }
    }

    function renderEnter() {
        return (
            <img src={require('images/account/enter.png')} alt='' className='enter'/>
        )
    }

    return (
        <div className='account'>
            {!app.hasLogin && <Redirect to='/my/account/login'/>}
            <AccountHeader {...{my: store, app}} />

            <div className='card'>
                <NavLink
                    className='item avatar'
                    to={`/my/modify-avatar?avatarUrl=${avatar}`}
                >
                    <div className='title'>头像</div>
                    <img
                        src={
                            app.hasLogin && avatar
                                ? avatar
                                : require('images/account/avatar.png')
                        }
                        alt=''
                        className='avatar'
                    />
                    {renderEnter()}
                </NavLink>
                <NavLink className='item' to={`/my/modify-gender?gender=${gender}`}>
                    <div className='title'>性别</div>
                    <div className='gender'>{gender === 0 ? '男' : '女'}</div>
                    {renderEnter()}
                </NavLink>
                <div className='item username'>
                    <div className='title'>用户名</div>
                    <div className='username'>{username}</div>
                </div>
                <NavLink
                    className='item'
                    to={`/my/modify-nickname?nickname=${nickname}`}
                >
                    <div className='title'>昵称</div>
                    <div className='nickname'>{nickname}</div>
                    {renderEnter()}
                </NavLink>
                <NavLink className='item' to='/my/modify-password'>
                    <div className='title'>修改密码</div>
                    {renderEnter()}
                </NavLink>
            </div>
            <div className='btn-floor'>
                <button className='logout-btn' onClick={logout}>
                    退出登录
                </button>
            </div>
        </div>
    )
}

export default observer(Account)

import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

export const recommendList = async ({pageSize, pageNum}) => {
    return http({
        method: Method.POST,
        url: DOMAIN + '/api/index/look/around',
        data: {
            page: pageNum,
            size: pageSize
        }
    }).then(({data}) => {
        const {lookAroundGoodsList: list, totalNum} = data;
        return {
            list,
            totalNum
        };
    }).catch(err => {
        if (err === '拼多多') {
            return Promise.resolve({
                list: [],
                totalNum: 0
            })
        } else if (err === "网络错误") {
            return recommendList({pageSize, pageNum});
        }

    })
};

import React from 'react';
import './company2.scss';
import Head from 'components/merchant/head/Head';
import Step from 'components/merchant/step/Step';
import BottomButton from 'components/merchant/bottom-button/BottomButton';
import { InputItem, Radio, List, Picker, DatePicker } from 'antd-mobile';
import { createForm } from 'rc-form';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import UploadImgList from './partials/upload-img-list/UploadImgList';
import UploadImg from './partials/upload-img/UploadImg';

const Company2 = props => {
  const { merchant, form } = props;
  const { company2 } = merchant;
  const { getFieldProps } = form;

  useEffect(() => {
    company2.fetchProvinceList();
  }, []);

  return (
    <div className='glx-merchant-company-2'>
      <Head />
      <Step />

      <div className='content'>
        <div className='title-floor'>
          <div className='title'>公司信息提交</div>
          <div className='sub-title'>营业执照信息（副本）</div>
        </div>
        <div className='container'>
          {/* 公司名 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写真实公司名称'
            value={company2.name}
            onChange={value => {
              company2.name = value;
            }}
          ></InputItem>
          {/* 组织机构代码 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写组织机构代码'
            value={company2.code}
            onChange={value => {
              company2.code = value;
            }}
          ></InputItem>
          {/* 法人 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写法定代表人姓名'
            value={company2.person}
            onChange={value => {
              company2.person = value;
            }}
          ></InputItem>
          {/* 法人身份证号 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写法定代表人身份证号码'
            value={company2.idNumber}
            onChange={value => {
              company2.idNumber = value;
            }}
          ></InputItem>
          {/* 法人身份证电子版 */}
          <div className='row'>
            <span className='label id-number'>法人身份证电子版</span>
            <UploadImgList
              setImgList={list => {
                company2.idPictureList = list;
              }}
              maxSize={2}
            />
          </div>
          {/* 公司地址 */}
          <Picker
            title='地址'
            data={company2.areaData}
            value={[
              company2.currentProvinceId,
              company2.currentCityId,
              company2.currentDistrictId
            ]}
            onOk={value => {
              const [provinceId, cityId, districtId] = value;

              company2.provinceId = provinceId;
              company2.cityId = cityId;
              company2.districtId = districtId;
            }}
            onDismiss={() => {
              const { provinceId, cityId, districtId } = company2;

              company2.currentProvinceId = provinceId;
              company2.currentCityId = cityId;
              company2.currentDistrictId = districtId;
            }}
            onPickerChange={value => {
              const [provinceId, cityId, districtId] = value;

              company2.currentProvinceId = provinceId;

              if (cityId) {
                company2.currentCityId = cityId;
              }

              if (districtId) {
                company2.currentDistrictId = districtId;
              }
            }}
          >
            <List.Item>公司所在地</List.Item>
          </Picker>
          {/* 公司详细地址 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写公司详细地址'
            value={company2.detailedAddress}
            onChange={value => {
              company2.detailedAddress = value;
            }}
          ></InputItem>
          {/* 公司成立日期 */}
          <DatePicker
            mode='date'
            title='成立日期'
            value={company2.createDate}
            onChange={date => (company2.createDate = date)}
          >
            <List.Item arrow='horizontal'>公司成立日期</List.Item>
          </DatePicker>
          {/* 公司营业期限 */}
          <List.Item>
            <span className='label'>营业期限</span>
            <Radio
              name='termEndIsInfinite'
              checked={!company2.termEndIsInfinite}
              onChange={e => {
                company2.termEndIsInfinite = false;
              }}
            >
              期限
            </Radio>
            <Radio
              name='termEndIsInfinite'
              checked={company2.termEndIsInfinite}
              onChange={e => {
                company2.termEndIsInfinite = true;
              }}
            >
              永久
            </Radio>
          </List.Item>
          {/* 开始营业日期 */}
          <DatePicker
            mode='date'
            title='开始营业日期'
            value={company2.termStart}
            onChange={date => (company2.termStart = date)}
          >
            <List.Item arrow='horizontal'>开始营业日期</List.Item>
          </DatePicker>
          {/* 营业结束日期 */}
          {!company2.termEndIsInfinite && (
            <DatePicker
              mode='date'
              title='营业结束日期'
              value={company2.termEnd}
              onChange={date => (company2.termEnd = date)}
            >
              <List.Item arrow='horizontal'>营业结束日期</List.Item>
            </DatePicker>
          )}
          {/* 注册资本 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写注册资本'
            value={company2.registeredCapital}
            onChange={value => {
              company2.registeredCapital = value;
            }}
          ></InputItem>
          {/* 经营范围 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写经营范围'
            value={company2.scope}
            onChange={value => {
              company2.scope = value;
            }}
          ></InputItem>
          {/* 营业执照电子版 */}
          <div className='row'>
            <span className='label license'>营业执照副本电子版</span>
            <UploadImg
              src={company2.licenseUrl}
              setImg={img => {
                company2.license = img;
              }}
            />
          </div>
          {/* 公司电话 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写公司电话'
            value={company2.phone}
            onChange={value => {
              company2.phone = value;
            }}
          ></InputItem>
          {/* 公司紧急联系人姓名 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写公司紧急联系人姓名'
            value={company2.emergencyPerson}
            onChange={value => {
              company2.emergencyPerson = value;
            }}
          ></InputItem>
          {/* 公司紧急联系人手机 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写公司紧急联系人手机'
            value={company2.emergencyPhone}
            onChange={value => {
              company2.emergencyPhone = value;
            }}
          ></InputItem>
        </div>
      </div>
      <BottomButton />
    </div>
  );
};

export default createForm()(inject('merchant')(observer(Company2)));

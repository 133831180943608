import React, {useEffect, useMemo, useRef} from 'react'
import './gift-animate.scss'
import {observer} from "mobx-react";
import * as Tim from 'utils/tim'
import giftMap from 'pages/live-room/data/gift-map'
import {useLocalStore} from "mobx-react-lite";
import {useDidMount} from "beautiful-react-hooks";

const GiftAnimate = ({giftMessage, count, afterAnimate,defaultAvatar,defaultNickname}) => {

    const ref = useRef(null);
    const countRef = useRef(null)
    const localStore = useLocalStore(() => ({
        animation: undefined,
        countAnimation: undefined,
        countOutAnimation: undefined,
        didMount: false
    }))

    useDidMount(() => {
        localStore.didMount = true;
    })

    /**
     * 初始化动画
     */
    useEffect(() => {
        if (!localStore.didMount || !giftMessage) {
            return;
        }

        /*
        * 初始化主动画
        * 并启动
        * */
        const animation = new Animation(
            new KeyframeEffect(ref.current, [
                    {
                        offset: 0,
                        opacity: 0,
                        transform: 'translateX(100%)'
                    }, {
                        offset: 0.2,
                        opacity: 1,
                        transform: 'translateX(0)',
                    }, {
                        offset: 0.8,
                        opacity: 1,
                        transform: 'translateX(0)'
                    }, {
                        offset: 1,
                        transform: 'translateX(-0.48rem)',
                        opacity: 0,
                    }
                ], {
                    duration: 2000,
                    fill: 'forwards',
                }
            ), document.timeline
        )
        animation.onfinish = () => {
            afterAnimate();
        }
        localStore.animation = animation;
        animation.play();

        /*
        * 初始化countOut动画
        * */
        localStore.countOutAnimation = new Animation(
            new KeyframeEffect(countRef.current, [
                {
                    offset: 0,
                    opacity: 1
                }, {
                    offset: 1,
                    opacity: 0
                }
            ], {
                duration: 2000 * 0.2,
                delay: 2000 * (1 - 0.4),
                fill: 'backwards'
            }), document.timeline
        )

        /*
        * 初始化count动画
        * */
        const countAnimation = new Animation(
            new KeyframeEffect(countRef.current, [
                {
                    transform: 'scale(1,1)'
                }, {
                    transform: 'scale(1.5,1.5)'
                }
            ], {
                duration: 500,
                fill: 'backwards'
            }), document.timeline
        )
        countAnimation.onfinish = () => {
            animation.play();
            localStore.countOutAnimation.play();
        }
        localStore.countAnimation = countAnimation;

    }, [localStore.didMount, giftMessage])

    /**
     * 监听礼物数量的变化
     */
    useEffect(() => {
        if (count <= 1) {
            return;
        }

        /*
        * 控制主动画
        * */
        const computedTiming = localStore.animation.effect.getComputedTiming();
        localStore.animation.currentTime = computedTiming.delay +
            computedTiming.activeDuration * 0.2;
        localStore.countOutAnimation.currentTime = 0;

        /*
        * 控制数量动画
        * */
        if (localStore.countAnimation.playState === 'running') {
            localStore.countAnimation.cancel();
        }
        localStore.countAnimation.play();
    }, [count])

    const gift = useMemo(() => {
        return giftMessage?.content ? giftMap.get(Number(giftMessage?.content)) : undefined;
    }, [giftMessage?.content])

    return (
        <div className='glx-live-room__gift-animate'>
            <div ref={ref} className="gift-animate-container">
                <div className="avatar-wrapper">
                    <img
                        src={giftMessage?.fromProfile?.avatar || defaultAvatar}
                        alt=""
                        className="avatar"/>
                </div>
                <div className="content-wrapper">
                    <div className="name">{giftMessage?.fromProfile?.nick || defaultNickname}</div>
                    <div className="gift-name-wrapper">
                        <div className="label">送出</div>
                        <div className="gift-name">{gift?.name}</div>
                    </div>
                </div>
                <img src={gift?.smallIcon} alt="" className="gift-icon"/>
            </div>
            <div
                ref={countRef}
                className="count-container"
                style={{
                    opacity: `${count > 1 ? '1' : '0'}`
                }}
            >
                <div className="text-x">x</div>
                <div className="count">{count}</div>
            </div>
        </div>
    )
}

export default observer(GiftAnimate);

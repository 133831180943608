import React from 'react';
import './shop.scss';
import Head from 'components/merchant/head/Head';
import Step from 'components/merchant/step/Step';
import BottomButton from 'components/merchant/bottom-button/BottomButton';
import { InputItem, Radio, List, Picker, DatePicker } from 'antd-mobile';
import { createForm } from 'rc-form';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';

const Shop = props => {
  const { merchant, form } = props;
  const { shop } = merchant;
  const { getFieldProps } = form;

  useEffect(() => {
    shop.fetchData();
  }, []);

  return (
    <div className='glx-merchant-shop'>
      <Head />
      <Step />

      <div className='content'>
        <div className='title-floor'>
          <div className='title'>个人基本信息</div>
          <div className='sub-title'>经营信息</div>
        </div>
        <div className='container'>
          {/* 公司名称 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写公司名称'
            value={shop.companyName}
            onChange={value => {
              shop.companyName = value;
            }}
          ></InputItem>
          {/* 公司官网地址 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写公司官网地址'
            value={shop.officialWebsite}
            onChange={value => {
              shop.officialWebsite = value;
            }}
          ></InputItem>
          {/* 最近一年销售额 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写最近一年销售额'
            value={shop.saleroom}
            onChange={value => {
              shop.saleroom = value;
            }}
          ></InputItem>
          {/* 同类电子商务网站经验 */}
          <div className='row'>
            <span className='label experience'>同类电子商务网站经验</span>
            <Radio
              name='experience'
              className='experience'
              checked={shop.hasExperience}
              onChange={e => {
                shop.hasExperience = true;
              }}
            >
              有
            </Radio>
            <Radio
              name='experience'
              className='experience'
              checked={!shop.hasExperience}
              onChange={e => {
                shop.hasExperience = false;
              }}
            >
              无
            </Radio>
          </div>
          {/* 请填写网店地址，形如www.baidu.com */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写网店地址，形如www.baidu.com'
            value={shop.shopWebsite}
            onChange={value => {
              shop.shopWebsite = value;
            }}
          ></InputItem>
          {/* 网站运营人数 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写网站运营人数'
            value={shop.personNumber}
            onChange={value => {
              shop.personNumber = value;
            }}
          ></InputItem>
          {/* 可网售商品数量 */}
          <Picker
            title='可网售商品数量'
            cols={1}
            data={shop.goodsNumberList}
            value={[shop.goodsNumber]}
            onOk={value => {
              const [goodsNumber] = value;

              shop.goodsNumber = goodsNumber;
            }}
          >
            <List.Item>可网售商品数量</List.Item>
          </Picker>
          <div className='row'>
            <div className='tip'>
              某一商品的多种颜色、尺码或包装形式不同时，按一件商品计算。
            </div>
          </div>
          {/* 预计平均客单价 */}
          <Picker
            title='预计平均客单价'
            cols={1}
            data={shop.predictAveragePriceList}
            value={[shop.predictAveragePrice]}
            onOk={value => {
              const [predictAveragePrice] = value;

              shop.predictAveragePrice = predictAveragePrice;
            }}
          >
            <List.Item>预计平均客单价</List.Item>
          </Picker>
          <div className='row'>
            <div className='tip'>用户购物时，单次购买的平均金额。</div>
          </div>
          {/* 仓库情况 */}
          <Picker
            title='仓库情况'
            data={shop.stockList}
            value={[shop.stock]}
            onOk={value => {
              const [stock] = value;

              shop.stock = stock;
            }}
          >
            <List.Item>仓库情况</List.Item>
          </Picker>
          {/* 请填写仓库地址 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写仓库地址'
            value={shop.warehouseAddress}
            onChange={value => {
              shop.warehouseAddress = value;
            }}
          ></InputItem>
          {/* 请填写常用物流公司 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写常用物流公司'
            value={shop.generalLogistics}
            onChange={value => {
              shop.generalLogistics = value;
            }}
          ></InputItem>{' '}
          <div className='row'>
            <div className='tip'>
              可填写多个，用逗号分隔，如“申通，圆通”，最大50字。
            </div>
          </div>
          {/* ERP类型 */}
          <Picker
            title='ERP类型'
            cols={1}
            data={shop.erpTypeList}
            value={[shop.erpType]}
            onOk={value => {
              const [erpType] = value;

              shop.erpType = erpType;
            }}
          >
            <List.Item>ERP类型</List.Item>
          </Picker>
          {/* 请填写代运营公司名称 */}
          <InputItem
            {...getFieldProps('control')}
            placeholder='请填写代运营公司名称'
            value={shop.thirdPartnarCompany}
            onChange={value => {
              shop.thirdPartnarCompany = value;
            }}
          ></InputItem>
          {/* 是否会选择商创仓储 */}
          <Picker
            title='是否会选择商创仓储'
            cols={1}
            data={[
              { value: false, label: '是' },
              { value: true, label: '否' }
            ]}
            value={[shop.selectOurWarehouse]}
            onOk={value => {
              const [selectOurWarehouse] = value;

              shop.selectOurWarehouse = selectOurWarehouse;
            }}
          >
            <List.Item>是否会选择商创仓储</List.Item>
          </Picker>
          {/* 是否会选择我们物流 */}
          <Picker
            title='是否会选择我们物流'
            cols={1}
            data={[
              { value: false, label: '是' },
              { value: true, label: '否' }
            ]}
            value={[shop.selectOurLogistics]}
            onOk={value => {
              const [selectOurLogistics] = value;

              shop.selectOurLogistics = selectOurLogistics;
            }}
          >
            <List.Item>是否会选择我们物流</List.Item>
          </Picker>
          {/* 开启分销资格 */}
          <div className='row'>
            <span className='label open-sale-qualification'>开启分销资格</span>
            <Radio
              name='openSale'
              className='open-sale-qualification'
              checked={shop.openSaleQualification}
              onChange={e => {
                shop.openSaleQualification = true;
              }}
            >
              是
            </Radio>
            <Radio
              name='openSale'
              className='open-sale-qualification'
              checked={!shop.openSaleQualification}
              onChange={e => {
                shop.openSaleQualification = false;
              }}
            >
              否
            </Radio>
          </div>
          {/* 垂直站 */}
          {/* <div className='row'>
            <div className='tip'>是否收到垂直站邀请</div>
          </div> */}
          <Picker
            title='垂直站邀请'
            cols={1}
            data={shop.receivedInviteList}
            value={[shop.receivedInvite]}
            onOk={value => {
              const [receivedInvite] = value;

              shop.receivedInvite = receivedInvite;
            }}
          >
            <List.Item>垂直站邀请</List.Item>
          </Picker>
        </div>
      </div>
      <BottomButton />
    </div>
  );
};

export default createForm()(inject('merchant')(observer(Shop)));

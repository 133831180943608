import React, {useEffect} from 'react'

import './my-header.scss'
import {inject, observer} from 'mobx-react'

const MyHeader = (props) => {

    return (
        <div className='glx-my__my-header'>
            <img src={require('images/my_v2/info/top-bg.png')} alt="" className="bg"/>
            <img src={require('images/my_v2/info/top-text.png')} alt="" className="img-text"/>
            <div className="setting-wrapper">
                <img src={require('images/my_v2/info/setting-icon.png')} alt="" className="icon-setting"/>
                <img src={require('images/my_v2/info/icon-shadow.png')} alt="" className="icon-shadow"/>
            </div>
            <div className="customer-service-wrapper">
                <img src={require('images/my_v2/info/customer-service-icon.png')} alt=""
                     className="icon-customer-service"/>
                <img src={require('images/my_v2/info/icon-shadow.png')} alt="" className="icon-shadow"/>
            </div>
        </div>
    )
}

export default inject('my', 'app')(observer(MyHeader))

import {makeAutoObservable, observable} from "mobx";

class ModifyOrderStore {
    @observable _orderId = 0;
    @observable _orderAddress = undefined;

    constructor() {
        makeAutoObservable(this)
    }
    get orderId() {
        return this._orderId;
    }

    set orderId(value) {
        this._orderId = value;
    }

    get orderAddress() {
        return this._orderAddress;
    }

    set orderAddress(value) {
        this._orderAddress = value;
    }
}

export default new ModifyOrderStore();
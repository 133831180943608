import React, {useEffect, useRef} from "react";
import css from 'styles/home/homeCarousel.module.scss'
import {observer} from "mobx-react";
import Swiper from "swiper";
import classNames from 'classnames'

const Index = ({advImgList}) => {

    const swiperRef = useRef()
    useEffect(() => {
        if (!(swiperRef.current && advImgList.length)) {
            return;
        }

        const mySwiper = new Swiper(swiperRef.current, {
            slidesPerView: 1,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
        })


        //鼠标覆盖停止自动切换
        swiperRef.current.onmouseover = function () {
            mySwiper.autoplay.stop();
        }

        //鼠标离开开始自动切换
        swiperRef.current.onmouseout = function () {
            mySwiper.autoplay.start();
        }
    }, [swiperRef.current, advImgList.length]);

    return (
        <div className={css.index}>
            <div className={css.container}>
                <div className={classNames('swiper', css.swiper)} ref={swiperRef}>
                    <div className={classNames('swiper-wrapper', css.swiperWrapper)}>
                        {
                            advImgList.map(item => {
                                const {id, file} = item;
                                return (
                                    <div key={id} className={classNames('swiper-slide', css.swiperSlide)}>
                                        <img className={css.img} src={file} alt=''/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Index)
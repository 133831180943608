import {observable, action, ObservableMap, makeAutoObservable} from 'mobx';
import * as Api from 'utils/api/api';
import * as antd from 'antd';

function getVerifySrc() {
  return `${Api.VerifySrc}?date=${new Date().getTime()}`;
}

class Register {
  @observable _username = '';
  @observable _verifyCode = '';
  @observable _password1 = '';
  @observable _password2 = '';
  @observable _verifyImg = getVerifySrc();
  @observable _usernameFocus = false;
  @observable _verifyCodeFocus = false;
  @observable _password1Focus = false;
  @observable _password2Focus = false;

  constructor() {
    makeAutoObservable(this)
  }
  get username() {
    return this._username;
  }
  set username(value) {
    this._username = value;
  }
  get verifyCode() {
    return this._verifyCode;
  }
  set verifyCode(value) {
    this._verifyCode = value;
  }
  get password1() {
    return this._password1;
  }
  set password1(value) {
    this._password1 = value;
  }
  get password2() {
    return this._password2;
  }
  set password2(value) {
    this._password2 = value;
  }
  get verifyImg() {
    return this._verifyImg;
  }
  set verifyImg(value) {
    this._verifyImg = value;
  }
  get usernameFocus() {
    return this._usernameFocus;
  }
  set usernameFocus(value) {
    this._usernameFocus = value;
  }
  get verifyCodeFocus() {
    return this._verifyCodeFocus;
  }
  set verifyCodeFocus(value) {
    this._verifyCodeFocus = value;
  }
  get password1Focus() {
    return this._password1Focus;
  }
  set password1Focus(value) {
    this._password1Focus = value;
  }
  get password2Focus() {
    return this._password2Focus;
  }
  set password2Focus(value) {
    this._password2Focus = value;
  }

  @action refreshVerifyImg() {
    this._verifyImg = getVerifySrc();
  }

  /**
   * 注册
   */
  @action async doRegister() {
    try {
      const { username, password1, password2 } = this;

      if (!username) {
        antd.message.error('请输入用户名');
        return false;
      }
      // if (!this.verifyCode) {
      //   antd.message.error('请输入验证码');
      //   return false;
      // }
      if (!password1) {
        antd.message.error('请输入密码');
        return false;
      }
      if (password1 !== password2) {
        antd.message.error('两次输入的密码不一致');
        return false;
      }

      await Api.register({
        username,
        password: password1
      });

      return true;
    } catch (error) {
      return false;
    }
  }
}

const store = new Register();

export default store;

import React from 'react';
import 'styles/modify-avatar.scss';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { useEffect } from 'react';
import * as antd from 'antd';
import UploadImg from 'components/upload-img/UploadImg';

const ModifyAvatar = props => {
  const { account: store } = props;
  let history = useHistory();
  const query = useQuery();

  /**
   * 初始化
   */
  useEffect(() => {
    store.avatarUrl = query.avatarUrl;
  }, []);

  async function doModify() {
    if (await store.doModifyAvatar()) {
      antd.message.success('修改成功');
      history.goBack();
    }
  }

  return (
    <div className='modify-avatar'>
      <div className='header'>
        <div
          className='back'
          onClick={() => {
            history.go(-1);
          }}
        >
          <img
            src={require('images/modify-nickname/back.png')}
            alt=''
            className='arrow'
          />
        </div>
        <div className='title'>头像</div>
      </div>

      <div className='form'>
        <div className='floor'>
          <UploadImg
            src={store.avatarUrl}
            setImg={img => {
              store.avatar = img;
            }}
          />
        </div>
        <div className='floor'>点击上传</div>
        <div className='floor btn-floor'>
          <button className='submit-btn' onClick={doModify}>
            确认修改
          </button>
        </div>
      </div>
    </div>
  );
};

export default inject('account')(observer(ModifyAvatar));

import React from "react";
import css from 'styles/home/homeHot.module.scss'
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";

const Index = () => {
    const history = useHistory()

    // todo 热卖
    const toHot = () => {

    }

    const toNews = () => {
        history.push("/news")
    }

    return (
        <div className={css.index}>
            <div className={css.container}>
                <div className={css.floor}>
                    {/*热卖*/}
                    <div className={css.left} onClick={toHot}>
                        <img src={require('images/home/热卖推荐固定图.png')} alt=""/>
                    </div>
                    <div className={css.right}>
                        {/*ai直播固定图*/}
                        <div className={css.top}>
                            <img src={require('images/home/ai直播固定图.png')} alt=""/>
                        </div>
                        {/*资讯中心*/}
                        <div className={css.bottom} onClick={toNews}>
                            <img src={require('images/home/资讯中心固定图.png')} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Index)
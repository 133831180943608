import React from 'react'
import './order-operate.scss'
import {inject, observer} from "mobx-react";
import OrderStatusEnum from "enum/OrderStatusEnum";
import * as api from 'utils/api/api';
import * as antd from 'antd';
import {useHistory} from 'react-router-dom';

const RenderButton = ({text, onClick, className}) => {
    return (
        <div
            className={`order-operate-button${className ? ' ' + className : ''}`}
            onClick={onClick}
        >
            {text}
        </div>
    )
}
const OrderOperate = ({order: store, fetchInfo,pay}) => {

    const {status} = store;
    const history = useHistory();

    const doCancelOrder = () => {
        api.cancelOrder({id: store.id}).then(() => {
            antd.message.success("取消成功");
            fetchInfo();
        });
    }

    const toAfterSale = () => {
        history.push(`/orders/${store.id}/refund/apply`);
    }

    const doReceive = () => {
        api.receiveDelivery({id: store.delivery?.id}).then(() => {
            antd.message.success("提交成功");
            fetchInfo();
        })
    }

    return (
        <div className='order-operate'>
            {status === OrderStatusEnum.WAIT_PAY.value && (
                <RenderButton text={'取消订单'} onClick={doCancelOrder}/>
            )}
            {OrderStatusEnum.canModifyAddress(status) && <RenderButton text={'修改订单'}/>}
            {status === OrderStatusEnum.WAIT_PAY.value && (
                <RenderButton
                    className={'order-operate-button--important'}
                    onClick={pay}
                    text={'去支付'}
                />
            )}
            {/*{status === OrderStatusEnum.WAIT_DELIVER.value && <RenderButton text={'我要催单'}/>}*/}

            {status === OrderStatusEnum.HAS_DELIVERED.value && (
                <RenderButton
                    className={'order-operate-button--important'}
                    text={'确认收货'}
                    onClick={doReceive}
                />
            )}
            {
                status >= OrderStatusEnum.HAS_DELIVERED.value &&
                status !== OrderStatusEnum.AFTER_SALE.value &&
                <RenderButton text='申请退款' onClick={toAfterSale}/>
            }
            {
                (status === OrderStatusEnum.HAS_COMPLETED.value || status === OrderStatusEnum.CANCELED.value) &&
                <RenderButton
                    text='再次购买'
                    className={'order-operate-button--important'}
                />
            }
        </div>
    )
}

export default inject('order')(observer(OrderOperate))

import React, { useState, createRef, useEffect } from 'react';
import './css/upload-img-list.scss';
import * as antd from 'antd';

function UploadImgList(props) {
  const {
    setImgList: propSetImgList,
    imgSrcList: propImgSrcList,
    maxSize
  } = props;

  const [loading, setLoading] = useState(false);
  const [imgList, setImgList] = useState([]);

  const fileRef = createRef(null);

  useEffect(() => {
    if (propImgSrcList) {
      setImgList(
        propImgSrcList.map(item => {
          let img = new File('');
          img.src = item;

          return img;
        })
      );
    }
  }, []);

  function onClick() {
    fileRef.current.dispatchEvent(new MouseEvent('click'));
  }

  function onChange() {
    let { files } = fileRef.current;

    if (maxSize) {
      if (files.length + imgList.length > maxSize) {
        antd.message.warning('最多只能上传2张');
        return false;
      }
    }

    let srcList = [];

    for (let i = 0; i < files.length; i++) {
      const src = URL.createObjectURL(files[i]);

      files[i].src = src;
      srcList.push(src);
    }

    propSetImgList([...imgList, ...files]);
    setImgList([...imgList, ...files]);
  }

  function doDelete(index) {
    let list = [...imgList];
    list.splice(index, 1);

    setImgList(list);
    propSetImgList(list);
  }

  return (
    <div className='glx-upload-img-list'>
      {imgList.map((item, index) => {
        return (
          <div className='img-item' key={index}>
            <div className='img-wrapper'>
              <img className='img' src={item.src} alt='img' />
              <div className='img-handle'>
                <img
                  className='delete-img'
                  src={require('./img/delete.png')}
                  alt='删除'
                  onClick={() => {
                    doDelete(index);
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}

      <button
        className={`upload-button ${imgList.length >= maxSize && 'hidden'}`}
        onClick={onClick}
      >
        <input
          className='upload'
          type='file'
          multiple='multiple'
          ref={fileRef}
          // accept='image/png, image/jpeg
          accept='image/*'
          onChange={onChange}
        />
        点击上传
      </button>
    </div>
  );
}

export default UploadImgList;

import React from 'react'

import './my-recent-deal.scss'
import MyRecentCard from '../my-recent-card/MyRecentCard'
import HorizontalList from '../../../components/common/horizontalList/HorizontalList'
import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
const MyRecentDeal = (props) => {
  const { deal: store } = props

  return (
    <div className='my-recent-deal'>
      <div className='my-recent-deal__title'>
        <span>最近交易</span>
      </div>
      <div className='my-recent-deal__content'>
        {store.list.length ? (
          <HorizontalList data={store.list} item={MyRecentCard} />
        ) : (
          <span>暂无交易记录</span>
        )}
      </div>
    </div>
  )
}

export default inject('deal')(observer(MyRecentDeal))

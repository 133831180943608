import React, {useCallback, useEffect} from 'react'
import 'styles/orders/address.scss'
import {inject, observer} from 'mobx-react'
import Back from 'components/common/back/Back'
import AddressItem from 'partials/orders/address/address-item/AddressItem'
import * as api from 'utils/api/api';
import {useHistory} from 'react-router-dom';

const Address = ({address: store}) => {

    const history = useHistory();

    const fetchList = useCallback(() => {
        api.addressList().then(list => {
            store.list = list;
        });
    }, []);
    useEffect(() => {
        fetchList();
    }, [fetchList]);

    const toAddAddress = () => {
        history.push('/orders/add-address');
    }

    const toModifyAddress = (id) => {
        history.push(`/orders/modify-address/${id}`);
    }

    return (
        <div className='glx-address'>
            <Back className='address-top'>
                <p className='title'>地址管理</p>
            </Back>
            <div className='address-list'>
                {store.list.map(address => (
                    <AddressItem key={address.id} address={address}/>
                ))}
            </div>

            <div className="add-floor">
                <button className="add-btn" onClick={toAddAddress}>新建地址</button>
            </div>
        </div>
    )
}

export default inject('address')(observer(Address))

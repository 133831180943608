import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

export const afterSaleInfo = ({orderId}) => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/after-sale',
        params: {
            orderId
        }
    }).then(res => res.data);
}

export const addAfterSale = ({orderId,reason}) => {
    return http({
        method: Method.POST,
        url: DOMAIN + '/after-sale',
        data: {
            orderId,
            reason
        }
    })
}
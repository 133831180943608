import React from 'react'
import './recommend-word-list.scss'
import { inject, observer } from 'mobx-react'
import { useHistory, useRouteMatch } from 'react-router-dom'

function RecommendWordList(props) {
  const { search: store } = props
  let history = useHistory()
  let match = useRouteMatch()
  function renderFloorList() {
    const list = store.recommendWordList
    if (list instanceof Array) {
      return store.recommendWordList?.map((item, i) => {
        return (
          <div
            className='floor'
            key={i}
            onClick={() => {
              store.keyword = item
              if (match.path === '/search/result') {
                store.fetchGoodsList()
              } else {
                history.push({ pathname: '/search/result' })
              }
            }}
          >
            <div className='word'>{item}</div>
          </div>
        )
      })
    } else return []
  }
  return <div className='recommend-word-list'>{renderFloorList()}</div>
}

export default inject('search')(observer(RecommendWordList))

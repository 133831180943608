import {observable, action, makeAutoObservable} from 'mobx';
import * as Api from 'utils/api/api';

class RecommendList {
  @observable _list = [];
  @observable _pageSize = 6;
  @observable _pageNum = 1;

  constructor() {
    makeAutoObservable(this)
  }
  get list() {
    return this._list;
  }
  set list(value) {
    this._list = value;
  }
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value) {
    this._pageSize = value;
  }
  get pageNum() {
    return this._pageNum;
  }
  set pageNum(value) {
    this._pageNum = value;
  }

  @action async fetchList() {
    try {
      const { pageSize, pageNum } = this;

      const { list } = await Api.recommendList({ pageSize, pageNum });

      this._list = list;
    } catch (error) {}
  }
}

export default RecommendList;

import React from "react";
import css from 'styles/home/homeAdv1.module.scss'
import {observer} from "mobx-react";

const Index = ({advImg}) => {
    const {id, file, title, subTitle, link} = advImg

    const onClick = () => {
        window.open(link, "_blank")
    }

    return (
        <div className={css.index}>
            <div className={css.container}>
                <div className={css.imgWrapper} onClick={onClick}>
                    <img src={file} alt="" className={css.img}/>
                </div>
            </div>
        </div>
    )
}

export default observer(Index)
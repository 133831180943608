import {observable, computed, action, makeAutoObservable} from 'mobx'

const APP_KEY = 'website-mobile-live'

function getItem(key) {
    const appJson = sessionStorage.getItem(APP_KEY)

    if (!appJson) {
        return null;
    }

    let app = JSON.parse(appJson)
    return app[key]
}

function setItem(key, value) {
    const appJson = sessionStorage.getItem(APP_KEY)
    let app
    if (!appJson) {
        app = {}
    } else {
        app = JSON.parse(appJson)
    }

    app = {...app, [key]: value}

    sessionStorage.setItem(APP_KEY, JSON.stringify(app))
}

class AppStore {
    @observable.ref _tab = '/home'
    @observable.ref
    _clientWidth = document.documentElement.getBoundingClientRect().width
    @observable.ref
    _clientHeight = document.documentElement.getBoundingClientRect().height
    @observable _token = ''
    @observable fontSize = 0

    constructor() {
        makeAutoObservable(this)
        let token = getItem('token')
        if (token) {
            this._token = token
        }
    }

    get clientWidth() {
        return this._clientWidth
    }

    get clientHeight() {
        return this._clientHeight
    }

    get token() {
        if (!this._token) {
            this._token = getItem('token');
        }

        return this._token
    }

    set token(value) {
        this._token = value
        setItem('token', value)
    }

    @computed get currentTab() {
        return this._tab
    }

    @computed get hasLogin() {
        return !!this.token;
    }

    @action initFontSize(size) {
        this.fontSize = size
    }

    @action setTab = (tabName = '/home') => {
        this._tab = tabName
    }
}

const store = new AppStore()

export default store

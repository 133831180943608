/*
 * @Author: your name
 * @Date: 2020-10-15 17:16:42
 * @LastEditTime: 2021-05-08 11:38:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /template_mall_mobile/src/utils/api/goods.js
 */
import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from "./api-utils";

/**
 * 商品详情
 * @param {*} param0
 */
export const goodsInfo = async ({goodsId, type}) => {
    const {data} = await http({
        method: Method.GET,
        url: DOMAIN + "/api/goods/detail",
        params: {
            goodsId,
            type
        }
    })

    return {
        skuList: data.productList,
        goods: {...data.goods, id: data.goods.id},
        hasCollect: data.userHasCollect,
        collectId: data.collectId,
        goodsAttributeList: data.goodsPropList,
        specificationMapList: data.goodsSpecificationList,
        goodsPromotionUrl: data.goods_promotion_url
    };

}

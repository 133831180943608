import React from "react";
import css from 'styles/home/homeMenu.module.scss'
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import classNames from "classnames";

const Index = () => {
    const history = useHistory()

    const toSelf = () => {
        history.push("/search/result")
    }

    const toSecKill = () => {
        history.push("/flash-sale")
    }

    const toShopStreet = () => {
        history.push("/shops")
    }

    const toNews = () => {
        history.push("/news")
    }

    const toMerchant = () => {
        history.push("/merchant")
    }

    return (
        <div className={css.index}>
            <div className={css.container}>
                <div className={css.menuList}>
                    <div className={classNames(css.menu)} onClick={toSelf}>
                        <div className={css.imgWrapper}>
                            <img className={css.self} src={require('images/home/菜单平台自营.png')} alt=""/>
                        </div>
                        <div className={css.name}>
                            平台自营
                        </div>
                    </div>
                    <div className={classNames(css.menu)} onClick={toSecKill}>
                        <div className={css.imgWrapper}>
                            <img className={css.secKill} src={require('images/home/菜单限时秒杀.png')} alt=""/>
                        </div>
                        <div className={css.name}>
                            限时秒杀
                        </div>
                    </div>
                    <div className={classNames(css.menu)} onClick={toShopStreet}>
                        <div className={css.imgWrapper}>
                            <img className={css.shopStreet} src={require('images/home/菜单店铺街.png')} alt=""/>
                        </div>
                        <div className={css.name}>
                            店铺街
                        </div>
                    </div>
                    <div className={classNames(css.menu)} onClick={toNews}>
                        <div className={css.imgWrapper}>
                            <img className={css.news} src={require('images/home/菜单新闻资讯.png')} alt=""/>
                        </div>
                        <div className={css.name}>
                            新闻资讯
                        </div>
                    </div>
                    <div className={classNames(css.menu)} onClick={toMerchant}>
                        <div className={css.imgWrapper}>
                            <img className={css.merchant} src={require('images/home/菜单招商入驻.png')} alt=""/>
                        </div>
                        <div className={css.name}>
                            招商入驻
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Index)
import {observable, computed, action, reaction, makeAutoObservable} from 'mobx'
import * as Api from '../utils/api/api'
import AppStore from '../stores/AppStore'
import reg from 'utils/reg'

class SearchStore {
    @observable.ref listTypeEnum = {
        LIST: 1,
        BLOCK: 2
    }
    @observable.ref orderEnum = {
        ASC: 'asc',
        DESC: 'desc'
    }
    @observable.ref sortFieldEnum = {
        PRICE: 'currentPrice',
        SOLD_NUM: 'soldNum'
    }
    @observable _historyWordList = []
    @observable _hotWordList = []
    @observable _recommendWordList = []
    @observable _showRecommendWordList = false
    @observable _goodsList = []

    @observable _isNew = null
    @observable _isHot = null
    @observable _keyword = ''
    @observable _order = null
    @observable _sortFiled = null
    @observable _fromPrice = ''
    @observable _toPrice = ''
    @observable _pageSize = 20
    @observable _pageNum = 1
    @observable _isSpecialPrice = false
    @observable _listType = this.listTypeEnum.BLOCK
    @observable _showSelect = false
    @observable _categoryList = []
    @observable _childCategoryList = []
    @observable _currentCategory = {
        id: -1,
        optId: -1,
        isSelf: false
    }
    @observable _currentChildCategory = {
        id: -1,
        optId: -1,
        isSelf: false
    }

    @observable _hasSelfCategory = null;

    constructor() {
        makeAutoObservable(this)
    }
    get historyWordList() {
        return this._historyWordList
    }

    set historyWordList(value) {
        this._historyWordList = value
    }

    get hotWordList() {
        return this._hotWordList
    }

    set hotWordList(value) {
        this._hotWordList = value
    }

    get recommendWordList() {
        return this._recommendWordList
    }

    set recommendWordList(value) {
        this._recommendWordList = value
    }

    get showRecommendWordList() {
        return this._showRecommendWordList
    }

    set showRecommendWordList(value) {
        this._showRecommendWordList = value
    }

    get goodsList() {
        return this._goodsList
    }

    set goodsList(value) {
        this._goodsList = value
    }

    get isNew() {
        return this._isNew
    }

    set isNew(value) {
        this._isNew = value
    }

    get isHot() {
        return this._isHot
    }

    set isHot(value) {
        this._isHot = value
    }

    get keyword() {
        return this._keyword
    }

    set keyword(value) {
        this._keyword = value
    }

    get order() {
        return this._order
    }

    set order(value) {
        this._order = value
    }

    get sortFiled() {
        return this._sortFiled
    }

    set sortFiled(value) {
        this._sortFiled = value
    }

    get fromPrice() {
        return this._fromPrice
    }

    set fromPrice(value) {
        this._fromPrice = value
    }

    get toPrice() {
        return this._toPrice
    }

    set toPrice(value) {
        this._toPrice = value
    }

    get pageSize() {
        return this._pageSize
    }

    set pageSize(value) {
        this._pageSize = value
    }

    get pageNum() {
        return this._pageNum
    }

    set pageNum(value) {
        this._pageNum = value
    }

    set isSpecialPrice(value) {
        this._isSpecialPrice = value
    }

    get listType() {
        return this._listType
    }

    set listType(value) {
        this._listType = value
    }

    get showSelect() {
        return this._showSelect
    }

    set showSelect(value) {
        this._showSelect = value
    }

    get categoryList() {
        return this._categoryList
    }

    set categoryList(value) {
        this._categoryList = value
    }

    get childCategoryList() {
        return this._childCategoryList
    }

    set childCategoryList(value) {
        this._childCategoryList = value
    }

    get currentCategory() {
        return this._currentCategory
    }

    set currentCategory(value) {
        this._currentCategory = value
        this._currentChildCategory = {
            id: -1,
            optId: -1,
            isSelf: false
        }
    }

    get currentChildCategory() {
        return this._currentChildCategory
    }

    set currentChildCategory(value) {
        this._currentChildCategory = value
    }

    get hasSelfCategory() {
        return this._hasSelfCategory;
    }

    set hasSelfCategory(value) {
        this._hasSelfCategory = value;
    }

    @computed get categoryId() {
        let categoryId = undefined;

        if (this._currentChildCategory.optId !== -1) {
            categoryId = this._currentChildCategory.optId
        } else if (this._currentCategory.optId !== -1) {
            categoryId = this._currentCategory.optId
        }

        return categoryId;
    }

    @computed get realFromPrice() {
        if (reg.isNum(this._fromPrice)) {
            return Number(this._fromPrice) * 100
        } else {
            return null
        }
    }

    @computed get realToPrice() {
        if (reg.isNum(this._toPrice)) {
            return Number(this._toPrice) * 100
        } else {
            return null
        }
    }

    @computed get showGoodsList() {
        return this._showGoodsList && this.recommendWordList.length === 0
    }

    @computed get selectNum() {
        let num = 0
        if (this.categoryId !== null && this.categoryId !== 0) {
            num++
        }
        if (reg.isNum(this.realFromPrice) && reg.isNum(this.realToPrice)) {
            num++
        }

        return num
    }

    /**
     * 获取历史搜索词列表
     */
    @action
    async fetchHistoryWordList() {
        try {
            if (AppStore.hasLogin) {
                const {list} = await Api.historySearchList()
                this._historyWordList = list
            }
        } catch (error) {
        }
    }

    /**
     * 获取热搜词列表
     */
    @action
    async fetchHotWordList() {
        try {
            const {list} = await Api.hotWordList({
                pageNum: 1,
                pageSize: 1000
            })

            this._hotWordList = list
        } catch (error) {
        }
    }

    /**
     * 获取推荐词列表
     * @param {*} param0
     */
    @action
    async fetchRecommendWordList({keyword}) {
        try {
            if (keyword && keyword.trim() != "") {
                const {list} = await Api.recommendWordList({
                    keyword: keyword.trim(),
                })
                this._recommendWordList = list || []
            } else {
                this._recommendWordList = []
            }

        } catch (error) {
        }
    }

    /**
     * 获取商品列表
     */
    @action
    async fetchGoodsList() {
        if (this._hasSelfCategory === null) {
            return;
        }

        try {
            this._showRecommendWordList = false
            let {
                categoryId,
                isNew,
                isHot,
                keyword,
                order,
                sortFiled,
                realFromPrice,
                realToPrice,
                pageSize,
                pageNum,
                isSpecialPrice,
                hasSelfCategory
            } = this

            let fromPrice = realFromPrice
            let toPrice = realToPrice

            if (!reg.isNum(fromPrice) || !reg.isNum(toPrice)) {
                fromPrice = null
                toPrice = null
            }

            const {list} = await Api.search({
                categoryId: undefined,
                isNew,
                isHot,
                keyword: keyword || this.currentChildCategory?.optName ||  this.currentCategory?.optName,
                order,
                sortFiled,
                fromPrice,
                toPrice,
                pageSize,
                pageNum,
                isSpecialPrice,
                goodsType: undefined
            })

            this._goodsList = list
        } catch (error) {
        }
    }

    /**
     * 切换列表类型
     */
    @action switchListType() {
        if (this._listType === this.listTypeEnum.LIST) {
            this._listType = this.listTypeEnum.BLOCK
        } else {
            this._listType = this.listTypeEnum.LIST
        }
    }

    /**
     * 获取类别列表
     */
    @action
    async fetchCategoryList() {
        try {
            let list = []

            const {
                list: pddGoodsCategoryList,
                hasSelfCategory
            } = await Api.pddGoodsCategoryList({
                parentId: 0
            })
            list.push(...pddGoodsCategoryList)

            this._hasSelfCategory = hasSelfCategory;
            if (hasSelfCategory) {
                list = [
                    {
                        id: 0,
                        optId: 0,
                        name: "平台自营",
                        isSelf: true
                    },
                    ...list
                ]
            }

            this.categoryList = list
        } catch (error) {
        } finally {
        }
    }

    /**
     * 获取子类别列表
     */
    @action
    async fetchChildCategoryList() {
        try {
            const {id: parentId, optId: parentOptId, isSelf} = this.currentCategory

            let list = []

            if (isSelf) {
                const {list: goodsCategoryList} = await Api.childGoodsCategoryList({
                    parentId: parentOptId
                })
                list.push(...goodsCategoryList)
            } else {

                const {
                    list: pddGoodsCategoryList,
                } = await Api.pddGoodsCategoryListByParentId({
                    parentId,
                    parentOptId
                })

                list.push(...pddGoodsCategoryList)
            }

            this._childCategoryList = list
        } catch (error) {
        }
    }

    @action
    resetSearch() {
        this._keyword = ''
        this._fromPrice = ''
        this._toPrice = ''
        this._currentCategory = {
            id: -1,
            optId: -1,
            isSelf: false,
        }
        this._currentChildCategory = {
            id: -1,
            optId: -1,
            isSelf: false,
        }
        // this.categoryId = null
        this._fromPrice = ''
        this._toPrice = ''
    }
}

const store = new SearchStore()

/**
 * 获取推荐词列表
 */
reaction(
    () => store.keyword,
    keyword => {
        if (keyword) {
            store.fetchRecommendWordList({keyword})
        }
    }
)

/**
 * 获取子类别
 */
reaction(
    () => store.currentCategory,
    currentCategory => {
        store.currentChildCategory = {
            id: -1,
            optId: -1,
            isSelf: false
        }
        if (currentCategory.optId === -1) {
            store.childCategoryList = []
        } else {
            store.fetchChildCategoryList()
        }
    }
)

export default store

import React from 'react'

import './horizontal-list.scss'
import { useHistory } from 'react-router-dom'

const HorizontalList = props => {
  const { item, data } = props

  const history = useHistory()

  function toDetail(id, pddShop) {
    history.push({
      pathname: `/detail/${id}`,
      search: `?type=${pddShop === true ? 1 : 0}`
    })
  }

  return (
    <div className='horizontal-list'>
      <div className='horizontal-list-wrapper'>
        {data?.length && data.map((datum, i) => item(datum, i, toDetail))}
      </div>
    </div>
  )
}

export default HorizontalList

import React from 'react';
import { Player } from 'video-react';
import { Icon } from 'antd';

import PlayIcon from './play.png';

import './video-item.scss';
class VideoItem extends React.Component {
  state = { playing: false };
  triggerPlay = () => {
    const { playing } = this.state;
    if (playing) {
      this.setState({ playing: false }, () => this.player.pause());
    } else {
      this.setState({ playing: true }, () => this.player.play());
    }
  };
  showMask = () => {
    this.setState({ playing: false });
  };
  render() {
    // const { title, url, type, count, date, img } = this.props
    const { id, path, videoFrame, title, content, type, typeName } = this.props;
    const { playing } = this.state;
    return (
      <div className='video-item' onBlur={this.showMask}>
        <div className='video-item__player'>
          <Player
            poster={videoFrame}
            fluid={false}
            width='100%'
            height='100%'
            ref={player => (this.player = player)}
          >
            <source src={path} />
          </Player>
          <div className={`video-item__mask${playing ? '--active' : ''}`}>
            <span className='title-block'>{title}</span>
            <img
              className={`video-item__play`}
              src={PlayIcon}
              alt=''
              onClick={this.triggerPlay}
            />
          </div>
        </div>

        <div className='video-item__info'>
          <span className='video-item__type'>{typeName}</span>
          {/* <Icon type='eye' />
          <span className='video-item__count'>{count || 0}次播放</span>
          <Icon type='history' />
          <span className='video-item__date'>{date}</span> */}
        </div>
      </div>
    );
  }
}

export default VideoItem;

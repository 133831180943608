export default new Map([
    [1, {
        id: 1,
        name: '棒棒糖',
        bigIcon: `${process.env.PUBLIC_URL}/img/gift/big/棒棒糖.png`,
        smallIcon: `${process.env.PUBLIC_URL}/img/gift/small/棒棒糖.png`
    }],
    [2, {
        id: 2,
        name: '火箭',
        bigIcon: `${process.env.PUBLIC_URL}/img/gift/big/火箭.png`,
        smallIcon: `${process.env.PUBLIC_URL}/img/gift/small/火箭.png`
    }],
    [3, {
        id: 3,
        name: '比心',
        bigIcon: `${process.env.PUBLIC_URL}/img/gift/big/比心.png`,
        smallIcon: `${process.env.PUBLIC_URL}/img/gift/small/比心.png`
    }],
    [4, {
        id: 4,
        name: '666',
        bigIcon: `${process.env.PUBLIC_URL}/img/gift/big/666.png`,
        smallIcon: `${process.env.PUBLIC_URL}/img/gift/small/666.png`
    }],
    [5, {
        id: 5,
        name: '干杯',
        bigIcon: `${process.env.PUBLIC_URL}/img/gift/big/干杯.png`,
        smallIcon: `${process.env.PUBLIC_URL}/img/gift/small/干杯.png`
    }],
    [6, {
        id: 6,
        name: '仙女棒',
        bigIcon: `${process.env.PUBLIC_URL}/img/gift/big/仙女棒.png`,
        smallIcon: `${process.env.PUBLIC_URL}/img/gift/small/仙女棒.png`
    }],
    [7, {
        id: 7,
        name: '猫爪',
        bigIcon: `${process.env.PUBLIC_URL}/img/gift/big/猫爪.png`,
        smallIcon: `${process.env.PUBLIC_URL}/img/gift/small/猫爪.png`
    }]
])
import React from 'react';
import 'styles/help-info.scss';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useCallback } from 'react';

const HelpInfo = props => {
  const { helpInfo: store } = props;
  const { info } = store;

  const params = useParams();
  const history = useHistory();

  /**
   * 获取数据
   */
  const fetchInfo = useCallback(() => {
    const id = Number(params.id);

    store.fetchInfo(id);
  }, [params.id, store]);
  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);


  return (
    <div className='glx-help-info'>
      <img
        src={require('images/help-info/top-bg.png')}
        alt=''
        className='top-bg-img'
      />
      <div className='top'>
        <div
          className='back'
          onClick={() => {
            history.goBack();
          }}
        >
          <img
            src={require('images/help-info/back.png')}
            alt=''
            className='img'
          />
        </div>
        <div
          className='title'
          onClick={() => {
            history.goBack();
          }}
        >
          {info?.title}
        </div>
      </div>
      <div className='content'>
        <div
          className='container'
          dangerouslySetInnerHTML={{ __html: info?.content }}
        ></div>
      </div>
    </div>
  );
};

export default inject('helpInfo')(observer(HelpInfo));

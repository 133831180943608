import GoodsType from "enum/GoodsType";


class MyHistory {
    history = undefined

    constructor({history}) {
        this.history = history;
    }

    // 去商品详情
    toGoodsInfo({goodsType, id}) {
        // const pddShop = goodsType === GoodsType.PDD

        // const search = new URLSearchParams({
        //     goodsType,
        //     startTime,
        //     endTime
        // }).toString()

        // this.history.push({
        //     pathname: `${Router.GoodsInfo.realPath}/${pddShop}/${id}`,
        //     search
        // });

        this.history.push({
            pathname: `/detail/${id}`,
            search: `?type=${goodsType}`
        });
    }

    // toSearchPage({categoryId, keyword, goodsType, selfFlag}) {
    //     const search = new URLSearchParams({
    //         categoryId,
    //         keyword,
    //         goodsType,
    //         selfFlag
    //     }).toString()
    //     this.history.push({
    //         pathname: MyRouter.Search.path,
    //         search
    //     })
    // }

    push({pathname, search}) {
        return this.history.push({
            pathname,
            search
        })
    }
}

class MyRouterUtil {
    build({history}) {
        return new MyHistory({history})
    }
}

export default new MyRouterUtil();
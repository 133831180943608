import EnumUtil from "enum/EnumUtil";

const type = {
    CANCELED: {
        value: -1,
        label: "已取消",
    },
    WAIT_PAY: {
        value: 0,
        label: "待付款",
    },
    WAIT_DELIVER: {
        value: 1,
        label: "待发货",
    },
    HAS_DELIVERED: {
        value: 2,
        label: "待收货",
    },
    HAS_COMPLETED: {
        value: 3,
        label: "已完成",
    },
    AFTER_SALE: {
        value: 4,
        label: "售后/退款",
    },

};

type.getByValue = EnumUtil.getByValue.bind(type);

type.getLabelByValue = EnumUtil.getLabelByValue.bind(type);

type.canModifyAddress = (value) => {
    return value === type.WAIT_DELIVER.value;
}

Object.defineProperties(type, {
    getByValue: {
        enumerable: false,
    },
    getLabelByValue: {
        enumerable: false,
    },
    canModifyAddress: {
        enumerable: false
    }
});

export default type;

import React from 'react';
import './input-floor.scss';
import SearchInput from '../../../components/search/search-input/SearchInput';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

function InputFloor(props) {
  const { search: store } = props;
  let history = useHistory();
  return (
    <div className='input-floor'>
      <img
        src={require('images/search-result/back.png')}
        alt=''
        className='back'
        onClick={() => {
          store.resetSearch()
          history.go(-1);
        }}
      />
      <SearchInput
        keyword={store.keyword}
        onChange={e => {
          store.keyword = e.currentTarget.value;
          store.showRecommendWordList = true;
        }}
        onClear={() => {
          store.keyword = '';
        }}
        onSubmit={e => {
          e.preventDefault();
          store.fetchGoodsList();
        }}
      />
      {store.listType === store.listTypeEnum.LIST ? (
        <img
          src={require('images/search-result/list-type-list.png')}
          alt=''
          className='list-type'
          onClick={() => {
            store.switchListType();
          }}
        />
      ) : (
        <img
          src={require('images/search-result/list-type-block.png')}
          alt=''
          className='list-type'
          onClick={() => {
            store.switchListType();
          }}
        />
      )}
    </div>
  );
}

export default inject('search')(observer(InputFloor));

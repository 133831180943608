import React from 'react'
import showPrice from "utils/show-price";

const Price = ({className, price}) => {
    return (
        <span className={`glx-price${className ? ' ' + className : ''}`}>
        {price &&
        <strong className='glx-price__int'>
            {showPrice(price)}
        </strong>
        }
    </span>
    )
}

export default Price

import * as api from 'utils/api/api';
import {observable, action, makeAutoObservable} from "mobx";
import axios from 'axios';

class ConstantStore {
    @observable _app = null;

    constructor() {
        makeAutoObservable(this)
    }
    @action fetchJson() {
        axios.all([api.app()]).then(
            axios.spread((app) => {
                this._app = app;
            })
        )
    }

    get app() {
        return this._app;
    }

    set app(value) {
        this._app = value;
    }
}

export default new ConstantStore();
import React, {useEffect} from "react";

const Index = ({setCity}) => {

    useEffect(() => {
        if (!window.AMap) {
            return;
        }
        //高的地图api
        var map = new window.AMap.Map("container");
        map.plugin("AMap.Geolocation", () => {
            var geolocation = new window.AMap.Geolocation({
                // 是否使用高精度定位，默认：true
                enableHighAccuracy: true,
                // 设置定位超时时间，默认：无穷大
                timeout: 10000,
                // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                buttonOffset: new window.AMap.Pixel(10, 20),
                //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                zoomToAccuracy: true,
                //  定位按钮的排放位置,  RB表示右下
                buttonPosition: "RB"
            });

            map.addControl(geolocation);
            geolocation.getCurrentPosition((status, result) => {
                let needRequest = false;

                if (status === "complete") {
                    if (result.addressComponent?.city) {
                        setCity(result.addressComponent?.city);
                    } else {
                        needRequest = true;
                    }
                } else if (status === "error") {
                    needRequest = true;
                }

                if (needRequest) {
                    geolocation.getCityInfo((status, result) => {
                        if (status === "complete") {
                            setCity(result.city);
                        } else if (status === "error") {
                            setCity('定位失败');
                        }
                    })
                }
            });
        });
    }, [window.AMap]);

    return (
        <div className="container" id="container"/>
    )
}

export default Index
import React, {useRef} from 'react'
import Nav from 'components/BottomNav'
import MyHeader from 'partials/my_v2/my-header/MyHeader'
import {observer, inject} from 'mobx-react'

import 'styles/my.scss'
import MyRecentDeal from 'partials/my_v2/my-recent-deal/MyRecentDeal'
import MyRecommend from 'partials/my_v2/my-recommend/MyRecommend'
import {useEffect} from 'react'
import MyInfo from 'partials/my_v2/my-info/MyInfo'
import MyOrder from 'partials/my_v2/my-orders/MyOrders'
import {useDidMount} from "beautiful-react-hooks";
import MeScroll from "mescroll.js"
import {useLocalStore} from 'mobx-react'
import {useHistory} from 'react-router-dom'

const My = ({my: store, deal, app}) => {
    const history = useHistory();

    const meScrollStore = useLocalStore(() => ({
        canDown: false,
        init() {
            this.canDown = false;
        }
    }))

    useDidMount(() => {
        const mescroll = new MeScroll("glx-my-page__mescroll", { //第一个参数"mescroll"对应上面布局结构div的id (1.3.5版本支持传入dom对象)
            //如果您的下拉刷新是重置列表数据,那么down完全可以不用配置,具体用法参考第一个基础案例
            //解析: down.callback默认调用mescroll.resetUpScroll(),而resetUpScroll会将page.num=1,再触发up.callback
            down: {
                beforeLoading() {
                    meScrollStore.canDown = true

                },
                callback() {
                    if (!meScrollStore.canDown) {
                        return;
                    }

                    history.push({
                        pathname: '/live-poster'
                    })
                },
                textOutOffset: '释放进入'
            }
        });
    })

    /**
     * 初始化
     */
    useEffect(() => {
        store.fetchRecommendList()
        meScrollStore.init()
    }, [])

    useEffect(() => {
        if (!app.hasLogin) {
            return;
        }

        store.fetchInfo()
        deal.fetchList().then(data => {
            const {totalNum} = data;
            store.footprintsNum = totalNum;
        })
    }, []);

    return (
        <div className='glx-my-page'>
            <div id="glx-my-page__mescroll" className="mescroll">
                <div className="page-container">
                    <MyHeader/>
                    <MyInfo/>
                    <MyOrder/>
                    {app.hasLogin && <MyRecentDeal/>}
                    <MyRecommend/>
                </div>
            </div>
            <Nav/>
        </div>
    )
}

export default inject('my', 'deal', 'app')(observer(My))

import {observable, action, makeAutoObservable} from 'mobx'
import * as Api from 'utils/api/api'
import RecommendList from './RecommendListStore'

class MyStore {
    // private
    @observable _id = 0
    @observable _username = ''
    @observable _nickname = ''
    @observable _avatar = ''
    @observable _gender = -1
    @observable _birthday = ''
    // public
    @observable goodsNum = 0
    @observable shopsNum = 0
    @observable footprintsNum = 0
    @observable.ref recommendListStore = new RecommendList()

    constructor() {
        makeAutoObservable(this)
    }
    /**
     * 获取用户信息
     */
    @action
    async fetchInfo() {
        try {
            const {info} = await Api.userInfo()

            const {id, username, avatar, nickname, gender, birthday} = info

            this._id = id
            this._username = username || ''
            this._avatar = avatar || ''
            this._nickname = nickname || ''
            this._gender = gender === 0 ? 0 : 1
            this._birthday = birthday
        } catch (error) {
        }
    }

    /**
     * 获取推荐列表
     */
    @action
    async fetchRecommendList() {
        await this.recommendListStore.fetchList()
    }

    get id() {
        return this._id
    }

    set id(value) {
        this._id = value
    }

    get username() {
        return this._username
    }

    set username(value) {
        this._username = value
    }

    get nickname() {
        return this._nickname
    }

    set nickname(value) {
        this._nickname = value
    }

    get avatar() {
        return this._avatar
    }

    set avatar(value) {
        this._avatar = value
    }

    get gender() {
        return this._gender
    }

    set gender(value) {
        this._gender = value
    }

    get birthday() {
        return this._birthday
    }

    set birthday(value) {
        this._birthday = value
    }
}

const store = new MyStore()

export default store

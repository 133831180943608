import {DOMAIN, http, Method} from './api-utils';
import {isTest} from "./commonApi";
import floorList from './mock-data/floorList.json'
import guessYouLike from './mock-data/guessYouLike.json'
import * as Api from 'utils/api/api'

export async function fetchFloorList({page, size}) {

    if (isTest) {
        return floorList
    }

    return http({
        url: DOMAIN + '/api/app/home/floor',
        method: Method.GET,
        params: {
            page,
            size
        }
    }).then(res => res.data)
}

export async function fetchGuessYouLike() {
    if (isTest) {
        return guessYouLike.lookAroundGoodsList;
    }

    const {list, totalNum} = await Api.recommendList({
        pageNum: 1,
        pageSize: 2
    })
    return list
}

export async function fetchLookAround() {
    const {list, totalNum} = await Api.recommendList({
        pageNum: 1,
        pageSize: 6
    })
    return list
}
import React, {useEffect} from "react";
import css from 'styles/home/homeSecKill.module.scss'
import {observer} from "mobx-react";
import * as Api from "utils/api/api";
import {useHistory} from "react-router-dom";
import {makeAutoObservable} from "mobx";
import GoodsType, {goodsTypeEnumMap} from "enum/GoodsType";
import classNames from "classnames";
import {DateTime} from "luxon";
import MyRouterUtil from "../../utils/MyRouterUtil";

class SecKillStore {

    list = []

    startTime = undefined
    hourNumber = 0
    minuteNumber = 0
    secondNumber = 0

    get secKillTimeStr() {
        return this.startTime?.toFormat('HH:mm')
    }

    setStartTime(value) {
        this.startTime = value
    }

    setHourNumber(value) {
        this.hourNumber = value
    }

    get hourNumberStr() {
        return `${this.hourNumber}`.padStart(2, '0')
    }

    setMinuteNumber(value) {
        this.minuteNumber = value
    }

    get minuteNumberStr() {
        return `${this.minuteNumber}`.padStart(2, '0')
    }

    setSecondNumber(value) {
        this.secondNumber = value
    }

    get secondNumberStr() {
        return `${this.secondNumber}`.padStart(2, '0')
    }

    setList(value) {
        this.list = value
    }

    datetimeList = [
        0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22
    ].map(hour =>
        DateTime.now().set({
            hour: hour
        }).startOf('hour')
    )

    use(datetimeList) {
        const curTime = DateTime.now();
        const curHour = curTime.hour

        const i = Math.floor(curHour / 2)
        const secKillStartTime = datetimeList[i]

        let secKillEndTime = secKillStartTime.plus({hours: 2})

        const diffObj = secKillEndTime.diff(curTime, ['seconds', 'minutes', 'hours']).toObject()
        const secondNumber = Math.floor(diffObj.seconds)
        const minuteNumber = diffObj.minutes
        const hourNumber = diffObj.hours


        this.startTime = secKillStartTime
        this.endTime = secKillEndTime
        this.hourNumber = hourNumber
        this.minuteNumber = minuteNumber
        this.secondNumber = secondNumber
    }

    constructor() {
        makeAutoObservable(this)
    }
}

const secKillStore = new SecKillStore()

function useFetchSecKillList({setSecKillList}) {
    useEffect(() => {
        Api.secKillList({
            page: 1,
            size: 10
        }).then((list) => {
            setSecKillList(list.slice(0,3))
        });
    }, [])
}

function useSecKill() {
    useEffect(() => {
        const timer = setInterval(() => secKillStore.use(secKillStore.datetimeList), 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);
}

const Index = () => {
    const history = useHistory()

    useFetchSecKillList({setSecKillList: v => secKillStore.setList(v)})

    useSecKill()

    const toSecKill = () => {
        history.push("/flash-sale")
    }

    const toGoodsInfo = ({goodsType, id}) => {
        MyRouterUtil.build({history})
            .toGoodsInfo({goodsType, id})
    }

    return (
        <div className={css.index}>
            <div className={css.container}>
                <div className={css.titleFloor}>
                    <div className={css.titleWrapper}>
                        <div className={css.title}>限时抢购</div>
                    </div>

                    <div className={css.timeContainer}>
                        <div className={css.item}>
                            {secKillStore.hourNumberStr}
                        </div>
                        <div className={css.item}>
                            {secKillStore.minuteNumberStr}
                        </div>
                        <div className={css.item}>
                            {secKillStore.secondNumberStr}
                        </div>
                    </div>

                    <div className={css.moreWrapper} onClick={toSecKill}>
                        <div className={css.text}>
                            查看更多
                        </div>
                        <div className={css.iconWrapper}>
                            <img src={require('images/home/秒杀更多icon.png')} alt=""/>
                        </div>
                    </div>
                </div>

                <div className={css.goodsListContainer}>
                    <div className={css.goodsList}>
                        {
                            secKillStore.list.map((item, i) => {
                                const {
                                    selfGoodsId,
                                    jdSkuId,
                                    pddGoodsSign,
                                    name,
                                    price,
                                    secKillPrice,
                                    mainPic,
                                    goodsType
                                } = item
                                const goodsTypeText = goodsTypeEnumMap.get(goodsType);
                                let id;
                                switch (goodsType) {
                                    case GoodsType.PDD:
                                        id = pddGoodsSign;
                                        break;
                                    case GoodsType.JD:
                                        id = jdSkuId;
                                        break;
                                    case GoodsType.SELF:
                                    default:
                                        id = selfGoodsId;
                                        break;
                                }
                                return (
                                    <div
                                        key={i}
                                        className={css.goods}
                                        onClick={() => {
                                            toGoodsInfo({goodsType, id})
                                        }}
                                    >
                                        <div className={css.imgWrapper}>
                                            <img src={mainPic} alt=""/>
                                        </div>
                                        <div className={css.bottom}>
                                            <div className={css.name}>
                                                <span
                                                    className={classNames({
                                                        [css.self]: goodsType === GoodsType.SELF,
                                                        [css.pdd]: goodsType === GoodsType.PDD,
                                                        [css.jd]: goodsType === GoodsType.JD
                                                    })}
                                                >
                                                        {goodsTypeEnumMap.get(goodsType)}
                                                    </span>
                                                {name}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default observer(Index)
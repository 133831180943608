import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from "./api-utils";

/**
 * 购物车详情
 */
export const cartInfo = async () => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + "/cart/get"
  });

  let { cartInfo: info, cartList: list } = data;

  return {
    info,
    list
  };
};

/**
 * 添加商品
 * @param {*} param0
 */
export const cartAddGoods = async ({ goodsId, skuId, number }) => {
  await http({
    method: Method.POST,
    url: DOMAIN + "/cart/add",
    data: {
      goodsId,
      productId: skuId,
      number
    }
  });
};

/**
 * 删除商品
 * @param {*} param0
 */
export const cartDeleteGoods = async ({ cartId, goodsId, skuId }) => {
  await http({
    method: Method.PUT,
    url: DOMAIN + "/cart/update",
    data: { goodsId, productId: skuId, number: 0, id: cartId }
  });
};

/**
 * 修改购物车商品数量
 * @param {*} param0
 */
export const cartModifyGoodsNum = async ({
  goodsId,
  skuId,
  number,
  cartId
}) => {
  await http({
    method: Method.PUT,
    url: DOMAIN + "/cart/update",
    data: {
      goodsId,
      productId: skuId,
      number,
      id: cartId
    }
  });
};

/**
 * 删除购物车
 * @param {*} param0
 */
export const cartDelete = async ({ cartIdList }) => {
  await http({
    method: Method.DELETE,
    url: DOMAIN + "/cart/delete",
    headers: {
      [Header.ContentType]: ContentType.JSON
    },
    data: cartIdList
  });
};

/**
 * 切换sku
 * @param {*} param0
 */
export const cartChangSku = async ({
  cartId,
  goodsId,
  skuId,
  newSkuId,
  number
}) => {
  //删除商品
  await cartDeleteGoods({
    cartId,
    goodsId,
    skuId
  });
  //添加商品
  await cartAddGoods({
    goodsId,
    skuId: newSkuId,
    number
  });
};

/**
 * 购物车商品数量
 */
export const cartGoodsCount = async () => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + "/cart/goods/count"
  });

  const { cartCount: count } = data;

  return {
    count
  };
};

export const deleteCartByOrderId = ({orderId}) => {
  return http({
    method: Method.DELETE,
    url: DOMAIN + '/cart/delete-by-order-id',
    params: {
      orderId
    }
  })
}
import React, {useEffect, useMemo, useRef} from 'react'
import './live-list.scss'
import {inject, observer, useLocalStore, useObserver} from "mobx-react";
import Swiper from "swiper";
import * as Tim from 'utils/tim'
import * as Api from 'utils/api/api'

const LiveList = (
    {
        live: store,
        onPlay
    }
) => {
    const swiperRef = useRef(null);

    const currentLive = useMemo(() => {
        return store.list?.[store.currentIndex];
    }, [store.list, store.currentIndex])

    /*
    * 创建swiper和video对象
    * */
    useEffect(() => {
        if (!store.list.length) {
            return;
        }

        let showBtnArray = [];
        for (let i = 0; i < store.list.length; i++) {
            showBtnArray.push(true);
        }

        new Swiper(swiperRef.current, {
            width: '100%',
            height: '100%',

            on: {
                slideChange() {
                    store.currentIndex = this.realIndex;
                },
            },
        });
    }, [store.list]);

    return (
        <div className="glx-live__live-list">
            <div className="container">
                <div className="swiper-container" ref={swiperRef}>
                    <div className="swiper-wrapper">
                        {
                            store.list.map(({live, liveTask}, i) => (
                                <div className="swiper-slide" key={live.id}>
                                    <div className="player-wrapper">
                                        <img src={liveTask.poster || require('./img/default-poster.png')} alt=""
                                             className="poster"/>
                                        <div className="panel-container">
                                            <div className="top-container">
                                                <div className="playing-icon-wrapper">
                                                    <img src={require('./img/playing.png')} alt="" className="icon"/>
                                                </div>
                                                <div className="heat-wrapper">
                                                    热度：0
                                                </div>
                                            </div>
                                            <div className="play-icon-wrapper" onClick={() => {
                                                onPlay(live.id);
                                            }}>
                                                <img src={require('./img/play-icon.png')} alt="" className="play-icon"/>
                                            </div>

                                            <div className="bottom-container">
                                                <div className="cart-wrapper">
                                                    <img src={require('./img/cart-icon.png')} alt=""
                                                         className="cart-icon"/>
                                                    <div className="text">主播带货</div>
                                                </div>
                                                <div className="info-wrapper">
                                                    <img src='' alt="" className="avatar"/>
                                                    <div className="username">{liveTask.title}</div>
                                                    <div className="focus">
                                                        关注
                                                    </div>
                                                </div>
                                                <div className="title-wrapper">
                                                    {liveTask.brief || '暂无简介'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default inject('live')(observer(LiveList));
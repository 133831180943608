import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from './api-utils';

/**
 * 拼多多商品分类
 * 如果 parentId 为0，则查出顶级分类
 * 如果 parentId 不为0，则查出所有子分类
 * @param {*} param0
 */
export const pddGoodsCategoryListByParentId = async ({
                                                       parentId,
                                                       parentOptId,
                                                     }) => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + "/pdd/mall/list/all/opt/byParentId",
    headers: {
      [Header.ContentType]: ContentType.JSON,
    },
    params: {
      parentId,
      parentOptId,
    },
  });

  function handleList(list) {
    return list.map((item) => {
      let {
        optName: name,
        sonOptTree: childList,
        uncheckedIcon: icon,
        icon: iconActive,
      } = item;

      if (childList) {
        childList = handleList(childList);
      }

      return {
        ...item,
        name,
        parentId: item.parentId,
        parentOptId: item.parentOptId,
        isSelf: false,
        icon,
        iconActive,
        childList,
      };
    });
  }

  const list = handleList(data.msg);

  const { hasSelfRunCategory: hasSelfCategory } = data;

  return {
    list,
    hasSelfCategory,
  };
};

/**
 * 拼多多商品分类
 * 如果 parentId 为0，则查出顶级分类
 * 如果 parentId 不为0，则查出所有子分类
 * @param {*} param0
 */
export const pddGoodsCategoryList = async ({ parentId }) => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/pdd/mall/list/all/opt',
    headers: {
      [Header.ContentType]: ContentType.JSON
    },
    params: {
      pid: parentId
    }
  });

  function handleList(list) {
    return list.map(item => {
      let {
        id,
        optId,
        optName,
        level,
        sonOptTree: childList,
        icon,
        uncheckedIcon
      } = item;

      if (childList) {
        childList = handleList(childList);
      }

      return {
        id,
        optId,
        optName,
        level,
        isSelf: false,
        icon,
        uncheckedIcon,
        childList
      };
      
    });
  }

  const list = handleList(data.msg);

  const { hasSelfRunCategory: hasSelfCategory } = data;

  return {
    list,
    hasSelfCategory
  };
};

/**
 * 拼多多商品顶级分类
 * 如果 parentId 为0，则查出顶级分类
 */
export const pddMainGoodsCategoryList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/pdd/mall/list/all/opt',
    headers: {
      [Header.ContentType]: ContentType.JSON
    },
    params: {
      pid: 0
    }
  });

  function handleList(list) {
    return list.map(item => {
      let {
        optName: name,
        optId: id,
        level,
        uncheckedIcon: icon,
        icon: iconActive
      } = item;

      return {
        id,
        name,
        level,
        isSelf: false,
        icon,
        iconActive
      };
    });
  }

  const list = handleList(data.msg);

  return {
    list
  };
};

/**
 * 拼多多商品子分类
 * 根据 parentId 获取拼多多商品子分类
 * @param {*} param0
 */
export const pddChildGoodsCategoryList = async ({ parentId }) => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/pdd/mall/list/all/opt/background',
    params: {
      pid: parentId
    }
  });

  const list = data.msg.map(item => {
    const { id, optId, optName, parentOptId, level, icon } = item;

    return {
      ...item,
      isSelf: false
    };
  });

  return {
    list
  };
};

/**
 * 自营商品所有子分类
 */
export const allChildGoodsCategoryList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/product/category/listCategory/front',
    params: {
      pid: 0
    }
  });

  function handleList(list) {
    return list.map(item => {
      let {
        id,
        name: optName,
        pic,
        checked,
        sonCategoryTree: childList
      } = item;

      if (childList) {
        childList = handleList(childList);
      }

      return {
        id,
        optName,
        pic,
        childList,
        isSelf: true
      };
    });
  }

  const list = handleList(data);

  return {
    list
  };
};

/**
 * 自营商品子分类
 * 根据 parentId 获取自营商品子分类
 * @param {*} param0
 */
export const childGoodsCategoryList = async ({ parentId }) => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/product/category/listCategory/background',
    params: {
      pid: parentId
    }
  });

  const list = data.map(item => {
    const {
      file,
      name,
      checked,
      pid: parentId,
      id,
      pic: img,
      propertyId
    } = item;

    return {
      id,
      name,
      img,
      isSelf: true
    };
  });

  return {
    list
  };
};

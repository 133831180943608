import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

export const dbContact = async () => {

    const {data} = await http({
        method: Method.GET,
        url: DOMAIN + '/cart/business/contact'
    })

    const {
        telPhone: phone,
        name
    } = data;

    return {name, phone}
}
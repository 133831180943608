import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from "./api-utils";

export const orderAddressInfo = ({orderId}) => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/api/order-address/get-by-order-id',
        params: {orderId}
    }).then(res => res.data);
}
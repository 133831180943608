import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

/**
 * app
 */
export const app = () => {
    return http({
        method: Method.GET,
        url: process.env.PUBLIC_URL + '/json/app.json'
    }).then(res => res.data);
};
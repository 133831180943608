import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from './api-utils';

/**
 * 搜索
 * @param {*} param0
 */
export const search = async ({
  categoryId,
  isNew,
  isHot,
  keyword,
  order,
  sortFiled,
  fromPrice,
  toPrice,
  pageSize,
  pageNum,
  goodsType,
  isSpecialPrice
}) => {
  const { data } = await http({
    method: Method.POST,
    url: DOMAIN + '/api/index/search',
    data: {
      categoryId,
      isNew,
      isHot,
      keyword: keyword && keyword.trim(),
      order,
      sortFiled,
      fromPrice,
      toPrice,
      page: pageNum,
      size: pageSize,
      goodsType,
      isSpecialPrice
    }
  });

  const list = data.msg.objectList;

  const { totalNum } = data.msg;

  return { list, totalNum };
};

/**
 * 历史搜索词列表
 */
export const historySearchList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/search/history/list'
  });

  const list = data.msg;

  return { list };
};

/**
 * 热搜词列表
 * @param {*} param0
 */
export const hotWordList = async ({ pageNum, pageSize }) => {
  const { data } = await http({
    method: Method.POST,
    url: DOMAIN + '/hot/search/list',
    data: {
      page: pageNum,
      size: pageSize,
      categoryId: 0
    }
  });

  const list = data.msg.objectList.map(item => {
    const { id, word: hotWord, categoryId, categoryName } = item;

    return {
      id,
      hotWord
    };
  });

  return {
    list
  };
};

/**
 * 百度搜索提示
 * @param {*} param0
 */
export const recommendWordList = async ({ keyword }) => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/forward/info',
    params: {
      wd: keyword
    }
  });

  let list = data;

  return {
    list
  };
};

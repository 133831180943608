//修改h根元素字体
import app from '../stores/AppStore';

(function (designWidth, maxWidth) {
    var doc = document,
        win = window
    var docEl = doc.documentElement
    var tid
    var rootItem, rootStyle

    function refreshRem() {
        var width = docEl.getBoundingClientRect().width
        if (!maxWidth) {
            maxWidth = 540
        }
        if (width > maxWidth) {
            width = maxWidth
        }
        //rem换算方法1rem=50px
        var rem = (width * 50) / designWidth
        //兼容UC开始
        rootStyle = 'html{font-size:' + rem + 'px !important}'
        rootItem =
            document.getElementById('rootsize') || document.createElement('style')
        if (!document.getElementById('rootsize')) {
            document.getElementsByTagName('head')[0].appendChild(rootItem)
            rootItem.id = 'rootsize'
        }
        if (rootItem.styleSheet) {
            rootItem.styleSheet.disabled || (rootItem.styleSheet.cssText = rootStyle)
        } else {
            try {
                rootItem.innerHTML = rootStyle
            } catch (f) {
                rootItem.innerText = rootStyle
            }
        }
        //兼容UC结束
        docEl.style.fontSize = rem + 'px'
        app.initFontSize(rem)
    }

    refreshRem()

    win.addEventListener(
        'resize',
        function () {
            clearTimeout(tid) //防止执行两次
            tid = setTimeout(refreshRem, 300)
        },
        false
    )

    win.addEventListener(
        'pageshow',
        function (e) {
            if (e.persisted) {
                // 浏览器后退的时候重新计算
                clearTimeout(tid)
                tid = setTimeout(refreshRem, 300)
            }
        },
        false
    )

    function changeSize(e) {
        doc.body.style.fontSize = '16px'
    }

    if (doc.readyState === 'complete') {
        changeSize()
    } else {
        doc.addEventListener('DOMContentLoaded', changeSize, false)
    }
})(750, 1080)

export function paddingInt(int, len) {
  let str = int + ''
  while (str.length < len) {
    str = '0' + str
  }
  return str
}

export function parseSearchString(str: String = window.location.search) {
  if (str.startsWith('?')) {
    str = str.slice(1)
  }
  let queryStrs = str.split('&')
  const queries = {}
  queryStrs
    .filter(queryStr => queryStr.includes('='))
    .forEach(queryStr => {
      const [k, v] = queryStr.split('=')
      queries[k] = v
    })
  return queries
}

export function parsePathVar(str: String = window.location.pathname) {
  let arr = str.split('/')
  let pathVar = arr[arr.length - 1]
  if (pathVar.includes('?')) {
    pathVar = pathVar.split('?')[0]
  }
  return pathVar
}

export function parseQuery(search) {
  return new URLSearchParams(search)
}

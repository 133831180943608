import React from 'react';
import 'styles/shop-collect.scss';
import Tab from 'partials/goods-collect/tab/Tab.jsx';
import { inject, observer } from 'mobx-react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { SwipeAction } from 'antd-mobile';
import showPrice from 'utils/show-price';
import { useHistory } from 'react-router-dom';

const ShopCollect = props => {
  const { shopCollect: store } = props;
  const { list, pageSize, pageNum, order, sortField } = store;
  let history = useHistory();

  /**
   * 初始化推荐列表
   */
  useEffect(() => {
    store.fetchRecommendList();
  }, []);

  /**
   * 初始化数据
   */
  const fetchList = useCallback(() => {
    store.fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNum, order, sortField]);
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  function toDetail({ id, pddShop }) {
    history.push({
      pathname: `/detail/${id}`,
      search: `?type=${pddShop === true ? 1 : 0}`
    });
  }

  function renderShopList() {
    return store.list.map((item, i) => {
      const {
        id,
        name,
        thumbnail,
        time,
        timeStamp,
        fid,
        type,
        shopType,
        pddShop,
        collectNum,
        goodsList
      } = item;
      return (
        <SwipeAction
          key={id}
          className='shop-wrapper'
          autoClose
          right={[
            {
              text: '取消',
              onPress: () => store.doDelete(id),
              style: {
                backgroundColor: 'rgba(240, 55, 81, 1)',
                color: 'rgba(255, 255, 255, 1)',
                width: '2.4rem'
              }
            }
          ]}
        >
          <div
            className='shop'
            onClick={() => {
              toDetail({ id, pddShop });
            }}
          >
            <div className='img-block'>
              <img src={thumbnail} alt='' className='img' />
            </div>
            <div className='info-block'>
              <div className='name'>{name}</div>
            </div>
          </div>
        </SwipeAction>
      );
    });
  }

  function renderRecommendList() {
    return store.recommendListStore.list.map((item, i) => {
      let {
        id,
        name,
        originPrice,
        currentPrice,
        shopId,
        soldNum,
        mainPic,
        hasCollect,
        collectId,
        categoryId,
        isHot,
        isNew,
        isSpecialPrice,
        serviceTags,
        mallName,
        pddShop
      } = item;

      return (
        <div
          className='item'
          key={id}
          onClick={() => {
            toDetail({ id, pddShop });
          }}
        >
          <div className='img-block'>
            <img src={mainPic} alt='' className='img' />
          </div>
          <div className='info-block'>
            <div className='name'>{name}</div>
            <div className='price-floor'>
              <div className='current-price'>{showPrice(currentPrice)}</div>
              {!pddShop && <div className='self'>自营</div>}
              {isHot === 1 && <div className='hot'>热卖</div>}
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className='shop-collect'>
      <Tab
        {...{
          currentIndex: 1,
          store
        }}
      />
      <div className='shop-list'>{renderShopList()}</div>{' '}
      <div className='recommend-list'>
        <div className='title-floor'>
          <div className='title-floor'>商品推荐</div>
        </div>
        <div className='list'>{renderRecommendList()}</div>
      </div>
    </div>
  );
};

export default inject('shopCollect')(observer(ShopCollect));

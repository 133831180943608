class Reg {
  isNum(str) {
    let reg = /^[0-9]+(.[0-9])?[0-9]*$/;
    return reg.test(str);
  }
}

const reg = new Reg();

export default reg;

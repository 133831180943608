import React from "react";
import css from 'styles/home/homeGuessYouLike.module.scss'
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import GoodsType, {goodsTypeEnumMap} from "enum/GoodsType";
import classNames from "classnames";
import showPrice from "utils/show-price";
import MyRouterUtil from "utils/MyRouterUtil";

const Index = ({goodsList = []}) => {

    const history = useHistory();

    // todo 商品详情
    const toGoodsInfo = ({goodsType, id}) => {
        MyRouterUtil.build({history}).toGoodsInfo({goodsType, id})
    }


    return (
        <div className={css.index}>
            <div className={css.container}>
                <div className={css.titleWrapper}>
                    <div className={css.title}>
                        猜你喜欢
                    </div>
                </div>
                <div className={css.goodsList}>
                    {
                        !!goodsList.length && goodsList?.slice(0, 2)?.map((item, i) => {
                            const {id, mainPic, name, originPrice, currentPrice, isHot, isPddShop, isJdShop} = item;
                            const isSelf = !isPddShop && !isJdShop

                            let goodsType;
                            if (isPddShop) {
                                goodsType = GoodsType.PDD
                            } else if (isJdShop) {
                                goodsType = GoodsType.JD
                            } else {
                                goodsType = GoodsType.SELF
                            }

                            return (
                                <div key={i} className={css.goods} onClick={() => toGoodsInfo({goodsType, id})}>
                                    <div className={css.left}>
                                        <div className={css.imgWrapper}>
                                            <img src={mainPic} alt=""/>
                                        </div>
                                    </div>
                                    <div className={css.right}>
                                        <div className={css.name}>
                                            <span className={classNames({
                                                [css.self]: goodsType === GoodsType.SELF,
                                                [css.pdd]: goodsType === GoodsType.PDD,
                                                [css.jd]: goodsType === GoodsType.JD
                                            })}>{goodsTypeEnumMap.get(goodsType)}</span>
                                            {name}
                                        </div>
                                        <div className={css.priceWrapper}>
                                            <div className={css.price}>
                                                {showPrice(currentPrice)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(Index)
import React from 'react';
import * as Api from 'utils/api/api';
import 'styles/login.scss';
import {observer} from 'mobx-react';
import {useHistory, useLocation} from 'react-router-dom';
import useMyStores from "hooks/useMyStores";

const BgImg = require('images/login/bg.png');
const InputBg = require('images/login/input-bg.png');
const InputActiveBg = require('images/login/input-active-bg.png');
const UsernameIcon = require('images/login/username.png');
const UsernameActiveIcon = require('images/login/username-active.png');
const PasswordIcon = require('images/login/password.png');
const PasswordActiveIcon = require('images/login/password-active.png');
const EnterBtnBg = require('images/login/enter.png');
const Login = props => {
    const {home} = props

    const {login: store, app, user: userStore} = useMyStores()

    const history = useHistory();
    const location = useLocation();

    async function doLogin() {
        try {
            let {from} = location.state || {from: {pathname: '/'}};

            let {username, password} = store;
            await Api.login({
                username,
                password
            });

            app.token = 'token';
            userStore.login();
            history.replace(from);
        } catch (error) {
        }
    }

    return (
        <div className='glx-login'>
            <img src={BgImg} alt='' className='bg'/>
            <button
                className='login-btn'
                onClick={() => {
                    history.replace({
                        pathname: '/my/account/register'
                    });
                }}
            >
                还没有账号？去注册
            </button>
            <div className='title-text'>5G商城网</div>
            <div className='welcome-text'>欢迎您</div>
            <div className='login-text'>Login</div>

            <div className='card'>
                <div className='floor username'>
                    <div className='input-wrapper'>
                        <img
                            src={store.usernameFocus ? InputActiveBg : InputBg}
                            alt=''
                            className='bg-img'
                        />
                        <img
                            src={store.usernameFocus ? UsernameActiveIcon : UsernameIcon}
                            alt=''
                            className='icon username'
                        />
                        <div className={`label ${store.usernameFocus && 'active'}`}>
                            用户名
                        </div>
                        <input
                            type='text'
                            className='input'
                            value={store.username}
                            onFocus={() => {
                                store.usernameFocus = true;
                            }}
                            onBlur={() => {
                                store.usernameFocus = false;
                            }}
                            onChange={e => {
                                store.username = e.currentTarget.value;
                            }}
                        />
                    </div>
                </div>
                <div className='floor password'>
                    <div className='input-wrapper'>
                        <img
                            src={store.passwordFocus ? InputActiveBg : InputBg}
                            alt=''
                            className='bg-img'
                        />
                        <img
                            src={store.passwordFocus ? PasswordActiveIcon : PasswordIcon}
                            alt=''
                            className='icon username'
                        />
                        <div className={`label ${store.passwordFocus && 'active'}`}>
                            密码
                        </div>
                        <input
                            type='password'
                            className='input'
                            value={store.password}
                            onFocus={() => {
                                store.passwordFocus = true;
                            }}
                            onBlur={() => {
                                store.passwordFocus = false;
                            }}
                            onChange={e => {
                                store.password = e.currentTarget.value;
                            }}
                        />
                    </div>
                </div>
                {/* <div className='floor forget-password'>
            <div className="forget-text">忘记密码？</div>
        </div> */}
                <div className='floor btn-floor '>
                    <button className='enter-btn' onClick={doLogin}>
                        <img src={EnterBtnBg} alt='' className='bg-img'/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default observer(Login);

import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

export async function advImgList({type}) {
    return http({
        method: Method.GET,
        baseURL: DOMAIN + '/api/adv_img',
        params: {
            type
        }
    }).then(res => res.data)
}
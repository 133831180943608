import React, {useEffect, useRef} from 'react';

import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

import './news-swiper.scss';
import {inject, observer} from 'mobx-react';

function NewsSwiper(props) {
  const { news } = props;
  const swiperRef = useRef(null)
  /**
   * 初始化 swiper
   */
  useEffect(() => {
    if (!news.bannerList.length) {
      return;
    }

    new Swiper(swiperRef.current, {
      loop: true,
      width: this.props.width,
      pagination: {
        el: '.swiper-pagination'
      }
    });
  }, [news.bannerList]);

  const handleClick = url => e => {
    // e.stopPropagation()
    this.props.history.push(url);
  };

  return (
    <div className='news-swiper'>
      <div className='swiper-container' ref={swiperRef}>
        <div className='swiper-wrapper'>
          {news.bannerList.map((item, i) => {
            const { pic, src } = item;
            return (
              <div className='swiper-slide' key={i}>
                <img
                  className='news-swiper__img'
                  src={pic}
                  alt=''
                  onClick={this.handleClick(src)}
                />
              </div>
            );
          })}
        </div>
        <div className='swiper-pagination' />
      </div>
    </div>
  );
}

export default inject('news')(observer(NewsSwiper));

import React from 'react'

import './simple-order-header.scss'
import {back} from 'utils/doms'
import BackBlackIcon from './image/back-black.png'

const SimpleOrderHeader = (props) => {
    const {text} = props
    return (
        <div className='simple-order-header'>
            <img
                src={BackBlackIcon}
                className='simple-order-header__back'
                onClick={back}
            />
            <span>{text}</span>
        </div>
    )
}

export default SimpleOrderHeader

import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

export const homeNewsList = async ({pageSize, pageNum}) => {
    const {data} = await http({
        method: Method.POST,
        url: DOMAIN + '/news/notice/index/notice/list',
        data: {
            page: pageNum,
            size: pageSize
        }
    });

    const list = data.msg.objectList;

    const totalNum = data.msg.totalNum;

    return {list, totalNum};
};

/**
 * 获取资讯列表
 * @param {*} param0
 */
export const newsList = async ({
                                   pageNum,
                                   pageSize,
                                   categoryId,
                                   title,
                                   content
                               }) => {
    const {data} = await http({
        method: Method.POST,
        url: DOMAIN + '/news/notice/list',
        data: {
            page: pageNum,
            size: pageSize,
            type: categoryId,
            title,
            content
        }
    });

    return {
        list: data.msg.objectList,
        totalNum: data.msg.totalNum
    };
};

export const newsInfo = async ({id}) => {
    const {data} = await http({
        method: Method.GET,
        url: DOMAIN + '/news/notice/info',
        params: {
            id
        }
    });

    const {
        title,
        content,
        publishTime,
        updateTime,
        source,
        author,
        watchTimes,
        thumbnail,
        categoryId,
        categoryName,
        pre,
        next
    } = data.msg;

    return {
        info: {
            id,
            title,
            content,
            publishTime,
            updateTime,
            source,
            author,
            watchTimes,
            thumbnail,
            categoryId,
            categoryName,
            pre,
            next
        }
    };
};

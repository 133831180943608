import React, {useMemo} from 'react'
import './orders-item.scss'
import {NavLink, Link, useHistory} from 'react-router-dom'
import {preventAndStopPropagation} from 'utils/doms'
import {inject, observer} from 'mobx-react'
import DeliverIcon from './image/deliver-icon.png'
import Enter from './image/enter.png'
import Moment from 'moment'
import OrderStatusEnum from "enum/OrderStatusEnum";
import showPrice from "utils/show-price";
import * as api from 'utils/api/api';
import * as antd from 'antd';
import {useHistry} from 'react-router-dom';

const OrdersItem = ({order, fetchList}) => {

    const history = useHistory()
    const goToOrderDetail = () => {
        history.push(`/orders/${order.id}`)
    }

    const shopName = useMemo(() => {
        if (!order) {
            return "";
        }

        if (order.status === OrderStatusEnum.WAIT_PAY.value) {
            return "商家";
        } else {
            return order.shopName;
        }
    }, [order]);
    return (
        <div className='orders-item' onClick={goToOrderDetail}>
            <div className='orders-item-headline'>
                <div className='orders-item-headline__left'>
                    <img className='orders-item-headline__shop-icon' src='' alt=''/>
                    <span className='orders-item-headline__shop-name'>{shopName}</span>
                </div>
                <div className='orders-item-headline__right'>
                    <img className='orders-item-headline__status-icon' src='' alt=''/>
                    <span className={`orders-item-headline__status${
                        order.status === OrderStatusEnum.HAS_COMPLETED.value
                            ? ' orders-item-headline__status--finished'
                            : ''
                    }`}
                    >{OrderStatusEnum.getLabelByValue(order.status)}</span>
                    <img className='orders-item-headline__delete' src='' alt=''/>
                </div>
            </div>
            {
                order.status === OrderStatusEnum.HAS_DELIVERED.value &&
                order.delivery?.traceList?.length &&
                <OrdersItemDeliver
                    delivery={order.delivery}
                    orderId={order.id}
                />
            }
            <OrderProductList order={order}/>
            <OrderOperate order={order} fetchList={fetchList}/>
        </div>
    )
}

export default inject('orderList')(observer(OrdersItem))


const OrderOperate = inject('orderList')(
    observer(({order, orderList: store, fetchList}) => {

        const history = useHistory();

        const doReceive = (deliveryId) => {
            api.receiveDelivery({id: deliveryId}).then(() => {
                fetchList();
            })
        }
        const buyAgain = () => {
            api.buyOrderAgain({id: order.id}).then(() => {
                history.push("/cart");
            });
        }
        return (
            <div className='orders-item-operate' onClick={preventAndStopPropagation}>
                {/*{order.status === OrderStatusEnum.AFTER_SALE.value && (*/}
                {/*    <NavLink*/}
                {/*        to={`/orders/${order.id}/refund`}*/}
                {/*        className='orders-item-operate__refund-progress'*/}
                {/*    >*/}
                {/*        退款进度*/}
                {/*    </NavLink>*/}
                {/*)}*/}
                {order.status === OrderStatusEnum.AFTER_SALE.value && (
                    <NavLink
                        to={`/orders/${order.id}/refund`}
                        className='orders-item-operate__refund-detail'
                    >
                        退款明细
                    </NavLink>
                )}
                {OrderStatusEnum.canModifyAddress(order.status) && (
                    <NavLink
                        to={`/orders/${order.id}/modify`}
                        className='orders-item-operate__modify'
                    >
                        修改订单
                    </NavLink>
                )}
                {
                    order.status === OrderStatusEnum.HAS_DELIVERED.value &&
                    <a
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            store.showDeliveryModal(order.id)
                        }}
                        className='orders-item-operate__deliver'
                    >
                        查看物流
                    </a>
                }
                {order.status === OrderStatusEnum.HAS_COMPLETED.value && (
                    <NavLink to="" className='orders-item-operate__buy'
                             onClick={e => {
                                 e.preventDefault();
                                 buyAgain();
                             }}>
                        再次购买
                    </NavLink>
                )}
                {order.status === OrderStatusEnum.HAS_DELIVERED.value && (
                    <Link
                        to={`/orders/${order.id}/confirm`}
                        className='orders-item-operate__confirm'
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation();
                            doReceive(order.delivery?.id);
                        }}
                    >
                        确认收货
                    </Link>
                )}
                {order.status === OrderStatusEnum.WAIT_PAY.value && (
                    <NavLink to={`/orders/${order.id}`} className='orders-item-operate__pay'>
                        立即付款
                    </NavLink>
                )}
            </div>
        )
    })
)

const OrdersItemDeliver = inject('orderList')(
    observer(({delivery, orderId, orderList: store}) => {
        const handleClickDeliver = (e) => {
            e.stopPropagation()
            e.preventDefault()
            store.showDeliveryModal(orderId);
        }

        const lastTrace = useMemo(() => {
            if (!delivery || !delivery.traceList || !delivery.traceList.length) {
                return null;
            }

            return delivery.traceList[delivery.traceList.length - 1];
        }, [delivery])

        return (
            <div className='orders-item-deliver' onClick={handleClickDeliver}>
                <img src={DeliverIcon} className='orders-item-deliver__icon'/>
                {
                    lastTrace?.acceptStation &&
                    lastTrace?.acceptTime &&
                    <div className='orders-item-deliver-info'>
                        <div className='orders-item-deliver-info__desc'>{lastTrace?.acceptStation}</div>
                        <div className='orders-item-deliver-info__time'>
                            {Moment(delivery.lastTrace?.acceptTime).format('YYYY-MM-DD hh:mm:ss')}
                        </div>
                    </div>
                }
                <img src={Enter} alt='' className='orders-item-deliver__enter'/>
            </div>
        )
    })
)

const OrderProductList = ({order}) => {

    const firstSku = useMemo(() => {
        return order.productList?.[0];
    }, [order]);

    return <div className='order-product-list'>
        {
            order.productList.length === 1
                ? <div className="single-wrapper">
                    <img src={firstSku?.mainImg} alt='' className='img'/>
                    <p className='name'>{firstSku?.name}</p>
                    <div className='price'>
                        {showPrice(order.price)}
                    </div>
                </div>
                : <div className="multiple-wrapper">
                    {
                        order.productList?.slice(0, 4)
                            .map(item => {

                                return <div className="sku-product" key={item.id}>
                                    <img src={item.mainImg} alt='商品主图' className='main-img'/>
                                </div>
                            })
                    }
                    {/*<div className="extra">*/}
                    {/*    <div className="price">{showPrice(order.price)}</div>*/}
                    {/*    <div className="count">共{order.productList?.length}件</div>*/}
                    {/*</div>*/}
                </div>
        }
    </div>
}
import React from 'react';
import './shop3.scss';
import Head from 'components/merchant/head/Head';
import Step from 'components/merchant/step/Step';
import BottomButton from 'components/merchant/bottom-button/BottomButton';
import {InputItem, List, Picker, Toast} from 'antd-mobile';
import {createForm} from 'rc-form';
import {inject, observer} from 'mobx-react';

const Shop3 = props => {
    const {merchant, form} = props;
    const {shop2, shop3} = merchant;
    const {getFieldProps} = form;

    const doSubmit = () => {

        if (!shop3.shopName) {
            Toast.fail("请填写期望店铺名称", 1);
            return;
        }

        if (!shop3.shopUsername) {
            Toast.fail("请填写店铺用户名", 1);
            return;
        }

        if (!shop3.shopPassword) {
            Toast.fail("请填写店铺登陆密码", 1);
            return;
        }

        merchant.submit();
    }

    return (
        <div className='glx-merchant-shop-3'>
            <Head/>
            <Step/>

            <div className='content'>
                <div className='title-floor'>
                    <div className='title'>店铺命名</div>
                    <div className='floor'>
                        <div className='label type'>期待店铺类型</div>
                        <div className='shop-name'>{shop2.shopType?.value}</div>
                    </div>
                    <div className='sub-title'>旗舰店铺命名规范</div>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='tip'>
                            店铺名称：品牌名|类目描述|旗舰店/官方旗舰店
                            (也可自定义,如：***官方旗 舰店)
                        </div>
                    </div>
                    <div className='row'>
                        <div className='shop-name-title'>店铺名称</div>
                    </div>
                    {/* 类目描述关键词 */}
                    <InputItem
                        {...getFieldProps('control')}
                        placeholder='请填写类目描述关键词'
                        value={shop3.categoryKeyword}
                        onChange={value => {
                            shop3.categoryKeyword = value;
                        }}
                    />
                    {/* 店铺后缀 */}
                    <Picker
                        title='店铺后缀'
                        cols={1}
                        data={[
                            {
                                value: true,
                                label: '是'
                            },
                            {
                                value: false,
                                label: '否'
                            }
                        ]}
                        value={[shop3.ifAddSuffix]}
                        onOk={value => {
                            const [ifAddSuffix] = value;
                            shop3.ifAddSuffix = ifAddSuffix;
                        }}
                    >
                        <List.Item>店铺后缀</List.Item>
                    </Picker>
                    {/* 期望店铺名称 */}
                    <InputItem
                        {...getFieldProps('control')}
                        placeholder='期望店铺名称'
                        value={shop3.shopName}
                        onChange={value => {
                            shop3.shopName = value;
                        }}
                    />
                    {/* 期望店铺登录用户名 */}
                    <InputItem
                        {...getFieldProps('control')}
                        placeholder='期望店铺登录用户名'
                        value={shop3.shopUsername}
                        onChange={value => {
                            shop3.shopUsername = value;
                        }}
                    />
                    {/* 期望店铺登录密码 */}
                    <InputItem
                        {...getFieldProps('control')}
                        type='password'
                        placeholder='期望店铺登录密码'
                        value={shop3.shopPassword}
                        onChange={value => {
                            shop3.shopPassword = value;
                        }}
                    />
                </div>
            </div>
            <BottomButton
                className='bottom-btn'
                onClick={doSubmit}
            >
                确认
            </BottomButton>
        </div>
    );
};

export default createForm()(inject('merchant')(observer(Shop3)));

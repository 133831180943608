import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import BackIcon from 'images/common/back.png'
import ShopImage from 'images/shops/shop.png'
import Back from 'components/common/back/Back'
import BannerImage from 'images/shops/banner-temp.jpg'

import 'styles/shops.scss'
import ShopPreview from 'components/shops/shop-preview/ShopPreview'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { PullToRefresh } from 'antd-mobile'
import { useState } from 'react'
import { useRef } from 'react'

const Shops = props => {
  const { shops: store } = props
  const container = useRef()

  /**
   * 获取店铺列表
   */
  const fetchList = useCallback(() => {
    store.fetchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.pageSize])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  const onScroll = e => {
    const rect = container.current.getBoundingClientRect()
    const top = rect.top
    const height = rect.height
    const clientHeight = document.body.clientHeight
    if (clientHeight - top >= height - 1 && !store.refreshing) {
      store.refreshing = true
      store.pageSize += store.PAGE_SIZE
    }
  }

  useEffect(() => {
    window.addEventListener('touchmove', onScroll)
    return () => {
      window.removeEventListener('touchmove', onScroll)
    }
  }, [])

  return (
    <div className='glx-shops'>
      <div className='shops-header'>
        <Back className=''>
          <img className='shops-header__back' src={BackIcon} alt='' />
        </Back>
        <img className='shops-header__icon' src={ShopImage} alt='' />
        <img className='shops-header__banner' src={BannerImage} alt='' />
      </div>
      <div
        className='shops-content-wrapper'
        // damping={60}
        // direction='up'
        // refreshing={store.refreshing}
        // onRefresh={() => {
        //   store.pageSize += store.PAGE_SIZE
        // }}
        ref={container}
      >
        <div className='shops-content'>
          {store.list.map((shop, idx) => (
            <ShopPreview key={idx} shop={shop} />
          ))}
          <div className='shops-loading'>
            {store.refreshing ? '加载中' : '加载更多'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default inject('shops')(observer(Shops))

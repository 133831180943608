import {makeAutoObservable, observable} from 'mobx';
import Gender from 'enum/CompanyGender';

class Company {
  @observable _gender = Gender.Male;
  @observable _name = '';
  @observable _mobilephone = '';
  @observable _email = '';

  constructor() {
    makeAutoObservable(this)
  }
  get gender() {
    return this._gender;
  }

  set gender(gender) {
    this._gender = gender;
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get mobilephone() {
    return this._mobilephone;
  }

  set mobilephone(mobilephone) {
    this._mobilephone = mobilephone;
  }

  get email() {
    return this._email;
  }

  set email(email) {
    this._email = email;
  }
}

export default new Company();

import React from 'react';
import './add-license.scss';
import { InputItem, Radio, List, Picker, DatePicker } from 'antd-mobile';
import { createForm } from 'rc-form';
import { inject, observer } from 'mobx-react';
import UploadImg from './partials/upload-img/UploadImg';
import Moment from 'moment';

const AddLicense = props => {
  const { className, merchant } = props;
  const { shop2: store } = merchant;

  const DateText = ({ extra, onClick, children }) => {
    return (
      <div className='date-text' onClick={onClick}>
        <input
          type='text'
          value={children}
          placeholder='请选择'
          className='input'
        />
      </div>
    );
  };

  const renderTableBody = () => {
    let floorList = [];

    for (const [categoryId, license] of store.licenseMap) {
      const { categoryName, picture, endDate, isInfinite } = license;
      const onChange = license => {
        store.licenseMap.set(categoryId, license);
      };
      floorList.push(
        <div className='floor' key={categoryId}>
          <div className='item category'>{categoryName}</div>
          <div className='item picture'>
            <UploadImg
              className='upload-img'
              text={picture ? '重新上传' : '上传文件'}
              setImg={file => {
                onChange({ ...license, picture: file });
              }}
            />
          </div>
          <div className='item date'>
            <DatePicker
              mode='date'
              title='到期日'
              value={endDate}
              onChange={date => onChange({ ...license, endDate: date })}
            >
              <DateText>
                {endDate && Moment(endDate).format('YYYY-MM-DD')}
              </DateText>
            </DatePicker>
            <Radio
              className='end-date-infinite'
              checked={license.isInfinite}
              onClick={e => {
                onChange({ ...license, isInfinite: !isInfinite });
              }}
            >
              永久
            </Radio>
          </div>
        </div>
      );
    }

    return floorList;
  };

  return (
    <div className={`glx-merchant-add-license ${className}`}>
      <div className='table-header'>
        <div className='item category'>类目名称</div>
        <div className='item picture'>电子版</div>
        <div className='item date'>到期日</div>
      </div>
      <div className='table-body'>{renderTableBody()}</div>
    </div>
  );
};

export default createForm()(inject('merchant')(observer(AddLicense)));

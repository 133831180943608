import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import './category-type.scss';

const CategoryType = props => {
  const {
    id,
    optId,
    optName,
    isSelf,
    icon,
    uncheckedIcon,
    hasSelf,
    active,
    onClick
  } = props;
  return (
    <div
      className={`${active ? 'category-type--active' : 'category-type'}`}
      onClick={onClick}
    >
      <span className='category-type__name'>{optName}</span>
    </div>
  );
};

export default inject('category')(observer(CategoryType));

import React from 'react'

import './orders-placeholder.scss'
import EmptyOrdersBg from 'images/orders/empty-orders.jpg'

const OrdersPlaceholder = (props) => {
  return (
    <div className='orders-placeholder'>
      <img src={EmptyOrdersBg} className='orders-placeholder__bg' />
      <span>您还没有相关订单</span>
    </div>
  )
}

export default OrdersPlaceholder

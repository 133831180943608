import {makeAutoObservable, observable} from "mobx";

class AddressStore {
    @observable _list = [];

    constructor() {
        makeAutoObservable(this)
    }
    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }
}

const store = new AddressStore()

export default store

import EnumUtil from "enum/EnumUtil";

const type = {
    WE_CHAT: {
        value: 1,
        label: '微信支付'
    },
    A_LI_PAY: {
        value: 2,
        label: '支付宝支付'
    },
    UNION_PAY: {
        value: 3,
        label: '银联支付'
    }
}

type.getByValue = EnumUtil.getByValue.bind(type);
type.getLabelByValue = EnumUtil.getLabelByValue.bind(type);

export default type;
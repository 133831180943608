import React, {useEffect} from 'react'
import './play-video.scss'
import useQuery from "hooks/useQuery";
import {observer} from "mobx-react";
import {useLocalStore} from "mobx-react-lite";
import * as Api from 'utils/api/api'
import DPlayer from 'dplayer';

const PlayVideo = () => {

    const query = useQuery();

    const localStore = useLocalStore(() => ({
        videoInfo: undefined
    }))

    useEffect(() => {
        if (!query.id) {
            return;
        }

        Api.videoInfo({id: query.id}).then(({info}) => {
            localStore.videoInfo = info;
        })
    }, [query.id])

    useEffect(() => {
        if (!localStore.videoInfo) {
            return;
        }

        const player = new DPlayer({
            container: document.getElementById('play-video__player'),
            video: {
                url: localStore.videoInfo.path,
                pic: localStore.videoInfo.videoFrame
            },
            autoplay: true
        });
    }, [localStore.videoInfo])

    return (
        <div className="glx-play-video">
            <div className="player" id='play-video__player'/>
        </div>
    )
}

export default observer(PlayVideo);
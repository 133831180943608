import React from "react";
import { useHistory } from "react-router-dom";

import Divider from "../../common/divider/Divider";
import ShopType from "enum/ShopType";

import "./shop-preview.scss";
import showPrice from "utils/show-price";
const ShopPreview = props => {
  const history = useHistory();
  const { button } = props;
  const {
    shopId,
    shopName,
    thumbnail,
    merchantType,
    hasCollect,
    collectId,
    avgDesc,
    avgServ,
    avgLgst,
    shopSoldNum,
    pddShop,
    goodsList
  } = props.shop;
  function goToShop(id) {
    history.push(`/shop/${id}?type=${pddShop ? 1 : 0}`);
  }
  function toDetail(id, pddShop) {
    history.push({
      pathname: `/detail/${id}`,
      search: `?type=${pddShop === true ? 1 : 0}`
    });
  }

  return (
    <div className="shop-preview">
      <div className="shop-preview-header">
        <div className="shop-preview-header__info">
          <div className="img-wrapper">
            <img
              className="img"
              src={thumbnail || require("./img/no-logo.png")}
              alt=""
            />
          </div>
          <p className="shop-preview-header__text">
            <span className="shop-preview-header__title">{shopName}</span>
            {/* <span className='shop-preview-header__follower'>
              {format(followers)}人关注
            </span> */}
          </p>
        </div>

        <span
          className="shop-preview-header__opt"
          onClick={() => {
            goToShop(shopId);
          }}
        >
          进店
        </span>
      </div>
      <Divider />
      <div className="shop-preview-content">
        {goodsList?.length ? (
          goodsList.map((goods, i) => {
            const { goodsId, pic, price } = goods;
            return (
              <div
                className="goods"
                key={goodsId}
                onClick={() => {
                  toDetail(goodsId, pddShop);
                }}
              >
                <div className="img-block">
                  <img src={pic} alt="" className="img" />
                </div>
                <div className="price-block">{showPrice(price)}</div>
              </div>
            );
          })
        ) : (
          <img src={require("./img/no-src.png")} alt="" className="no-src" />
        )}
      </div>
    </div>
  );
};

export default ShopPreview;

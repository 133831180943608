import React from 'react';
import './complete.scss';
import Head from 'components/merchant/head/Head';
import Step from 'components/merchant/step/Step';
import BottomButton from 'components/merchant/bottom-button/BottomButton';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import MerchantStatus from 'enum/MerchantStatus';

const Complete = props => {
  const history = useHistory();
  const { merchant } = props;

  return (
    <div className='glx-merchant-complete'>
      <Head
        goBack={() => {
          history.goBack();
        }}
      />
      <Step />
      {merchant.merchantStatus.status === MerchantStatus.approve ? (
        <div className='content success'>
          <div className='img-block'>
            <img src={require('./img/success.png')} alt='' className='img' />
          </div>
          <div className='title-block'>
            <div className='title'>审核通过</div>
            <div className='sub-title'>恭喜您</div>
          </div>
          <div className='tip-floor'>感谢您在本店申请入驻</div>

          <div className='card'>
            <div className='floor'>
              <div className='label'>店铺名称</div>
              <div className='value'>{merchant.merchantStatus?.shopName}</div>
            </div>

            <div className='floor'>
              <div className='label'>店铺用户名</div>
              <div className='value'>
                {merchant.merchantStatus?.shopUsername}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='content failed'>
          <div className='img-block'>
            <img src={require('./img/failed.png')} alt='' className='img' />
          </div>
          <div className='title-block'>
            <div className='title'>审核未通过！</div>
            <div className='sub-title'>抱歉</div>
          </div>
          <div className='tip-floor'>
            填写资料可能出现问题，请
            <a
              href=''
              className='retry'
              onClick={e => {
                e.preventDefault();

                merchant.resetStep();
              }}
            >
              重新尝试
            </a>
          </div>
        </div>
      )}

      <BottomButton
        className='bottom-btn'
        onClick={() => {
          history.goBack();
        }}
      >
        返回首页
      </BottomButton>
    </div>
  );
};

export default inject('merchant')(observer(Complete));

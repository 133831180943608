import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

export const payTypeList = ({isOpen}) => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/api/pay-type/list',
        params: {
            isOpen
        }
    }).then(res => res.data)
}

export const isOpenPayTypeList = () => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/api/pay-type/list',
        params: {
            isOpen: true
        }
    }).then(res => res.data)
}
export function back() {
  window.history.back()
}

export function gotoPage(url) {
  if (url) {
    window.location.href = url
  }
}

export const prevent = (e) => e.preventDefault()
export const preventAndStopPropagation = (e) => {
  e.stopPropagation()
  e.preventDefault()
}

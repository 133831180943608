import React, {useCallback, useEffect} from 'react'

import './pay-success.scss'

import SuccessBg from './image/success-bg.png'
import {useHistory} from 'react-router-dom';
import {inject, observer} from "mobx-react";
import LookAroundFloor from './partials/look-around-floor/LookAroundFloor';
import * as Api from "utils/api/api";

const PaySuccess = ({paySuccess: store}) => {
    const history = useHistory();

    const goOrders = () => {
        history.push('/orders');
    }

    /**
     * 随便看看
     */
    const fetchLookAround = useCallback(() => {
        store.lookAround.loading = true;
        Api.recommendList({
            pageSize: store.lookAround.pageSize,
            pageNum: store.lookAround.pageNum,
        }).then(({list}) => {
            store.lookAround.list = list.slice(0, store.lookAround.pageSize);
        }).finally(() => {
            store.lookAround.loading = false;
        })
    }, [store.lookAround.pageSize])
    useEffect(() => {
        fetchLookAround();
    }, [fetchLookAround]);

    return (
        <div className='pay-success'>
            <img className='pay-success__bg' alt='' src={SuccessBg}/>

            <div className='pay-success-status'>
                <div className='pay-success-status__status'>支付成功</div>
                <div className='pay-success-status__instr'>
                    请在个人中心查看此订单详情
                </div>
            </div>

            <div className='pay-success-ope'>
                <div className='pay-success-ope__order' onClick={goOrders}>查看订单</div>
            </div>

            <div className="recommend-wrapper">
                <LookAroundFloor/>
            </div>
        </div>
    )
}

export default inject('paySuccess')(observer(PaySuccess));

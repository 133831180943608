import React from 'react'
import DeliverCurrentIcon from './image/deliver-current-icon.png'
import ToAddressIcon from 'images/orders/detail-to-address-icon.png'

import {inject, observer} from 'mobx-react'
import {preventAndStopPropagation} from 'utils/doms'
import Moment from 'moment'

import './orders-deliver.scss'
import {NavLink} from 'react-router-dom'

const OrdersDeliver = ({orderList: store}) => {
    const CurrentIcon = () => (
        <img src={DeliverCurrentIcon} alt='' className='deliver-current-icon'/>
    )
    const NotCurrentDot = () => <span className='deliver-not-current-spot'></span>

    return (
        <div className='orders-deliver-wrapper' onClick={()=>{store.closeDeliveryModal()}}>
            <div className='orders-deliver' onClick={preventAndStopPropagation}>
                <div className='orders-deliver-head'>
                    <p>等待收货</p>
                    <div>
                        <NavLink to={`/orders/${store.currentOrder?.id}/confirm`}>确认收货</NavLink>
                    </div>
                    {/*<img src={imgUrl} />*/}
                </div>
                <div className='orders-deliver-content'>
                    <div className='orders-deliver-content__container'>
                        {store.currentDelivery?.traceList?.map((trace, idx) => {
                            const isCurrent = idx === store.currentDelivery?.traceList.length - 1;

                            return (
                                <div
                                    className={`orders-deliver-info${
                                        isCurrent ? ' orders-deliver-info--current' : ''
                                    }`}
                                    key={idx}
                                >
                                    <div>
                                        {isCurrent ? <CurrentIcon/> : <NotCurrentDot/>}
                                        <span
                                            className='orders-deliver-info__time'>{trace.acceptTime && Moment(trace.acceptTime).format('MM-DD hh:mm')}</span>
                                    </div>
                                    <div className={`orders-deliver-info__info${
                                        isCurrent
                                            ? ' orders-deliver-info__info--current'
                                            : ''
                                    }`}
                                    >
                                        {trace.acceptStation}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='orders-deliver-bottom'>
                    <NavLink to={`/orders/${store.currentOrder?.id}`}>
                        <span>订单详情</span>
                        <img
                            src={ToAddressIcon}
                            className='orders-deliver-bottom__to-detail'
                        />
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default inject('orderList')(observer(OrdersDeliver))

const SECOND = 1000;
const MINUTE = 1000 * 60; //把分，时，天，周，半个月，一个月用毫秒表示
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

function useSecKill(initStartTime, initEndTime) {
  const secKill = {};
  let date = new Date();

  /**
   * 是否已经开始了
   */
  secKill.hasStart = () => {
    if (initStartTime && initEndTime && initStartTime > date.getTime()) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * 获取开始和结束时间
   */
  if (!initStartTime || !initEndTime) {
    //   let time = new Date().getTime();

    //   time %= DAY;
    //   const hour = time / HOUR;

    //   time %= HOUR;
    //   const minute = time / MINUTE;

    //   time %= MINUTE;
    //   const second = time / SECOND;

    const num = date.getHours() % 2;

    if (num === 0) {
      secKill.startTime = new Date(date.getTime());
      secKill.startTime.setMinutes(0);
      secKill.startTime.setSeconds(0);
    } else if (num === 1) {
      secKill.startTime = new Date(date.getTime());
      secKill.startTime.setHours(secKill.startTime.getHours() - 1);
      secKill.startTime.setMinutes(0);
      secKill.startTime.setSeconds(0);
    }
    secKill.endTime = new Date(secKill.startTime.getTime());
    secKill.endTime.setHours(secKill.endTime.getHours() + 2);
  } else {
    secKill.startTime = new Date(initStartTime);
    secKill.endTime = new Date(initEndTime);
  }

  secKill.getTime = () => {
    let timeObj = {};

    // let startTime = new Date(),
    //   endTime = new Date();

    let diffTime = secKill.endTime.getTime() - date.getTime();

    diffTime %= DAY;
    let hour = diffTime / HOUR;
    hour = Math.floor(hour);
    if (hour < 10) {
      hour = '0' + hour;
    }

    diffTime %= HOUR;
    let minute = diffTime / MINUTE;
    minute = Math.floor(minute);
    if (minute < 10) {
      minute = '0' + minute;
    }

    diffTime %= MINUTE;
    let second = diffTime / SECOND;
    second = Math.floor(second);
    if (second < 10) {
      second = '0' + second;
    }

    timeObj = { hour, minute, second };

    return timeObj;
  };

  return secKill;
}

export default useSecKill;

import React from 'react';
import {NavLink, useHistory} from 'react-router-dom';

import VideoTypes from '../../../constants/videoConst';
import Back from '../../../components/common/back/Back';
import BackIcon from '../../../images/common/back.png';
import Logo from './logo.png';

import './videos-header.scss';
import {inject, observer} from 'mobx-react';

const VideosHeader = props => {
    const {video} = props;
    const history = useHistory();

    const goBack = () => {
        history.replace("/live");
    }

    return (
        <div className='videos-header'>
            <img className='videos-header__back' src={BackIcon} alt='' onClick={goBack}/>
            <img className='videos-header__img' src={Logo} alt=''/>
            <div className='videos-header__tabs'>
                {VideoTypes.map((item, i) => {
                    const active = video.currentCategoryIndex === i;
                    return (
                        <span
                            className={`videos-header__tab${
                                active ? ' videos-header__tab--active' : ''
                            }`}
                            key={i}
                        >
              <NavLink to={`/videos/${item.keyword}`}>{item.name}</NavLink>
            </span>
                    );
                })}
            </div>
        </div>
    );
};

export default inject('video')(observer(VideosHeader));

import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';


export async function secKillList({page, size}) {
    return http({
        url: DOMAIN + '/api/sec-kill',
        method: Method.GET,
        params: {
            page,
            size
        }
    }).then(res => res.data)
}
import React from "react";
import { useHistory } from "react-router-dom";

import "./product-card.scss";
import showPrice from "utils/show-price";

function ProductCard(props) {
  const history = useHistory();
  let {
    id,
    name,
    originPrice,
    currentPrice,
    shopId,
    soldNum,
    mainPic,
    hasCollect,
    collectId,
    categoryId,
    isHot,
    isNew,
    isSpecialPrice,
    serviceTags,
    mallName,
    pddShop,
    className
  } = props;
  //   const goToDetail = () => {
  //     history.push({
  //       pathname: '/detail/' + id + '?type=1'
  //     })
  //   }
  function toDetail(id, pddShop) {
    history.push({
      pathname: `/detail/${id}`,
      search: `?type=${pddShop === true ? 1 : 0}`
    });
  }

  return (
    <div
      className={`glx-home-random-product-card ${className}`}
      onClick={() => {
        toDetail(id, pddShop);
      }}
    >
      <div className="img-block">
        <img src={mainPic} alt="" className="img" />
      </div>
      <div className="info-block">
        <div className="name-floor">{name}</div>
        <div className="price-floor">
          <div className="current-price">{showPrice(currentPrice)}</div>
          {!pddShop && <div className="self">自营</div>}
          {isHot === 1 && <div className="hot">热卖</div>}
        </div>
      </div>
    </div>
  );
}

export default ProductCard;

import React from 'react';
import './message.scss';
import ChatMessage from 'enum/ChatMessage';

const Message = props => {
  const { content, userType, avatar } = props;

  const showType = () => (userType === ChatMessage.FROM ? 'from' : 'to');

  const showAvatar = () =>
    userType === ChatMessage.FROM
      ? avatar
      : require('./img/service-avatar.png');

  return (
    <div className={`glx-message ${showType()}`}>
      {userType === ChatMessage.FROM ? (
        <React.Fragment>
          <div className={`text-block ${showType()}`}>{content}</div>
          <img src={showAvatar()} alt='' className='avatar' />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <img src={showAvatar()} alt='' className='avatar' />
          <div className={`text-block ${showType()}`}>{content}</div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Message;

import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from './api-utils';

export const uploadImg = async ({ file }) => {
  const formData = GetFormData({ file });
  const { data } = await http({
    method: Method.POST,
    url: DOMAIN + '/upload/img',
    headers: {
      [Header.ContentType]: ContentType.FormData
    },
    data: formData
  });

  const filePath = data.msg;

  return {
    filePath
  };
};

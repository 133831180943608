import {BASE_SOCKET, DOMAIN, http, Method} from './api-utils';

export async function imJoinGroup({imGroupId, imUserId}) {
    return http({
        method: Method.PUT,
        url: DOMAIN + '/api/im-group/join',
        data: {
            imGroupId,
            imUserId
        }
    }).then(res => res.data)
}

export async function imQuitGroup({imGroupId, imUserId}) {
    return http({
        method: Method.PUT,
        url: DOMAIN + '/api/im-group/quit',
        data: {
            imGroupId,
            imUserId
        }
    }).then(res => res.data)
}

export async function imGroupInfo({id}) {
    return http({
        method: Method.GET,
        url: DOMAIN + `/api/im-group/${id}`
    }).then(res => res.data)
}

export async function imUserList({groupId}) {
    return http({
        method: Method.GET,
        url: DOMAIN + '/api/im-user',
        params: {
            groupId
        }
    }).then(res => res.data)
}

export async function imUserInfo({imUserId}) {
    return http({
        method: Method.GET,
        url: DOMAIN + `/api/im-user/${imUserId}`
    }).then(res => res.data)
}

export async function addImUser() {
    return http({
        method: Method.POST,
        url: DOMAIN + '/api/im-user',
        data: {}
    }).then(res => res.data)
}

export async function modifyImUser({imUserId, userId, nickname, avatar, gender}) {
    return http({
        method: Method.PUT,
        url: DOMAIN + `/api/im-user/${imUserId}`,
        data: {
            userId,
            nickname,
            avatar,
            gender
        }
    }).then(res => res.data)
}

export const imSocket = ({imUserId}) => {
    return `${BASE_SOCKET}/api/socket/im/${imUserId}`
}

/*
export async function imUserSign({userId}) {
    return http({
        method: Method.GET,
        url: DOMAIN + `/api/im/user-sign`,
        params: {
            userId
        }
    }).then(res => res.data)
}

export async function imDeleteAccount({userId}) {
    return http({
        method: Method.DELETE,
        url: DOMAIN + `/api/im/account/${userId}`
    })
}*/

import React, {useMemo} from 'react';
import './need-know.scss';
import Step from 'components/merchant/step/Step';
import {inject, observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import Head from 'components/merchant/head/Head';
import BottomButton from 'components/merchant/bottom-button/BottomButton';

const NeedKnow = props => {
    const {merchant} = props;
    const history = useHistory();

    const adminUrl = useMemo(() => {
        return `${window.location.protocol}//${window.location.host}/admin`;
    }, [window.location.protocol, window.location.host])

    return (
        <div className='glx-merchant-need-know'>
            <Head/>
            <Step className='step'/>
            <div className='content'>
                <div className='title-floor'>入驻须知</div>
                <div className='content-floor'>
                    <div className='container'>
                        1商家在商城开设店铺，不同店铺类型、不同经营类目（所属行业）所需资质内容及标准不同，具体资质标准请参见《商城开放平台招商资质标准细则》，资质提交流程详情参见
                        <span className='color'>《商家在线入驻步骤》</span>
                        <br/>
                        2商家提交的资质内容需满足条件
                        <br/>
                        2.1真实性：商家务必确保提供资质的不存在伪造、变造等情况，若商家提供的相关资质为第三方提供，如商标注册证、授权书等，请务必先行核实文件的真实性。
                        <br/>
                        2.2完整性：按资质提交流程提示上传提交全部所需的资质文件。
                        <br/>
                        2.3有效性：商家提交的相关资质需在有效期内，在营店铺资质到期或即将到期，需及时更新相关资质信息。
                        <br/>
                        <br/>
                        商城申请入驻资质相关要求
                        <br/>
                        <br/>
                        1商家务必确保提交资质的真实性，入驻环节商家向平台提供虚假资质的，平台有权限制其入驻，
                        <br/>
                        且限制关联其店铺的入驻；若经营环节商家向平台提供虚假资质的，一经查实，平台有权处理。
                        <br/>2 商家资质失效前90天起，商家后台（
                        <span
                            className='color'
                            onClick={() => {
                                window.top.open(adminUrl);
                            }}
                        >
              {adminUrl}
            </span>
                        ）会提醒即将到期的资质，商家需及时对资质信息进行更新。
                        <br/>
                        2.1如商家主体资质、行业资质逾期未提交的，自资质到期日起，平台将对其实施店铺监管（包括但不限于搜索降权、店铺屏蔽、处罚扣分等方式）直至商家补齐相关资质。
                        <br/>
                        2.2如商家品牌资质逾期未提交的，自资质到期日起，平台将先取消相应品牌授权并下架该品牌相关商品，直至商家补齐相关资质并且审核通过后，再恢复店铺该品牌的销售限制。
                        <br/>
                        2.3提交资质不合格被驳回的，商家需在被驳回之日起15日内提交有效资质，否则，按照本规范中第3.2.1项、第3.2.2项进行处理。商家提交虚假资质的，按本规范第3.1项进行处理。
                        <br/>
                        2.4商家店铺因资质失效而被进行店铺监管的，在监管期间商家技术服务年费正常结算。
                        <br/>
                        3商城有权对在营商家资质进行常规复检或特别复检，若在常规复检中发现资质不合格，商家需在15天内按商城要求补充相关资质。商家若无法按期提供或提供的资质不合格的，按本规范第3.2.3项进行处理。
                        <br/>
                        3.1 特别复检适用情形说明，包括但不限于以下情形：
                        <br/>
                        3.1.1基于司法调查、行政监管、媒体报道、公众质疑等情形，商城有权对商家资质展开特别复检。
                        <br/>
                        3.1.2基于市场秩序管理或交易纠纷处理中掌握的信息，商城有权对商家资质展开特别复检。
                        <br/>
                        <br/>
                        因商家提交虚假资质违反国家法律、法规、国家政策或侵犯他人合法权益（包括但不限于知识产权）的，商家应当独立承担全部法律责任，因此导致商城及其关联公司利益受损的，商家应当全额赔偿。
                        <br/>
                        <br/>
                        附则：
                        <br/>
                        1.商城开放平台商家的行为，发生在本管理规范生效之日以前的，适用当时的规范。发生在本管理规范生效之日以后的，适用本规范。
                        <br/>
                        2. 商城可根据平台运营情况随时调整本管理规范并向商家公示。
                        <br/>
                        3.商家应遵守国家法律、行政法规、部门规章等规范性文件。对任何涉嫌违反国家法律、行政法规、部门规章等规范性文件的行为，本规范已有规定的，适用于本规范。本规范尚无规定的，商城有权酌情处理。但商城对商家的处理不免除其应承担的法律责任。商家在商城的任何行为，应同时遵守与商城及其关联公司签订的各项协议。
                        <br/>
                        4. 本规范于2019年1月6日首次发布，于2019年11月21日修订，于2019年11月28日生效。
                    </div>
                </div>
            </div>
            <BottomButton/>
        </div>
    );
};

export default inject('merchant')(observer(NeedKnow));

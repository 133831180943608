import React from "react";
import './current-goods.scss'
import showPrice from "utils/show-price";

const CurrentGoods = ({goods, toBuy}) => {

    return (
        <div className="glx-live-room__current-goods">
            <div className="title-container">
                <div className="title">直播中的商品</div>
            </div>
            <div className="img-container">
                <img src={goods.mainPic} alt="" className="img"/>
            </div>
            <div className="info-container">
                <div className="price">{showPrice(goods.currentPrice)}</div>
                <div className="buy" onClick={() => toBuy(goods.goodsId)}>去买</div>
            </div>
        </div>
    )
}

export default CurrentGoods;
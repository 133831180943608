import {useEffect} from "react";
import * as Api from "utils/api/api";

function useFetchLiveGoods({live, store}) {
    useEffect(() => {
        if (!live) {
            return;
        }

        Api.liveGoodsList({liveId: live.id}).then(data => {
            store.liveGoodsList = data
        })
    }, [live])

}

export default useFetchLiveGoods;
import React from "react";
import {observer} from "mobx-react";
import css from "./inputFloor.module.scss";
import Gender from "enum/Gender";
import ImMsgType from "enum/ImMsgType";
import {runInAction} from "mobx";

const Index = ({store, socketRef, defaultAvatar, onOpenGoodsListPanel}) => {
    /**
     * 发送弹幕
     * @param key
     */
    const onSendBulletComments = (key) => {
        if (key === "Enter") {
            if (store.bulletComment === "") {
                return;
            }

            const text = store.bulletComment

            socketRef.current?.send(JSON.stringify({
                text
            }))
            store.putMessage({
                from: store.imUser.id,
                avatar: store.imUser.avatar || defaultAvatar,
                nickname: store.imUser.nickname,
                gender: store.imUser.gender || Gender.unknown,
                msgBody: {
                    type: ImMsgType.TEXT,
                    payload: {
                        text
                    }
                }
            })
            store.bulletComment = ''
        }
    }


    /**
     * 点赞
     */
    function onThumb() {
        socketRef.current?.send(JSON.stringify({
            thumb: true
        }))
        store.putMessage({
            from: store.imUser.id,
            avatar: store.imUser.avatar || defaultAvatar,
            nickname: store.imUser.nickname,
            gender: store.imUser.gender || Gender.unknown,
            msgBody: {
                type: ImMsgType.GRP_TIP,
                payload: {
                    text: `${store.imUser.nickname}点赞了`
                }
            }
        })
    }


    return (
        <div className={css.inputFloor}>
            <input
                type="text"
                value={store.bulletComment}
                placeholder='打个招呼吧...'
                onChange={e => {
                    store.bulletComment = e.target.value
                }}
                onKeyDown={e => {
                    runInAction(()=>{
                        onSendBulletComments(e.key)
                    })
                }}
            />
            <div className={css.goodsIconWrapper} onClick={onOpenGoodsListPanel}>
                <img src={require('./img/显示商品列表icon.png')} className={css.goodsIcon} alt=""/>
            </div>
            <div className={css.thumbIconWrapper} onClick={onThumb}>
                <img src={require('./img/拇指icon.png')} className={css.thumbIcon} alt=''/>
            </div>
        </div>
    )
}

export default observer(Index)
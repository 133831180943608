import {
  LIVE_BASE_URL,
  MINI_BASE_URL,
  ONLINE,
  Header,
  ContentType,
  GetFormData,
  Method,
  http,
} from "./api-utils";

/*
 * 测试的时候设为true
 * */
const USE_MOCK_DATA = false;

/**
 * 直播中的流
 * @param page
 * @param size
 * @returns {Promise<{totalNum, list}>}
 */
export const liveList = async ({ page, size }) => {
  return http({
    method: Method.GET,
    url: LIVE_BASE_URL + "/live/streaming",
    params: {
      page,
      size,
    },
  }).then((res) => res.data);
};

/**
 * 小程序二维码
 * @returns {string}
 */
export const miniProgramCode = ({ scene }) => {
  return MINI_BASE_URL + `/miniapp/code?scene=${scene}`;
};

/**
 * 下载小程序二维码
 */
export const downloadMiniProgramCode = () => {
  window.location.href = MINI_BASE_URL + "/miniapp/download-code";
};

/**
 * 根据id获取直播详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const liveInfo = (id) => {
  return http({
    method: Method.GET,
    url: LIVE_BASE_URL + `/live/${id}`,
  }).then((res) => res.data);
};

/**
 * 根据id获取直播的当前直播任务
 * @param liveId
 * @returns {Promise<AxiosResponse<any>>}
 */
const currentLiveTask = ({ liveId }) => {
  return http({
    method: Method.GET,
    url: LIVE_BASE_URL + `/live/current-live-task/${liveId}`,
  }).then((res) => res.data);
};

/**
 * 根据id获取直播详情
 * @param id
 * @returns {Promise<{liveTask: AxiosResponse<*>, live: AxiosResponse<*>}>}
 */
export const liveDto = (id) => {
  return Promise.all([liveInfo(id), currentLiveTask({ liveId: id })]).then(
    ([live, liveTask]) => {
      if (USE_MOCK_DATA) {
        live.pullUrl =
          "http://playertest.longtailvideo.com/adaptive/bipbop/gear4/prog_index.m3u8";
        // live.pullUrl = 'http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8';
      }

      return {
        live,
        liveTask,
      };
    }
  );
};

/**
 * 获取当前直播的商品
 * @param liveTaskId 直播间任务id
 * @returns {AxiosPromise}
 */
export const liveTaskCurrentGoods = ({ liveTaskId }) => {
  return http({
    method: Method.GET,
    url: LIVE_BASE_URL + "/goods/current",
    params: {
      liveTaskId,
    },
  }).then((res) => res.data);
};

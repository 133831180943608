import React, { useState, createRef, useEffect } from 'react';
import './css/upload-img.scss';

function UploadImg(props) {
  const { setImg, src, uploadButton: initUploadButton, className } = props;
  const [imgSrc, setImgSrc] = useState('');
  const [loading, setLoading] = useState(false);

  const fileRef = createRef(null);

  const MAX_SIZE = 2;

  useEffect(() => {
    if (src) {
      setImgSrc(src);
    } else {
      setImgSrc('');
    }
  }, [src]);

  const uploadButton = initUploadButton ? (
    initUploadButton
  ) : (
    <div className='upload-button'>
      <div className='text'>上传</div>
    </div>
  );

  function onClick() {
    fileRef.current.dispatchEvent(new MouseEvent('click'));
  }

  function onChange() {
    const { files } = fileRef.current;
    if (!files || files.length === 0) {
      return;
    }

    let img = files[0];
    setImg(img);

    const imgSrc = URL.createObjectURL(img);
    setImgSrc(imgSrc);

    // let fileReader = new FileReader();
    // fileReader.readAsDataURL(file);

    // fileReader.onload = function(e) {
    //   const fileBase64 = e.target.result;
    //   setLoading(false);
    //   setImgSrc(fileBase64);
    // };
  }

  return (
    <div className='upload-img' onClick={onClick}>
      <input
        className='file'
        ref={fileRef}
        type='file'
        // accept='image/png, image/jpeg'
        accept='image/*'
        onChange={onChange}
      />
      {imgSrc ? (
        <img className='img' src={imgSrc} alt='avatar' />
      ) : (
        uploadButton
      )}
    </div>
  );
}

export default UploadImg;

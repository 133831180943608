import React from 'react';

import './my-recommend.scss';
import ProductCard from 'components/home/product-card/ProductCard';
import { inject, observer } from 'mobx-react';
const MyRecommend = props => {
  const { my: store } = props;
  return (
    <div className='my-recommend'>
      <div className='my-recommend__title'>
        <span>为你推荐</span>
      </div>
      <div className='content'>
        {store.recommendListStore.list.map((item, idx) => {
          return <ProductCard {...item} key={idx} />;
        })}
      </div>
      <div className='my-recommend__placeholder'></div>
    </div>
  );
};

export default inject('my')(observer(MyRecommend));

import {makeAutoObservable, reaction, when} from "mobx";
import * as Api from "utils/api/api";
import {defaultAvatar} from "utils/api/api";

class UserStore {
    id = 0;
    username = "";
    avatar = "";
    nickname = "";
    gender = null;
    birthday = 0;
    token = "";

    constructor() {
        makeAutoObservable(this)

        this.token = sessionStorage.getItem('token')
    }


    setInfo(info) {
        const {id, username, avatar, nickname, gender, birthday} = info;

        this.id = id;
        this.username = username;
        this.avatar = avatar;
        this.nickname = nickname;
        this.gender = gender;
        this.birthday = birthday;
    }


    async login() {
        this.token = 'token';
        // await this.fetchInfo();
    }

    logout() {
        this.token = '';
        this.username = "";
        this.avatar = "";
        this.nickname = "";
        this.gender = null;
        this.birthday = 0;
    }

    async fetchInfo() {
        const {info} = await Api.userInfo();

        this.id = info.id;
        this.username = info.username;
        this.avatar = info.avatar;
        this.nickname = info.nickname;
        this.gender = info.gender;
        this.birthday = info.birthday;
    }

    get isLogin() {
        return !!this.token;
    }
}

const userStore = new UserStore();

when(() => userStore.isLogin, async () => {
    await userStore.fetchInfo()
})

reaction(() => userStore.token, (value) => {
    sessionStorage.setItem('token', value)
})

reaction(() => userStore.avatar, (value) => {
    if (!value) {
        userStore.avatar = defaultAvatar
    }
}, {
    fireImmediately: true
})

export default userStore;

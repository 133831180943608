import React from 'react'

import './my-info.scss'
import {NavLink, useHistory} from 'react-router-dom'
import {inject, observer} from 'mobx-react'

import DefaultAvatar from '../../../images/my/avatar-guest.png'
import EditIcon from 'images/my_v2/edit.jpg'

const path = (pathname) => ({pathname})

const MyInfo = (props) => {
    let {my, app} = props
    let history = useHistory();

    const prevent = (e) => {
        if (!app.hasLogin) {
            e.preventDefault()
        }
    }

    const toLogin = () => {
        history.push({
            pathname: '/my/account/login'
        });
    }

    return (
        <div className='glx-my__my-info'>

            <div className="card-container">
                <div className="card">
                    <img src={require('images/my_v2/info/card-bg.png')} alt=""
                         className='img-bg'/>
                    <div className="modify-container">
                        <div className="modify-text">修改资料</div>
                        <img src={require('images/my_v2/info/enter-icon.png')} alt="" className="img-enter"/>
                    </div>
                    <div className="info-container">
                        <div className="avatar-container">
                            <img src={my.avatar || require('images/my_v2/info/default-avatar.png')} alt=""
                                 className="img-avatar"/>
                        </div>
                        <div className="right">
                            <div className="title-container">
                                <div className="text-title" onClick={() => {
                                    if (app.hasLogin) {
                                        return;
                                    }

                                    toLogin();
                                }}>
                                    {app.hasLogin ? my.username : '点击登录'}
                                </div>
                                {
                                    app.hasLogin &&
                                    <img src={require('images/my_v2/info/verified-icon.png')} alt=""
                                         className="img-verified"/>
                                }
                            </div>
                            {
                                !app.hasLogin &&
                                <div className="text-sub-title">登录以获得更多商城特权</div>
                            }
                        </div>
                    </div>
                    {
                        app.hasLogin &&
                        <div className="data-container">
                            <div className="item" onClick={() => {
                                history.push({
                                    pathname: '/my/collect/goods'
                                })
                            }}>
                                <div className="value">{my.goodsNum}</div>
                                <div className="label">商品收藏</div>
                            </div>
                            <div className="item" onClick={() => {
                                history.push({
                                    pathname: '/my/collect/shop'
                                })
                            }}>
                                <div className="value">{my.shopsNum}</div>
                                <div className="label">店铺收藏</div>
                            </div>
                            <div className="item" onClick={() => {
                                history.push({
                                    pathname: '/my/track'
                                })
                            }}>
                                <div className="value">{my.footprintsNum}</div>
                                <div className="label">我的足迹</div>
                            </div>
                        </div>
                    }
                </div>
                <img src={require('images/my_v2/info/card-shadow.png')} alt="" className="img-shadow"/>
                <img src={require('images/my_v2/info/card-top-shadow.png')} alt="" className="img-top-shadow"/>
            </div>
        </div>
    )
}

export default inject('app', 'my')(observer(MyInfo))

import React from 'react';
import 'styles/modify-password.scss';
import { inject, observer } from 'mobx-react';
import * as antd from 'antd';
import { useHistory } from 'react-router-dom';

const ModifyPassword = props => {
  const { modifyPassword: store } = props;
  let history = useHistory();

  async function doModify() {
    if (await store.doModify()) {
      antd.message.success('修改成功');
      store.init();
    }
  }

  return (
    <div className='modify-password'>
      <div className='header'>
        <div
          className='back'
          onClick={() => {
            history.go(-1);
          }}
        >
          <img
            src={require('images/modify-password/back.png')}
            alt=''
            className='arrow'
          />
        </div>
        <div className='title'>修改密码</div>
      </div>

      <div className='form'>
        <div className='floor'>
          <input
            type='password'
            className='input'
            placeholder='输入旧密码'
            value={store.password}
            onChange={e => {
              store.password = e.currentTarget.value;
            }}
          />
        </div>
        <div className='floor'>
          <input
            type='password'
            className='input'
            placeholder='输入新密码'
            value={store.password1}
            onChange={e => {
              store.password1 = e.currentTarget.value;
            }}
          />
        </div>
        <div className='floor'>
          <input
            type='password'
            className='input'
            placeholder='确认新密码'
            value={store.password2}
            onChange={e => {
              store.password2 = e.currentTarget.value;
            }}
          />
        </div>
        <div className='floor'>
          <input
            type='text'
            className='input'
            placeholder='输入验证码'
            value={store.verifyCode}
            onChange={e => {
              store.verifyCode = e.currentTarget.value;
            }}
          />
          <img
            src={store.verifyImg}
            alt=''
            className='verify-img'
            onClick={() => {
              store.refreshVerifyImg();
            }}
          />
        </div>
        <div className='floor btn-floor'>
          <button className='submit-btn' onClick={doModify}>
            确认修改
          </button>
        </div>
      </div>
    </div>
  );
};

export default inject('modifyPassword')(observer(ModifyPassword));

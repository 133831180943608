import React, {useEffect, useMemo} from 'react'
import './order-header.scss'
import OrderHeaderBg from 'images/orders/order-header-bg.png'
import DeliverIcon from './image/deliver-icon.png'
import FinishedIcon from './image/finished-icon.png'
import PayIcon from './image/pay-icon.png'
import BackIcon from './image/back.png'
import {back} from 'utils/doms'
import SimpleOrderHeader from 'components/order/simple-order-header/SimpleOrderHeader'
import {inject, observer} from "mobx-react";
import OrderStatusEnum from "enum/OrderStatusEnum";
import showPrice from "utils/show-price";
import moment from "moment";
import * as api from "utils/api/api";
import * as antd from "antd";
import PayTypeEnum from "enum/PayTypeEnum";
import isWeChat from "utils/isWeChat";
import useQuery from 'hooks/useQuery';
import axios from 'axios';

const RenderStatus = inject('orderPay')
(observer(({order: store, fetchInfo, pay}) => {

        const doReceive = () => {
            api.receiveDelivery({id: store.delivery?.id}).then(() => {
                antd.message.success("提交成功");
                fetchInfo();
            })
        }

        switch (store.status) {
            case OrderStatusEnum.HAS_COMPLETED.value:
                return (
                    <div className='order-header-status'>
                        <div className='order-header-status-title'>
                            <img src={FinishedIcon}/>
                            <span className='order-header-status__text'>订单已完成</span>
                        </div>
                    </div>
                )
            case OrderStatusEnum.HAS_DELIVERED.value:

                return (
                    <div className='order-header-status'>
                        <div className='order-header-status-title'>
                            <img src={DeliverIcon}/>
                            <span className='order-header-status__text'>等待收货</span>
                        </div>
                        {/*<div className='order-header-status-time'>*/}
                        {/*    <span>预计小时后自动确认收货(暂时没有实现)</span>*/}
                        {/*</div>*/}
                        <div className='order-header-status-button' onClick={doReceive}>确认收货</div>
                    </div>
                )
            case OrderStatusEnum.WAIT_PAY.value:
                const leftMoment = moment().subtract(store.createTime, 'milliseconds');
                return (
                    <div className='order-header-status'>
                        <div className='order-header-status-title'>
                            <img src={PayIcon}/>
                            <span className='order-header-status__text'>等待支付</span>
                        </div>

                        <div className='order-header-status-price'>
                            需支付:
                            <span className='order-header-status-price__price'>{showPrice(store.price)}</span>
                        </div>
                        <div className='order-header-status-remain'>
                            剩余：
                            <span
                                className='order-header-status-remain__remain'>{leftMoment.hours()}小时{leftMoment.minutes()}分钟</span>
                        </div>
                        <div className='order-header-status-button' onClick={pay}>去支付</div>
                    </div>
                )
            default:
                return (
                    <div className='order-header-status'>
                        <div className='order-header-status-title'>
                        <span
                            className='order-header-status__text'>{OrderStatusEnum.getLabelByValue(store.status)}</span>
                        </div>
                    </div>
                )
        }
    }
));

const OrderHeader = ({order: store, fetchInfo,pay}) => {
    const query = useQuery();

    const headerHeight = useMemo(() => {
        if (!store.status === undefined) {
            return;
        }

        switch (store.status) {
            case OrderStatusEnum.WAIT_PAY.value:
            case OrderStatusEnum.HAS_DELIVERED.value:
                return '6.24rem';
            case OrderStatusEnum.HAS_COMPLETED.value:
                return '3.6rem';
            default:
                return undefined;
        }
    }, [store.status]);

    if (OrderStatusEnum.AFTER_SALE.value === store.status
        || OrderStatusEnum.HAS_COMPLETED.value === store.status) {
        return <SimpleOrderHeader text={'订单详情'}/>
    } else {
        return (
            <div className={`order-header order-header--${store.status}`}
                 style={{height: headerHeight}}
            >
                <img src={OrderHeaderBg} className='order-header__bg'/>
                <img src={BackIcon} className='order-header__back' onClick={back}/>
                <RenderStatus order={store} fetchInfo={fetchInfo} pay={pay}/>
            </div>
        )
    }
}

export default inject('order')(observer(OrderHeader))

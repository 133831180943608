import React from 'react';
import { Icon } from 'antd';

import './news-item.scss';
import { useHistory } from 'react-router-dom';
const NoticeIcon = require('./img/notice-icon.png');
const NewsItem = props => {
  //   const { pic, title, src, author, view } = props
  const {
    id,
    title,
    author,
    watchTimes,
    content,
    publishTime,
    thumbnail,
    categoryId,
    categoryName,
    netEase
  } = props;

  const history = useHistory();

  return (
    <div
      className='news-item'
      onClick={() => {
        if (netEase) {
          //   history.push(content);
          window.open(content);
        } else {
          history.push({
            pathname: `/news/info/${id}`
          });
        }
      }}
    >
      <div className='news-item__content'>
        <div className='news-item__left'>
          {/* <span className='news-item__title'>{title}</span> */}
          <div className='title-floor'>
            {categoryId === 1 && (
              <img src={NoticeIcon} alt='' className='notice-icon' />
            )}
            <div className='title'>{title}</div>
          </div>
          <div className='news-item__info'>
            <span className='news-item__author'>{author}</span>
            <span className='news-item__view'>
              <Icon type='eye' />
              {watchTimes}
            </span>
          </div>
        </div>
        <div className='news-item__right'>
          <img src={thumbnail} alt='' className='news-item__img' />
        </div>
      </div>

      <div className='news-item__divider' />
    </div>
  );
};

export default NewsItem;

import {useEffect, useRef} from "react";
import * as Api from "utils/api/api";
import ImMsgType from "enum/ImMsgType";
import {useBoolean} from "ahooks";

function useSocketRef({store, live, imUserId, socketRef}) {

    async function fetchGroupImUserList(groupId) {
        store.imUserList = await Api.imUserList({groupId});
    }

    useEffect(() => {

        if (!imUserId) {
            return;
        }
        if (store.socketFlag || socketRef.current) {
            return;
        }

        // socket
        socketRef.current = new WebSocket(Api.imSocket({imUserId}))
        socketRef.current.onopen = () => {
            console.log('socket 打开')
        };

        socketRef.current.onmessage = ({data}) => {

            console.log(`socket 接受信息：${JSON.stringify(data)}`)
            if (data) {
                const imMsg = JSON.parse(data);
                // 如果是群提示，说明有群成员变动
                if (imMsg.msgBody.type === ImMsgType.USER_ENTER) {
                    // 更新弹幕成员列表
                    fetchGroupImUserList(live.groupId).then()
                } else if (imMsg.msgBody.type === ImMsgType.AIVIDEO) {
                    const payload = imMsg.msgBody.payload
                    store.aivideoQueue.enqueue(payload)
                }
                // 更新弹幕消息
                store.putMessage(imMsg)
            }
        }

        socketRef.current.onclose = (e) => {
            console.log(`socket 关闭:${JSON.stringify(e)}`)
        }

        socketRef.current.onerror = (s, e) => {
            console.log(`socket 出错：${JSON.stringify(e)}`)
        }

        store.socketFlag = true

    }, [imUserId, store.socketFlag]);


    /*
     * 用户离开时
     * 1.退出群组
     * 2.退出im登录
     * */
    useEffect(() => {
        const beforeUnload = async () => {
            await Api.imQuitGroup({imGroupId: live.groupId, imUserId})
            if (socketRef.current) {
                socketRef.current?.close()
            }
        };
        window.addEventListener("beforeunload", beforeUnload);

        return () => {
            window.removeEventListener("beforeunload", beforeUnload);
        };
    }, []);


    const [joinGroupFlag, {set: setJoinGroupFlag}] = useBoolean(false)
    useEffect(() => {
        if (!(live?.groupId && imUserId && store.socketFlag)) {
            return;
        }
        if (joinGroupFlag) {
            return;
        }

        Api.imJoinGroup({
            imGroupId: live.groupId,
            imUserId
        }).then()
        setJoinGroupFlag(true)

    }, [live?.groupId, imUserId, store.socketFlag, joinGroupFlag])
}

export default useSocketRef;
import React from 'react';
import 'styles/help.scss';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function Help(props) {
  const { help: store } = props;
  const history = useHistory();

  useEffect(() => {
    store.init();
  }, []);

  useEffect(() => {
    store.fetchMenuList();
  }, []);

  const toInfo = id => {
    history.push({
      pathname: `/help/info/${id}`
    });
  };

  const renderChildList = list => {
    return list.map((item, i) => {
      const { id, title } = item;
      return (
        <div
          className='item'
          key={id}
          onClick={() => {
            toInfo(id);
          }}
        >
          {title}
        </div>
      );
    });
  };

  const renderList = () => {
    return store.menuList.map((item, i) => {
      const { id, title, isArticle, articleList, active } = item;

      return (
        <div
          key={id}
          className='item'
          onClick={() => {
            store.selectMenu(i);
          }}
        >
          {isArticle ? (
            <div
              className='child-item'
              onClick={() => {
                toInfo(id);
              }}
            >
              {title}
            </div>
          ) : (
            <div className='container'>
              <div className={`name ${active && 'active'}`}>{title}</div>
              {active ? (
                <img
                  src={require('images/help/arrow-open.png')}
                  alt=''
                  className='arrow open'
                />
              ) : (
                <img
                  src={require('images/help/arrow-close.png')}
                  alt=''
                  className='arrow close'
                />
              )}
            </div>
          )}
          {active && articleList?.length > 0 && (
            <div className='child-container'>
              {renderChildList(articleList)}
            </div>
          )}
        </div>
      );
    });
  };
  return (
    <div className='glx-help'>
      <img src={require('images/help/top-bg.png')} alt='' className='top-bg' />
      <img
        src={require('images/help/service.png')}
        alt=''
        className='service-img'
      />
      <div className='top'>
        <div className='floor1'>
          <div className='text'>HI~</div>
        </div>
        <div className='floor2'>
          <div className='text'>很高兴为您服务</div>
        </div>
        <div className='floor3'>
          <div className='input-wrapper'>
            <img
              src={require('images/help/search-icon.png')}
              alt=''
              className='search-icon'
            />
            <input type='text' className='input' />
          </div>
        </div>
      </div>

      <div className='content'>{renderList()}</div>
    </div>
  );
}

export default inject('help')(observer(Help));

import React from 'react'
import './user-list-panel.scss'

const UserListPanel = ({userList, defaultAvatar, defaultNickname}) => {
    return (
        <div className="glx-live-room__user-list-panel">
            <div className="title-container">
                <div className="title">贵宾席({userList.length})</div>
            </div>
            <div className="content-container">
                {
                    userList.map(item => (
                        <div className="item" key={item.userID}>
                            <img src={item.avatar || defaultAvatar} alt="" className="avatar"/>
                            <div className="nickname">{item.nick || defaultNickname}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default UserListPanel;
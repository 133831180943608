import React from 'react';
import './wait.scss';
import Head from 'components/merchant/head/Head';
import Step from 'components/merchant/step/Step';
import BottomButton from 'components/merchant/bottom-button/BottomButton';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const Wait = props => {
  const history = useHistory();
  const { merchant } = props;

  return (
    <div className='glx-merchant-wait'>
      <Head
        goBack={() => {
          history.goBack();
        }}
      />
      <Step />
      <div className='img-block'>
        <img src={require('./img/waiting.png')} alt='' className='img' />
      </div>
      <div className='status-floor'>
        <div className='title'>审核中</div>
        <div className='sub-title'>请耐心等待...</div>
        <div className='thanks'>感谢您在本店申请入驻</div>
      </div>
      {/* <div className='card'>
        <div className='floor'>
          <div className='label'>您的期望店铺名称</div>
          <div className='text'>{merchant.merchantStatus?.shopName}</div>
        </div>
        <div className='floor'>
          <div className='label'>店铺描述</div>
          <div className='text'></div>
        </div>
      </div> */}

      <BottomButton
        className='bottom-btn'
        onClick={() => {
          history.goBack();
        }}
      >
        返回首页
      </BottomButton>
    </div>
  );
};

export default inject('merchant')(observer(Wait));

import React, {useEffect, useRef} from "react";
import {observer} from 'mobx-react'
import css from './live.module.scss'
import LiveStore from "./store/LiveStore";
import classNames from "classnames";
import useSessionStorage from "beautiful-react-hooks/useSessionStorage";
// import * as Tim from "utils/tim";
import MessageList from "./messageList";
import AvatarFloor from "./avatarFloor";
import InputFloor from "./inputFloor";
import VideoPanel from "./videoPanel";
import useSocketRef from "./hooks/useSocketRef";
import useImUser from "./hooks/useImUser";
import useFetchLiveGoods from "./hooks/useFetchLiveGoods";
import GoodsListPanel from "./goodsListPanel";
import {useBoolean} from "ahooks";
import CurGoodsPanel from "./curGoodsPanel";
import ImUserListPanel from "./imUserListPanel";
import LiveGoodsType from "enum/LiveGoodsType";
import GoodsType from "enum/GoodsType";
import * as api from "utils/api/api";
import {defaultAvatar, liveFloatIcon} from "utils/api/api";
import {useHistory} from "react-router-dom";
import {makeAutoObservable, runInAction, when} from "mobx";
import useFetchLiveRecord from "./hooks/useFetchLiveRecord";
import {useDidMount} from "beautiful-react-hooks";
import * as antd from "antd";

const store = new LiveStore()

const LiveContainerStatus = {
    Show: 'show',
    Hide: 'hide',
    Transforming: 'transforming'
}

const LiveContainerClassName = {
    Normal: 'container-normal',
    ToHide: 'container-to-hide',
    Hide: 'container-hide',
    ToNormal: 'container-to-normal'
}

class CssStore {
    containerClassName = LiveContainerClassName.Normal
    // show hide transforming
    containerStatus = LiveContainerStatus.Show

    setContainerClassName(value) {
        this.containerClassName = value
    }

    setContainerStatus(value) {
        this.containerStatus = value
    }

    constructor() {
        makeAutoObservable(this)
    }
}

const cssStore = new CssStore();

when(() => store.liveRecord !== null, async () => {
    await runInAction(async () => {
        store.liveContext = await api.defaultLiveContext()
    })
})

const Live = ({containerClassName, liveInfo: {live, bgImgUrl = ''}, userStore}) => {
    const history = useHistory()

    const [imUserId, setImUserId] = useSessionStorage("imUserId", "");

    useImUser({imUserId, setImUserId, userStore, store})

    const socketRef = useRef()
    useSocketRef({store, live, imUserId, socketRef})
    // useFetchLiveGoods({live, store})
    useFetchLiveRecord({
        liveId: live?.id,
        setLiveRecord: (value) => store.setLiveRecord(value),
        setLiveGoodsList: (value) => store.setLiveGoodsList(value)
    })

    useEffect(() => {
        console.log(`live:${JSON.stringify(live)}`)
    }, [live]);

    // console.log(`memberList:${JSON.stringify(store.imUserList)}`)
    // console.log(`goodsList:${JSON.stringify(store.liveGoodsList)}`)

    const [liveGoodsListPanelFlag, {setFalse: closeGoodsListPanel, setTrue: openGoodsListPanel}] = useBoolean(false)

    const [curGoodsPanelFlag, {setFalse: closeCurGoodsPanel}] = useBoolean(true)

    const [imUserListPanelFlag, {setFalse: closeImUserListPanel, setTrue: openImUserPanel}] = useBoolean(false)

    // 去购买商品
    const toBuyLiveGoods = async ({liveGoodsType, skuId}) => {
        console.log(`去购买 skuId=${skuId}`)
        let goodsType;
        switch (liveGoodsType) {
            case LiveGoodsType.PDD:
                goodsType = GoodsType.PDD
                break;
            case LiveGoodsType.JD:
                goodsType = GoodsType.JD
                break;
            case LiveGoodsType.SELF:
            default:
                goodsType = GoodsType.SELF;
                break;
        }

        if (goodsType === GoodsType.SELF) {
            const orderProductList = [{productId: skuId, count: 1}];
            api.addOrder({orderProductList}).then((id) => {
                history.push({
                    pathname: `/order/pay/${id}`,
                });
            })
        } else {
            const {shotUrl} = await api.goodsPromotionUrl({goodsType, skuId})
            if (!shotUrl) {
                // antd.message.warn("商品已经下架");
                return;
            }
            window.open(shotUrl, '_blank')
        }

    }

    const onClickLiveIcon = () => {
        if (cssStore.containerStatus === LiveContainerStatus.Transforming) {
            return;
        }

        cssStore.setContainerClassName(LiveContainerClassName.ToNormal)
        cssStore.setContainerStatus(LiveContainerStatus.Transforming)
        setTimeout(() => {
            cssStore.setContainerClassName(LiveContainerClassName.Normal)
            cssStore.setContainerStatus(LiveContainerStatus.Normal)
        }, 1000)
    }

    const onClickHomeIcon = () => {
        if (cssStore.containerStatus === LiveContainerStatus.Transforming) {
            return;
        }

        cssStore.setContainerClassName(LiveContainerClassName.ToHide)
        cssStore.setContainerStatus(LiveContainerStatus.Transforming)
        setTimeout(() => {
            cssStore.setContainerClassName(LiveContainerClassName.Hide)
            cssStore.setContainerStatus(LiveContainerStatus.Hide)
        }, 1000)
    }

    // const clientHeightRef = useRef(0)
    // 处理软键盘弹出导致布局压缩的问题
    // useDidMount(() => {
    //     clientHeightRef.current = document.documentElement.clientHeight || document.body.clientHeight;
    //     debugger
    //
    //     const onResize = () => {
    //         debugger
    //         var nowClientHeight = document.documentElement.clientHeight || document.body.clientHeight;
    //         if (clientHeightRef.current > nowClientHeight) {
    //             //键盘弹出的事件处理
    //             console.log('键盘弹出')
    //
    //         } else {
    //             //键盘收起的事件处理
    //             console.log('键盘收起')
    //         }
    //     }
    //
    //     window.addEventListener('resize', onResize)
    // })



    return (
        <div className={classNames(css.live)}>
            {/*直播间icon*/}
            {
                cssStore.containerStatus === LiveContainerStatus.Hide &&
                <div className={css.liveIconContainer}>
                    <div className={css.liveIconWrapper} onClick={onClickLiveIcon}>
                        <img src={liveFloatIcon} alt=""/>
                    </div>
                </div>
            }
            <div className={classNames(css.container, containerClassName, cssStore.containerClassName)}>
                <div className={css.videoWrapper}>
                    <img src={require('./img/关注主播-福利多多.png')} className={css.welfare}/>

                    {/* 头像层 */}
                    <AvatarFloor store={store} defaultAvatar={defaultAvatar} onClickHomeIcon={onClickHomeIcon}
                                 openUserPanel={openImUserPanel}/>

                    {/*输入层*/}
                    <InputFloor store={store} defaultAvatar={defaultAvatar} socketRef={socketRef}
                                onOpenGoodsListPanel={openGoodsListPanel}/>

                    {/*消息列表*/}
                    <MessageList store={store}/>

                    {/*视频*/}
                    {/*<VideoPanel */}
                    {/*    store={store} */}
                    {/*    bgImgUrl={bgImgUrl}*/}
                    {/*/>*/}
                    {
                        store.liveRecord &&
                        <VideoPanel
                            liveRecord={store.liveRecord}
                            bgImgUrl={bgImgUrl}
                            liveContext={store.liveContext}
                            aivideoQueue={store.aivideoQueue}
                            setCurLiveGoodsIdxByLiveGoodsId={(id) => store.setCurLiveGoodsIdxByLiveGoodsId(id)}
                        />
                    }

                    {
                        liveGoodsListPanelFlag &&
                        <GoodsListPanel store={store} onClose={closeGoodsListPanel}
                                        toBuyLiveGoods={toBuyLiveGoods}/>
                    }

                    {
                        store.curLiveGoods && curGoodsPanelFlag &&
                        <CurGoodsPanel store={store} onClose={closeCurGoodsPanel} toBuyLiveGoods={toBuyLiveGoods}/>
                    }

                    {
                        imUserListPanelFlag &&
                        <ImUserListPanel userList={store.imUserList} onClose={closeImUserListPanel}
                                         defaultAvatar={defaultAvatar}/>
                    }

                </div>
            </div>
        </div>
    )
}
export default observer(Live);
import React from "react";
import css from 'styles/homeFloor.module.scss'
import {observer} from "mobx-react";
import classNames from "classnames";
import GoodsType, {goodsTypeEnumMap} from "enum/GoodsType";
import showPrice from "../../utils/show-price";
import MyRouterUtil from "../../utils/MyRouterUtil";
import {useHistory} from "react-router-dom";

const Index = ({className, data}) => {

    const history = useHistory()

    let {floor, adv1, goodsList = []} = data

    goodsList = goodsList.map(item => {
        const {pddGoodsSign, jdSkuId, selfGoodsId} = item;

        let id;
        switch (item.goodsType) {
            case GoodsType.PDD:
                id = pddGoodsSign;
                break;
            case GoodsType.JD:
                id = jdSkuId
                break;
            case GoodsType.SELF:
            default:
                id = selfGoodsId
                break;
        }
        return {
            ...item,
            id
        }
    })

    const toGoodsInfo = ({goodsType, id}) => {
        MyRouterUtil.build({history}).toGoodsInfo({goodsType, id})
    }

    const toAdv1 = () => {
        window.open(adv1.link, '_blank')
    }

    return (
        <div className={classNames(className, css.index)}>
            <div className={css.container}>
                <div className={css.titleFloor}>
                    <div className={css.title}>
                        {floor.name}
                    </div>
                </div>
                <div className={css.advWrapper} onClick={toAdv1}>
                    <img src={adv1.file} alt=""/>
                </div>

                <div className={css.goodsListContainer}>
                    <div className={css.goodsList}>
                        {
                            goodsList.map((item, i) => {
                                const {id, mainPic, name, price, oldPrice, goodsType} = item;
                                return (
                                    <div
                                        key={i}
                                        className={css.goods}
                                        onClick={() => toGoodsInfo({goodsType, id})}
                                    >
                                        <div className={css.top}>
                                            <div className={css.imgWrapper}>
                                                <img src={mainPic} alt=""/>
                                                <span
                                                    className={classNames({
                                                        [css.self]: goodsType === GoodsType.SELF,
                                                        [css.pdd]: goodsType === GoodsType.PDD,
                                                        [css.jd]: goodsType === GoodsType.JD
                                                    })}
                                                >
                                                    {goodsTypeEnumMap.get(goodsType)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={css.priceWrapper}>
                                            <div className={css.price}>
                                                ¥{price.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Index)
import {observable, computed, action, makeAutoObservable} from 'mobx'
import tabs from '../constants/newsConst'
import * as Api from 'utils/api/api'

interface NewsBanner {
  pic: String;
  src?: String;
}

interface NewsItem {
  pic: String;
  src: String;
  title: String;
  author: String;
  view: Number;
}

class NewsStore {
  @observable _bannerList = []
  @observable _list = []
  @observable PAGE_SIZE = 8
  @observable _pageSize = this.PAGE_SIZE
  @observable _pageNum = 1
  @observable _title = ''
  @observable _categoryId = undefined
  @observable _categoryList = []
  @observable _refreshing = false

  constructor() {
    makeAutoObservable(this)
  }
  get bannerList() {
    return this._bannerList
  }
  set bannerList(value) {
    this._bannerList = value
  }
  get list() {
    return this._list
  }
  set list(value) {
    this._list = value
  }
  get pageSize() {
    return this._pageSize
  }
  set pageSize(value) {
    this._pageSize = value
  }
  get pageNum() {
    return this._pageNum
  }
  set pageNum(value) {
    this._pageNum = value
  }
  get title() {
    return this._title
  }
  set title(value) {
    this._title = value
  }
  get categoryId() {
    return this._categoryId
  }
  set categoryId(value) {
    this._categoryId = value
  }
  get categoryList() {
    return this._categoryList
  }
  set categoryList(value) {
    this._categoryList = value
  }
  get refreshing() {
    return this._refreshing
  }
  set refreshing(value) {
    this._refreshing = value
  }

  /**
   * 初始化
   */
  @action init() {
    this._bannerList = []
    this._list = []
    this._pageSize = this.PAGE_SIZE
    this._pageNum = 1
    this._title = ''
    this._categoryId = undefined
  }

  /**
   * 获取资讯信息列表
   */
  @action async fetchList() {
    try {
      const { pageNum, pageSize, categoryId, title } = this
      const { list } = await Api.newsList({
        pageNum,
        pageSize,
        categoryId,
        title
      })

      this._list = list
      this._refreshing = false
    } catch (error) {}
  }

  /**
   * 获取类别列表
   */
  @action async fetchCategoryList() {
    try {
      let { list, totalNum } = await Api.newsCategoryList()

      this._categoryList = [
        {
          id: undefined,
          name: '全部资讯',
          isUrl: false
        },
        ...list
      ]
    } catch (error) {}
  }
}

const store = new NewsStore()

export default store

import React from "react";
import {observer} from "mobx-react";
import css from './curGoodsPanel.module.scss'
import LiveGoodsType from "enum/LiveGoodsType";
import * as antd from "antd";
import classNames from "classnames";
import {goodsDefaultImg} from "../../utils/api/commonApi";

const Index = ({store, onClose, toBuyLiveGoods}) => {
    let {id, name, pic, price, oldPrice = 0, type: liveGoodsType, pddGoods, jdGoods, selfGoods} = store.curLiveGoods

    const disable = !name;

    let skuId;
    switch (liveGoodsType) {
        case LiveGoodsType.PDD:
            skuId = pddGoods?.goodsSign;
            break;
        case LiveGoodsType.JD:
            skuId = jdGoods?.skuId
            break;
        case LiveGoodsType.SELF:
        default:
            skuId = selfGoods?.skuId
            break;
    }

    return (
        <div className={css.curGoodPanel}>

            <div className={css.imgWrapper}>

                {
                    disable ? <img className={css.img} src={goodsDefaultImg} alt=''/>
                        : <img className={css.img} src={pic} alt=''/>
                }

                <div className={css.speaking}>
                    <div className={css.text}>讲解中</div>
                </div>

                <div className={css.closeWrapper} onClick={onClose}>
                    <img src={require('./img/关闭单个商品icon.png')} alt='' className={css.closeIcon}/>
                </div>
            </div>

            <div className={css.bottom}>

                <div className={css.name}>
                    {disable ? '该商品已下架' : name}
                </div>

                <div className={css.lowestPriceText}>
                    30天最低价
                </div>

                <div
                    className={classNames(css.btnToPrice, {
                        [css.disable]: !name
                    })}
                    onClick={() => {
                        if (disable) {
                            // antd.message.warn("商品已经下架");
                            return;
                        }
                        toBuyLiveGoods({liveGoodsType, skuId})
                    }}
                >
                    <div className={css.price}>
                        {`￥${price.toFixed(2)}`}
                    </div>

                    {
                        disable ?
                            <img src={require('./img/按钮图标-已下架.png')} alt='' className={css.undercarriageIcon}/>
                            : <img src={require('./img/抢icon.png')} alt='' className={css.icon}/>
                    }
                </div>

            </div>

        </div>
    )
}

export default observer(Index)

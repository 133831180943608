import React, {useCallback, useEffect, useMemo, useState} from 'react'
import 'styles/orders/order-pay.scss'
import BackIcon from 'images/order-pay/back.png'
import BackgroundImg from 'images/order-pay/bg.png'
import AlipayIcon from 'images/order-pay/alipay.jpg'
import WechatIcon from 'images/order-pay/wechat.jpg'
import EnterIcon from 'images/order-pay/enter.png'

import {inject, observer} from 'mobx-react'
import Price from 'components/common/price/Price'
import {useHistory, useParams} from 'react-router-dom'
import * as api from 'utils/api/api';
import PayTypeEnum from "enum/PayTypeEnum";
import * as antd from 'antd';
import useQuery from "hooks/useQuery";
import isWeChat from "utils/isWeChat";
import myLog from 'utils/myLog';

import shopNameIcon from 'images/order-pay/shopName.png'
import StopPay from 'partials/order/pay/stop-pay/StopPay';

const OrderProductList = inject('orderPay', 'constant')(observer(({orderPay: store, constant}) => {
    const {orderProductList} = store;

    const lastProduct = useMemo(() => {
        if (!orderProductList || !orderProductList.length) {
            return null;
        }

        return orderProductList[orderProductList.length - 1]
    }, [orderProductList])

    useEffect(() => {
        constant.fetchJson();
    }, []);

    return <div className="order-product-list">
        {
            orderProductList.map(item => {
                const specification = item.specificationNameAndValueList?.map(item => item.value)?.join("/");
                return (
                    <div className="order-product" key={item.id}>
                        <div className='order-pay-goods'>
                            <div className='flex floor1'>
                                <img className='order-pay-goods__shop-icon' src={shopNameIcon}/>
                                <div
                                    className='order-pay-goods__shop-name'>{item.shopName ? item.shopName : constant.app.websiteName}</div>
                                {
                                    item.shopName &&
                                    <div className='self'>平台自营</div>
                                }
                            </div>
                            <div className='flex floor2'>
                                <img className='order-pay-goods__img' src={item.mainPic}/>
                                <div className='order-pay-goods__desc'>
                                    <div className='name'>{item.skuName}</div>
                                    {/*<div>{specification}</div>*/}
                                    {item?.specificationNameAndValueList.map(({id, name, value}) => (
                                        <span className='specification' key={id}>{name}:{value} </span>
                                    ))}

                                    <div className='price-floor'>
                                        <div className='price'>
                                            <Price price={item.price}/>
                                        </div>
                                        <div className='count'>
                                            数量:{item?.count}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className='order-pay-goods__deliver'>*/}
                            {/*    <span>配送</span>*/}
                            {/*    <span>{deliverType}</span>*/}
                            {/*</div>*/}
                            {/*<div className='order-pay-goods__deliver-time'>*/}
                            {/*    现在付款预计{predictDeliver}发货*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className='order-pay-price'>*/}
                        {/*<div>*/}
                        {/*    <span>运费</span>*/}
                        {/*    <Price*/}
                        {/*        className='order-pay-price__deliver'*/}
                        {/*        price={deliverFee}*/}
                        {/*        moneySymbol='+  ￥'*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </div>
                )
            })
        }
    </div>
}))

const OrderAddress = inject('orderPay', 'selectOrderAddress')(
    observer(({orderPay: store, selectOrderAddress, fetchOrder}) => {
        const history = useHistory();

        const fullAddress = useMemo(() => {
            const address = store.orderAddress;

            if (!address) {
                return null;
            }

            return address.province + address.city + address.district + address.address;
        }, [store.orderAddress])

        const onSelect = () => {
            history.push('/orders/select-address');
        }

        const modifyAddress = useCallback(() => {
            const address = selectOrderAddress.currentAddress;
            if (!address) {
                return;
            }

            const orderAddress = {
                name: address.name,
                phone: address.telNumber,
                province: address.province,
                city: address.city,
                district: address.district,
                address: address.detailInfo
            }
            api.modifyOrder({orderId: store.id, orderAddress}).then(() => {
                fetchOrder();
            })
        }, [selectOrderAddress.currentAddress, store.id])
        useEffect(() => {
            modifyAddress();
        }, [modifyAddress])

        return (

            <div className='order-pay-address' onClick={onSelect}>
                {
                    store.orderAddress ?
                        <React.Fragment>
                            <div>
                                {store.orderAddress?.isDefault && (
                                    <div className='order-pay-address__default'>默认</div>
                                )}
                                <div></div>
                            </div>
                            <div>
                                <div>{fullAddress}</div>
                                <img src={EnterIcon} className='order-pay-address__enter'/>
                            </div>
                            <div>
                                <span>{store.orderAddress?.name}</span>
                                <span>{store.orderAddress?.phone}</span>
                            </div>
                        </React.Fragment> :
                        <div className="select-text-wrapper">
                            <span>点击选择收货地址</span>


                            <img className='arrow' src={EnterIcon}/>
                        </div>
                }
            </div>

        )
    }));

const SelectPayType = inject('orderPay')(
    observer(({orderPay: store}) => {
        const fetchPayType = useCallback(() => {
            api.isOpenPayTypeList().then(list => {
                store.payTypeList = list;
                store.currentPayTypeId = list?.[0].id;
            })
        }, [])

        useEffect(() => {
            fetchPayType();
        }, [fetchPayType])

        const getIcon = payTypeId => {
            switch (payTypeId) {
                case PayTypeEnum.A_LI_PAY.value:
                    return AlipayIcon;
                case PayTypeEnum.WE_CHAT.value:
                    return WechatIcon;
                case PayTypeEnum.UNION_PAY.value:
                default:
                    return ''
            }
        }

        const RadioBtn = ({active}) => {
            return <div className={`radio-btn ${active && 'active'}`}/>
        }

        const onSelect = payTypeId => {
            store.currentPayTypeId = payTypeId;
        }

        return (
            <div className='order-pay-select'>
                {
                    store.payTypeList.map(({id, name}) => {
                        return (
                            <div className='option'
                                 key={id}
                                 onClick={() => {
                                     onSelect(id);
                                 }}
                            >
                                <img className='pay-icon' src={getIcon(id)} alt=''/>
                                <span className='pay-name'>{name}</span>
                                <RadioBtn active={id === store.currentPayTypeId}/>
                            </div>
                        )
                    })
                }
            </div>
        )
    })
)

const OrderPay = ({orderPay: store}) => {
    const history = useHistory();
    const params = useParams();
    const id = Number(params.id);
    const query = useQuery();
    const [payResultBody, setPayResultBody] = useState(null);

    /*
    * 初始化
    * */
    useEffect(() => {
        store.showBackModel = false;
    }, []);

    const fetchOrder = useCallback(() => {
        if (!id) {
            return;
        }
        if (query.state === "queryPay") {
            return;
        }

        api.orderInfo({id}).then(order => {

            store.id = id;
            store.sn = order.sn;
            store.price = order.price;
            store.status = order.status;
            store.createTime = order.createTime;
            store.delivery = order.delivery;
            store.orderAddress = order.orderAddress;
            store.orderProductList = order.orderProductList;
            store.payType = order.payType;
        })
    }, [id, query.state]);
    /*
    * 获取订单信息
    * */
    useEffect(() => {
        fetchOrder();
    }, [fetchOrder])

    /*
    * 查询是否支付成功
    * */
    useEffect(() => {
        if (query.state !== 'queryPay') {
            return;
        }

        if (!id) {
            return;
        }

        api.queryPay({orderId: id}).then(data => {
            if (!data.success) {
                history.push("/pay/fail");
            } else {
                history.push("/pay/success");
            }
        })
    }, [query.state, id, history]);

    /*
    * 查询openId
    * */
    useEffect(() => {
        if (!query.state && query.state !== 0) {
            return;
        }

        if (!store.currentPayTypeId || !store.price || !store.sn) {
            return;
        }

        if (query.state === "queryOpenId") {
            api.getOpenId({code: query.code}).then(data => {
                const {openId} = data;
                myLog("openId:" + openId)

                api.pay({
                    price: store.price,
                    orderSn: store.sn,
                    payTypeId: store.currentPayTypeId,
                    openId
                }).then((data) => {
                    myLog("发起支付成功")

                    const {
                        appId,
                        timeStamp,
                        nonceStr,
                        package: packAge,
                        signType,
                        paySign
                    } = data;

                    myLog("paySign:" + appId);
                    myLog("timeStamp" + timeStamp);
                    myLog("nonceStr" + nonceStr);
                    myLog("packAge" + packAge);
                    myLog("signType" + signType);
                    myLog("paySign" + paySign);

                    api.jsApi({
                        appId,
                        timeStamp,
                        nonceStr,
                        packAge,
                        signType,
                        paySign
                    });

                }).catch(error => {
                    myLog("发起支付失败")
                });
            }).error(error => {
                myLog("获取openId失败")
                myLog(error || error.response.data || error.message);
            })
        }
    }, [query.code, query.state, store.currentPayTypeId, store.price, store.sn]);


    const toPaySuccess = () => {
        history.push("/pay/success");
    }

    const pay = () => {
        if (!store.orderAddress) {
            antd.message.warn("请选择收货地址");
            return;
        }

        if (!store.currentPayTypeId) {
            antd.message.warn("请选择支付方式");
            return;
        }

        if (isWeChat()) {
            myLog("请求appId之前")
            api.appId({payTypeId: PayTypeEnum.WE_CHAT.value})
                .then(data => {
                    myLog("请求appId成功")
                    myLog("appId:" + data.appId);

                    const appId = data.appId;
                    // const redirectUrl = encodeURIComponent("http://www.zgdqhyw.cn/mobile/orders/pay/58")
                    const redirectUrl = encodeURIComponent(`${window.location.href}`)
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize
                        ?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code
                        &scope=snsapi_base&state=queryOpenId#wechat_redirect`;
                })
                .catch(error => {
                    myLog("请求appId失败")
                    myLog(error.response.data || error.message);
                })
        } else {
            api.pay({
                price: store.price,
                orderSn: store.sn,
                payTypeId: store.currentPayTypeId
            }).then(data => {
                if (store.currentPayTypeId === PayTypeEnum.A_LI_PAY.value) {
                    const {body} = data;
                    setPayResultBody(body);
                    const timer = setInterval(() => {
                        // let dom = document.getElementById("bestPayForm");
                        let dom = document.getElementsByName("punchout_form")?.[0]
                        if (!dom) {
                            return;
                        }

                        dom.submit();
                        clearInterval(timer);
                    }, 200)
                } else if (store.currentPayTypeId === PayTypeEnum.WE_CHAT.value) {
                    if (data.mwebUrl) {
                        const redirectUrl = encodeURIComponent(`${window.location.href}?state=queryPay`);
                        window.location.href = `${data.mwebUrl}&redirect_url=${redirectUrl}`;
                    }
                }
            });
        }
    }

    const goBack = () => {
        // history.goBack();
        store.showBackModel = true;
    }

    const hideStopPay = () => {
        store.showBackModel = false;
    }

    const goToOrder = () => {
        history.replace(`/orders/${store.id}`)
    }

    return (
        <div className='order-pay'>
            <img className='order-pay__bg' src={BackgroundImg}/>
            <div className='order-pay__bg--color'/>
            <div className='order-pay-header'>
                <img
                    onClick={goBack}
                    src={BackIcon}
                    className='order-pay-header__back'
                />
                <span>确认订单</span>
            </div>
            <OrderAddress fetchOrder={fetchOrder}/>
            <div className='order-pay-type'>
                <span className='item'>支付方式</span>
                <span className='item'>在线支付</span>
            </div>
            {
                store.orderProductList && <OrderProductList/>
            }
            <div className='price-block'>
                <span className="label">订单金额</span>
                <div className='price'>
                    <Price price={store.price}/>
                </div>
            </div>
            <SelectPayType/>
            <div className='order-pay-ope'>
                <div className='order-pay-ope__price'>
                    <Price price={store.price}/>
                </div>

                <div className='order-pay-ope__button' onClick={pay}>
                    继续付款
                </div>
            </div>
            {store.showBackModel && (
                <StopPay continuePay={hideStopPay} goToOrder={goToOrder}/>
            )}
            {payResultBody && <div dangerouslySetInnerHTML={{__html: payResultBody}}/>}
        </div>
    )
}

export default inject('orderPay')(observer(OrderPay))

import React from 'react'
import {observer} from "mobx-react";
import 'styles/open-live-process.scss'
import {useHistory} from 'react-router-dom'

const stepArr = [
    {
        step: 1,
        title: '申请',
        icon: require('images/open-live-process/step1-icon.png'),
        processIcon: require('images/open-live-process/process1-icon.png'),
        imgArr: [],
        textArr: [
            `联系平台方,获取直播间邀请码（邀请码用于开通直播间服务）`
        ]
    }, {
        step: 2,
        title: '操作',
        icon: require('images/open-live-process/step2-icon.png'),
        processIcon: require('images/open-live-process/process2-icon.png'),
        imgArr: [
            require('images/open-live-process/step2-img1.png')
        ],
        textArr: [
            `扫描“5G智能名片”小程序二维码，前往 “5G智能名片”- 个人中心 - 直播，输入邀请码开通直播服务。`
        ]
    }, {
        step: 3,
        title: '开启直播',
        icon: require('images/open-live-process/step3-icon.png'),
        processIcon: require('images/open-live-process/process3-icon.png'),
        imgArr: [],
        textArr: [
            `准备就绪，扫码前往开启您的直播之旅吧`
        ]
    }
]

const OpenLiveProcess = () => {
    const history = useHistory();

    return (
        <div className="glx-open-live-process-page">
            <img src={require('images/open-live-process/bg.png')} alt="" className="bg-img"/>
            <div className="title-container">
                <div className="icon-close-wrapper" onClick={() => {
                    history.goBack()
                }}>
                    <img src={require('images/open-live-process/close-icon.png')} alt="" className="icon-close"/>
                </div>
                <div className="text-title">云直播 - 开通流程</div>
            </div>
            <div className="process-container">
                <div className="process">
                    <div className="title-container">
                        <img src={stepArr[0].icon} alt="" className="icon-step"/>
                        <div className="title">{stepArr[0].title}</div>
                    </div>
                    <div className="content-container">
                        <img src={stepArr[0].processIcon} alt="" className="icon-process"/>
                        <div className="content">
                            <div className="text">
                                {stepArr[0].textArr[0]}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="process">
                    <div className="title-container">
                        <img src={stepArr[1].icon} alt="" className="icon-step"/>
                        <div className="title">{stepArr[1].title}</div>
                    </div>
                    <div className="content-container">
                        <img src={stepArr[1].processIcon} alt="" className="icon-process"/>
                        <div className="content">
                            <div className="text">
                                {stepArr[1].textArr[0]}
                            </div>
                            <img src={stepArr[1].imgArr[0]} alt="" className="img"/>
                        </div>
                    </div>
                </div>
                <div className="process">
                    <div className="title-container">
                        <img src={stepArr[2].icon} alt="" className="icon-step"/>
                        <div className="title">{stepArr[2].title}</div>
                    </div>
                    <div className="content-container">
                        <img src={stepArr[2].processIcon} alt="" className="icon-process"/>
                        <div className="content">
                            <div className="text">
                                {stepArr[2].textArr[0]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(OpenLiveProcess);
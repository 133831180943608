import React from "react";
import "./css/loading.scss";

function Loading(props) {
  const { className } = props;
  return (
    <div className={`${className} glx-loading`}>
      <img
        className="img-loading"
        src={process.env.PUBLIC_URL + "/svg-loaders/bars.svg"}
        alt=""
      />
    </div>
  );
}

export default Loading;

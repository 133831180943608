//  1-个人，2-企业，3-旗舰店，4-专卖店，5-专营店，6-普通店
const type = {
  All: { value: 0, label: '全部' },
  Personal: { value: 1, label: '个人' },
  Company: { value: 2, label: '企业' },
  Flagship: { value: 3, label: '旗舰店' },
  Exclusive: { value: 4, label: '专卖店' },
  Franchise: { value: 5, label: '专营店' },
  Normal: { value: 6, label: '普通店' },
  getByValue(value) {
    for (let key in type) {
      if ((type[key].value = value)) {
        return type[key];
      }
    }
  }
};

Object.defineProperty(type, 'getByValue', {
  enumerable: false
});

export default type;

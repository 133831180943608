import React from 'react'

import BackIcon from 'images/orders/back-black.png'

import { observer, inject } from 'mobx-react'
import Back from 'components/common/back/Back'

const Address = (props) => {
  return (
    <div className='address'>
      <Back className='address-top'>
        <img src={BackIcon} className='address-top__back-icon' />
        <p>确认收货</p>
      </Back>
    </div>
  )
}

export default inject('address')(observer(Address))

import React from 'react';

// import RecentBg from '../../../images/my//recent.png'

import './my-recent-card.scss';
import showPrice from 'utils/show-price';
const MyRecentCard = (props, idx, toDetail) => {
  //   const { name, price, time } = props
  let { id, goodsId, time, name, mainPic, currentPrice, pddShop } = props;

  return (
    <div
      className='my-recent-card'
      key={idx || id}
      onClick={() => {
        toDetail(goodsId, pddShop);
      }}
    >
      <div className='my-recent-card__left'>
        <div className='my-recent-card__left-top'>
          <span className='my-recent-card__name'>{name}</span>
          <span className='my-recent-card__price'>
            {/* {price ? <span className='money-symboy'>￥</span> : ''}
            {price} */}
            {showPrice(currentPrice)}
          </span>
        </div>

        <div className='my-recent-card__left-bottom'>
          <span className='my-recent-card__time'>{time}</span>
        </div>
      </div>
      <div className='my-recent-card__right'>
        <img className='my-recent-card__img' alt='' />
      </div>
    </div>
  );
};

export default MyRecentCard;

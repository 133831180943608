import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import './styles/global.less'
import 'antd-mobile/dist/antd-mobile.css'
import 'mescroll.js/mescroll.min.css'

// swiper css
import 'swiper/css/swiper.min.css'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import stores from './stores'
import moment from './i18n/moment'
import './styles/common/index.scss'

// import './utils/rem.js'
import 'src/utils/flexible'

ReactDOM.render(
  <Provider {...stores}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from 'react';
import 'styles/modify-gender.scss';
import { inject, observer } from 'mobx-react';
import * as antd from 'antd';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import useQuery from 'hooks/useQuery';

const ModifyGender = props => {
  const { account: store } = props;
  let history = useHistory();
  const query = useQuery();
  /**
   * 初始化
   */
  useEffect(() => {
    store.gender = Number(query.gender);
  }, []);

  async function doModify() {
    if (await store.doModifyGender()) {
      antd.message.success('修改成功');
      history.goBack();
    }
  }

  return (
    <div className='modify-gender'>
      <div className='header'>
        <div
          className='back'
          onClick={() => {
            history.go(-1);
          }}
        >
          <img
            src={require('images/modify-nickname/back.png')}
            alt=''
            className='arrow'
          />
        </div>
        <div className='title'>修改性别</div>
      </div>

      <div className='form'>
        <div className='floor'>
          <input
            className='gender'
            type='radio'
            name='gender'
            checked={store.gender === 0}
            value={0}
            onClick={() => {
              store.gender = 0;
            }}
          />
          <span className='text'>男</span>
          <input
            className='gender'
            type='radio'
            name='gender'
            checked={store.gender === 1}
            value={1}
            onClick={() => {
              store.gender = 1;
            }}
          />
          <span className='text'>女</span>
        </div>
        <div className='floor btn-floor'>
          <button className='submit-btn' onClick={doModify}>
            确认修改
          </button>
        </div>
      </div>
    </div>
  );
};

export default inject('account')(observer(ModifyGender));

import React from 'react';
import './shop2.scss';
import Head from 'components/merchant/head/Head';
import Step from 'components/merchant/step/Step';
import BottomButton from 'components/merchant/bottom-button/BottomButton';
import { InputItem, Radio, List, Picker, DatePicker } from 'antd-mobile';
import { createForm } from 'rc-form';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import AddCategory from './partials/add-category/AddCategory';
import AddLicense from './partials/add-license/AddLicense';

const Shop2 = props => {
  const { merchant, form } = props;
  const { shop2 } = merchant;
  const { getFieldProps } = form;

  useEffect(() => {
    shop2.fetchData();
  }, []);

  return (
    <div className='glx-merchant-shop-2'>
      <Head />
      <Step />

      <div className='content'>
        <div className='title-floor'>
          <div className='title'>店铺类型及类目信息</div>
          <div className='sub-title'>选择店铺类型</div>
        </div>
        <div className='container'>
          {/* 期望店铺类型 */}
          <Picker
            title='期望店铺类型'
            cols={1}
            data={shop2.shopTypeList.map(item=>({...item,value:item.id,label:item.value}))}
            value={[shop2.shopType?.id]}
            onOk={value => {
              const [id] = value;
              shop2.selectShopType(id);
            }}
          >
            <List.Item>期望店铺类型</List.Item>
          </Picker>
        </div>
        <div className='title-floor'>
          <div className='sub-title'>选择店铺类型</div>
        </div>
        <div className='container'>
          {/* 主营类目 */}
          <Picker
            title='主营类目'
            cols={1}
            data={shop2.categoryList.map(item => ({
              ...item,
              value: item.id,
              label: item.value
            }))}
            value={[shop2.mainCategory]}
            onOk={value => {
              const [mainCategory] = value;

              shop2.mainCategory = mainCategory;
            }}
          >
            <List.Item>主营类目</List.Item>
          </Picker>
          {/* 详细类目 */}
          <AddCategory />
          {/* 是否有资质 */}
          <div className='row license'>
            <span className='label license'>是否具有对应类目行业资质</span>
            <Radio
              name='license'
              className='license'
              checked={shop2.hasLicense}
              onChange={e => {
                shop2.hasLicense = true;
              }}
            >
              有
            </Radio>
            <Radio
              name='license'
              className='license'
              checked={!shop2.hasLicense}
              onChange={e => {
                shop2.hasLicense = false;
              }}
            >
              无
            </Radio>
          </div>
          {/* 行业资质 */}
          <div className='row license'>
            <span className='label'>对应类目行业资质</span>
          </div>
          <div className='row'>
            <span className='tip'>
              请准确填写行业资质（至少一项必填），资质不全将无法通过审核
            </span>
          </div>
          <AddLicense />
        </div>
      </div>
      <BottomButton />
    </div>
  );
};

export default createForm()(inject('merchant')(observer(Shop2)));

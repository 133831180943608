import {observable, ObservableMap, action, computed, makeAutoObservable} from 'mobx'
import AppStore from './AppStore'
import * as Api from 'utils/api/api'

class Deal {
    @observable _list = []
    @observable PAGE_SIZE = 8
    @observable _pageSize = 8
    @observable _pageNum = 1

    constructor() {
        makeAutoObservable(this)
    }
    get list() {
        return this._list
    }

    set list(value) {
        this._list = value
    }

    get pageSize() {
        return this._pageSize
    }

    set pageSize(value) {
        this._pageSize = value
    }

    get pageNum() {
        return this._pageNum
    }

    set pageNum(value) {
        this._pageNum = value
    }

    /**
     * 获取信息
     */
    @action
    async fetchList() {
        if (AppStore.hasLogin) {
            const {pageSize, pageNum} = this
            return Api.trackMap({
                pageSize,
                pageNum
            }).then((data) => {
                const {map, totalNum} = data;

                let list = []

                for (let key in map) {
                    for (let item of map[key]) {
                        if (list.length < 8) {
                            list.push(item)
                        }
                    }
                }

                this._list = list

                return data;
            })
        }
    }
}

const store = new Deal()

export default store

import React from "react";
import { observer, inject } from "mobx-react";
import Nav from "components/BottomNav";
import "styles/category.scss";
import SearchInput from "components/search/search-input/SearchInput";
import CategoryType from "partials/category/category-type/CategoryType";
import CategorySection from "partials/category/category-section/CategorySection";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";

function Category(props) {
  const { category: store, search } = props;
  const [keyword, setKeyword] = useState("");
  let history = useHistory();

  useEffect(() => {
    store.init();
  }, []);

  useEffect(() => {
    store.fetchCategoryList();
  }, []);

  return (
    <div className="category">
      <div className="category-header">
        <SearchInput
          keyword={keyword}
          onChange={e => {
            // search.keyword = e.currentTarget.value;
            setKeyword(e.currentTarget.value);
          }}
          onClear={() => {
            // search.keyword = "";
            setKeyword("");
          }}
          onSubmit={e => {
            e.preventDefault();
            search.keyword = keyword;
            history.push({ pathname: "/search/result" });
          }}
        />
        <span className="category-header__all">全部</span>
      </div>
      <div className="category-body">
        <div className="category-sidebar">
          {store.categoryList.map((category, idx) => (
            <CategoryType
              key={idx}
              {...category}
              active={store.currentIndex === idx}
              onClick={() => {
                store.currentIndex = idx;
              }}
            />
          ))}
        </div>
        <div className="category-content">
          {/* <img
            className='category-content__banner'
            alt=''
            src={store.currentCategory?.pic}
          /> */}
          <div className="category-content__sections">
            {store.childCategoryList.map((item, idx) => {
              return <CategorySection {...item} key={idx} />;
            })}
          </div>
        </div>
      </div>
      <Nav />
    </div>
  );
}

export default inject("category", "search")(observer(Category));

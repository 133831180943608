import React, {useRef} from "react";
import {inject, observer} from "mobx-react";
import {NavLink, useParams, useHistory} from "react-router-dom";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import GoodsType from "enum/GoodsType";
import CartIcon from "images/detail/cart.png";
import BackIcon from "images/detail/back.png";
import AddIcon from "images/detail/+.png";
import SubIcon from "images/detail/-.png";
import "styles/product-detail.scss";
import Back from "components/common/back/Back";
import * as Api from "utils/api/api";
import * as antd from "antd";
import useQuery from "hooks/useQuery";
import {useEffect} from "react";
import {useCallback} from "react";
import {useState} from "react";
import showPrice from "utils/show-price";
import * as api from 'utils/api/api';
import {getDebugName} from "mobx";

function ProductDetail(props) {
    const {detail: store, app} = props;
    const [mode, setMode] = useState("normal"); //up,down
    const cardRef = useRef(null);

    const {
        currentSku,
        goods,
        imgList,
        specificationMapList,
        goodsAttributeList,
        description,
        goodsPromotionUrl,
        type
    } = store;

    let stock = goods?.totalStocks;
    let saleNum = goods?.soldNum;
    let skuName = currentSku?.skuName || goods?.name;
    let currentPrice = currentSku?.currentPrice || goods?.currentPrice;
    let originPrice = currentSku?.originPrice || goods?.originPrice;

    const params = useParams();
    const query = useQuery();
    let history = useHistory();
    let startY = 0;
    let startCardY = 0;

    /**
     * 初始化
     */
    useEffect(() => {
        store.init();
    }, []);

    /**
     * 获取参数
     */
    useEffect(() => {
        store.goodsId = params.id;
        store.type = Number(query.type);
    }, []);

    /**
     * 获取数据
     */
    let fetchInfo = useCallback(() => {
        store.fetchInfo();
    }, [params.id, query.type]);
    useEffect(() => {
        fetchInfo();
    }, [fetchInfo]);

    /**
     * 创建轮播图
     */
    useEffect(() => {
        new Swiper(".detail-image-swiper", {
            // loop: true
        });

        const viewerSwiper = new Swiper(".detail-image-wrapper", {
            // loop: true,
            width: app.clientHeight,
            pagination: {
                el: ".detail-image-wrapper .swiper-pagination",
                clickable: true
            },
            observer: true,
            onSlideChangeEnd: function (swiper) {
                swiper.update();
                viewerSwiper.startAutoplay();
                viewerSwiper.reLoop();
            }
        });
    }, [store.imgList]);

    /**
     * 获取购物车商品数量
     */
    useEffect(() => {
        if (app.hasLogin) {
            store.fetchCartCount();
        }
    }, []);

    const handleScroll = e => {
        console.log(e);
    };

    const handleAdd = e => {
        store.addNum();
    };

    const handleSub = e => {
        if (store.num <= 0) return;
        store.subNum();
    };

    function handleTouchStart(e) {
        // e.preventDefault();
        startY = e.changedTouches[0].pageY;
        startCardY = cardRef.current.getClientRects()[0].y;
    }

    function handleTouchEnd(e) {
        if (mode === "down") return;
        // e.preventDefault();
        const endCardY = cardRef.current.getClientRects()[0].y;
        const deltaCardY = endCardY - startCardY;
        const endY = e.changedTouches[0].pageY;
        const deltaY = endY - startY;

        if (mode === "up") {
            // 下滑切换回normal
            if (endCardY > app.clientHeight * 0.1 && deltaY > 0) {
                setMode("normal");
            }
            return;
        }

        if (mode === "normal") {
            if (Math.abs(deltaY) < 10) {
                // 滑动不明显
                return;
            }

            if (deltaY > 0 && Math.abs(deltaCardY - deltaY) >= 10) {
                //下滑
                setMode("down");
            } else if (deltaY < 0) {
                //上滑
                setMode("up");
                cardRef.current.scrollIntoView(true);
            }
        }
    }

    const disableScroll = () => {
        document.body.style.overflow = "hidden";
        // document.addEventListener('touchmove', doms.prevent, false)
    };

    const enableScroll = () => {
        document.body.style.overflow = "unset";
        // document.removeEventListener('touchmove', doms.prevent, false)
    };

    const renderMode = () => {
        switch (mode) {
            case "up": {
                // return (
                // )
                break;
            }
            case "down": {
                // return (
                // )
                break;
            }
            default:
                return null;
            //   case 'normal': {
            //     return (
            //       <div className='detail-shop'>
            //         <div className='detail-shop__info'>
            //           <img className='detail-shop__avatar' alt='' />
            //           <span className='detail-shop__title'>旗舰店</span>
            //         </div>

            //         <span className='detail-shop__follow'>关注</span>
            //       </div>
            //     );
            //   }
        }
    };

    const doAddToCart = async () => {
        if (store.cartCount > stock) {
            antd.message.warn("库存不够");
            return;
        }

        try {
            await Api.cartAddGoods({
                goodsId: goods.id,
                skuId: currentSku.id,
                number: 1
            });

            antd.message.success("添加成功");
            store.fetchInfo();
        } catch (error) {
            antd.message.error(error);
        }
    };

    const buy = () => {
        if (!app.hasLogin) {
            history.replace({
                pathname: "/my/account/login"
            });
            return;
        }

        if (store.cartCount > stock) {
            antd.message.warn("库存不够");
            return;
        }

        const orderProductList = [{
            productId: currentSku?.id,
            count: 1
        }]

        api.addOrder({orderProductList}).then((id) => {
            api.deleteCartByOrderId({orderId: id}).then(() => {
                antd.message.success("提交成功");
                history.push({
                    pathname: `/orders/pay/${id}`,
                });
            })
        })
    }

    const buyPromotion = ({type}) => {
        if (!app.hasLogin) {
            history.replace({
                pathname: "/my/account/login"
            });

            return;
        }

        if (type === GoodsType.PDD) {
            api.recordPddGoods(goods.id).then(url => {
                if (url) {
                    window.location.replace(url);
                } else {
                    window.location.replace(goodsPromotionUrl);
                }
            })
        }else if(type === GoodsType.JD) {
            // 打开京东详情页
            window.location.replace(goodsPromotionUrl);
        }
    }

    function renderOpe() {
        return (
            <div className="detail-ope">
                {store.type === 0 && (
                    <React.Fragment>
            <span
                className="detail-ope__cart"
                onClick={() => {
                    doAddToCart();
                }}
            >
              加入购物车
            </span>
                        <span
                            className="detail-ope__buy"
                            onClick={buy}
                        >
              立即购买
            </span>
                    </React.Fragment>
                )}
                {store.type !== 0 && (
                    <span
                        className="detail-ope__buy"
                        onClick={() => {
                            buyPromotion({
                                type
                            })
                        }}
                    >
            前往购买
          </span>
                )}
            </div>
        );
    }

    function renderSpecificationFloor() {
        let domList = [];

        for (let key in specificationMapList) {
            let {list: specificationList, currentIndex} = specificationMapList[key];
            let dom = (
                <div className="specification-floor" key={key}>
                    <div className="label">{key}</div>

                    {specificationList?.map((specification, index) => {
                        const {id, value} = specification;

                        return (
                            <div
                                key={id}
                                className={`specification-value-item ${
                                    currentIndex === index ? "active" : ""
                                }`}
                                onClick={() => {
                                    store.selectSpecification({
                                        key,
                                        index,
                                    });
                                }}
                                onTouchStart={e => {
                                    e.stopPropagation()
                                }}
                                onTouchEnd={e => {
                                    e.stopPropagation()
                                }}
                            >
                                {value}
                            </div>
                        );
                    })}
                </div>
            );

            domList.push(dom);
        }

        return domList;
    }

    function renderAttributeList() {
        return goodsAttributeList?.map(({propName, value}, i) => {
            return (
                <div className="specification-floor" key={i + propName}>
                    <div className="label">{propName}</div>
                    <div className="specification-value-item specification-value-item__pdd">
                        {value}
                    </div>
                </div>
            );
        });
    }

    function renderNameSpecification() {
        return (
            <div className="specification-floor">
                <div className="label">名称</div>

                <div className="specification-value-item active">
                    {store.goods?.name || store.currentSku?.name}
                </div>
            </div>
        );
    }

    return (
        <div className="detail">
            <div className="detail-nav">
                <Back hideBackIcon>
                    <img src={BackIcon} className="detail-nav__back" alt=""/>
                </Back>

                <NavLink to="/cart">
                    <img src={CartIcon} className="detail-nav__cart" alt=""/>
                </NavLink>

                {store.cartCount > 0 && (
                    <span className="detail-nav__cart-count">{store.cartCount}</span>
                )}
            </div>
            <div className="detail-image-swiper">
                <div className="swiper-wrapper">
                    {imgList.map((img, idx) => {
                        return (
                            <div className="swiper-slide" key={idx}>
                                <img src={img} className="img" alt=""/>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div
                className="detail-card"
                ref={cardRef}
                draggable={true}
                onScroll={handleScroll}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <div
                    className="detail-card__wrapper"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    {/* 收藏 */}
                    {store.type === GoodsType.SELF && (
                        <div
                            className="collect"
                            onClick={() => {
                                store.switchCollect();
                            }}
                        >
                            {store.hasCollect ? (
                                <img
                                    src={require("images/detail/like.png")}
                                    alt=""
                                    className="img"
                                />
                            ) : (
                                <img
                                    src={require("images/detail/unlike.png")}
                                    alt=""
                                    className="img"
                                />
                            )}
                        </div>
                    )}
                    <div className="detail-card__control">
                        <div className="detail-card__control-icon"></div>
                    </div>
                    <div className="detail-card__title">
                        {goods?.name || currentSku?.name}
                    </div>
                    <div className="detail-card__tags"></div>
                    <div className="detail-card__prices">
            <span className="detail-card__price">
              {/*<span className="money-symbol">￥</span>*/}
                {showPrice(currentPrice)}
            </span>
                        <span className="detail-card__origin-price">
              {/*<span className="money-symbol">￥</span>*/}
                            {showPrice(originPrice)}
            </span>
                    </div>
                    <div
                        className="specification-block"
                    >
                        {Object.keys(specificationMapList).length
                            ? renderSpecificationFloor()
                            : renderNameSpecification()}
                        {renderAttributeList()}
                    </div>
                    {/* <div className='detail-choose'>
              <p className='detail-choose__title'>颜色</p>
            </div>
            <div className='detail-choose'>
              <p className='detail-choose__title'>尺码</p>
            </div> */}
                    <div className="detail-choose detail-choose-stock">
                        <p className="detail-choose__title">数量</p>
                        <div
                            className="detail-choose__content"
                            onTouchStart={e => {
                                e.stopPropagation()
                            }}
                            onTouchEnd={e => {
                                e.stopPropagation()
                            }}
                        >
                            {/* <img src={SubIcon} className='detail-choose-stock__ope' alt='' /> */}
                            <div
                                // src={SubIcon}
                                className="ope minus"
                                alt=""
                                onClick={handleSub}
                            >
                                -
                            </div>
                            <div className="res">{store.num}</div>
                            {/* <img src={AddIcon} className='detail-choose-stock__ope' alt='' /> */}
                            <div
                                // src={AddIcon}
                                className="ope add"
                                alt=""
                                onClick={handleAdd}
                            >
                                +
                            </div>
                        </div>
                    </div>

                    <div className="detail-description">
                        {type === GoodsType.SELF ? (
                            <div className="description-block">
                                <div dangerouslySetInnerHTML={{__html: description}}></div>
                            </div>
                        ) : (
                            <div className="img-block">
                                {description?.split(";").map((item, index) => {
                                    return (
                                        <img key={index} src={item} alt="" className="info-img"/>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>

                {/* {renderMode()} */}
                <div className={`detail-full${mode === "full" ? "" : " none"}`}>
                    {imgList?.map((img, i) => {
                        return <img className="detail-image" src={img} key={i} alt=""/>;
                    })}
                </div>

                <div
                    onTouchStart={e => {
                        e.stopPropagation()
                    }}
                    onTouchEnd={e => {
                        e.stopPropagation()
                    }}
                    className={`detail-image-viewer${mode === "down" ? "" : " none"}`}
                >
                    <div className="detail-nav">
                        <img
                            src={BackIcon}
                            className="detail-nav__back"
                            alt=""
                            onClick={() => {
                                setMode("normal");
                            }}
                        />
                    </div>
                    <div className="detail-image-wrapper">
                        <div className="swiper-wrapper">
                            {imgList?.map((img, idx) => {
                                return (
                                    <div className="swiper-slide" key={idx}>
                                        <img
                                            src={img}
                                            className="detail-image-viewer__img"
                                            alt="商品图片"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="swiper-pagination"/>
                    </div>
                </div>

                {mode === "normal" && renderOpe()}
            </div>
        </div>
    );
}

export default inject("detail", "app")(observer(ProductDetail));

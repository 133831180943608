import React, {useCallback, useEffect, useMemo} from 'react'
import BackIcon from 'images/orders/back-black.png'
import {observer, inject} from 'mobx-react'
import Back from 'components/common/back/Back'
import 'styles/orders/modify-order.scss'
import {NavLink} from 'react-router-dom'
import ToAddressIcon from 'images/orders/detail-to-address-icon.png'
import * as api from 'utils/api/api';
import {useParams} from 'react-router-dom';
import * as antd from 'antd';
import {useHistory} from 'react-router-dom'

const ModifyOrder = ({modifyOrder: store, selectOrderAddress}) => {

    const history = useHistory();
    const params = useParams();
    const id = Number(params.id);

    const fetchInfo = useCallback(() => {
        if (!id) {
            return;
        }

        api.orderAddressInfo({orderId: id}).then(info => {
            store.orderAddress = info;
        });
    }, [id]);

    const modifyAddress = useCallback(() => {
        if (!selectOrderAddress.currentAddress) {
            return;
        }
        const {
            name,
            telNumber: phone,
            province,
            city,
            district,
            detailInfo: address
        } = selectOrderAddress.currentAddress;

        store.orderAddress = {
            name,
            phone,
            province,
            city,
            district,
            address
        }

    }, [selectOrderAddress.currentAddress]);


    const fullAddress = useMemo(() => {
        const {orderAddress: address} = store;

        return address?.province + address?.city + address?.district + address?.address;
    }, [store.orderAddress])

    useEffect(() => {
        if (selectOrderAddress.currentAddress) {
            return;
        }

        fetchInfo();
    }, [fetchInfo]);

    useEffect(() => {
        modifyAddress();
    }, [modifyAddress]);

    const onSubmit = () => {
        const {orderAddress} = store;

        api.modifyOrder({orderId: id, orderAddress}).then(() => {
            antd.message.success("修改成功");
            history.goBack();
        });
    };

    return (
        <div className='modify-order'>
            <Back className='modify-order-top'>
                <img src={BackIcon} className='modify-order-top__back-icon'/>
                <p className='modify-order-top__title'>修改订单</p>
            </Back>
            <div className='modify-order-body'>
                <p className='modify-order-body__title'>修改收货人信息</p>
                <p>
                    <span>收货人：</span>
                    <span>{store.orderAddress?.name}</span>
                </p>
                <p>
                    <span>联系方式：</span>
                    <span>{store.orderAddress?.phone}</span>
                </p>
                <p>
                    <span>收货地址：</span>
                    <span>
            <NavLink to='/orders/select-address'>
                {fullAddress}
            </NavLink>
            <NavLink
                to='/orders/select-address'
                className='modify-order-body__address-icon'
            >
              <img src={ToAddressIcon}/>
            </NavLink>
          </span>
                </p>
            </div>
            <div className='modify-order-confirm' onClick={onSubmit}>确认修改</div>
        </div>
    )
}

export default inject('modifyOrder', 'selectOrderAddress')(observer(ModifyOrder))

import React from 'react';

import './shop-title.scss';
import { inject, observer } from 'mobx-react';
const ShopTitle = props => {
  const { shop: store } = props;

  return (
    <div className='shop-title'>
      <div className='shop-title__info'>
        <div className='img-block'>
          <img
            className='img'
            src={store.shopInfo && store.shopInfo.img}
            alt=''
          ></img>
        </div>
        <div className='shop-title__text'>
          <span className='shop-title__title'>
            {store.shopInfo && store.shopInfo.name}
          </span>
          {/* <span className='shop-title__followers'>{followers}人关注</span> */}
        </div>
      </div>

      {store.hasCollect ? (
        <div
          className='shop-title__ope'
          onClick={() => {
            store.switchCollect();
          }}
        >
          取消收藏
        </div>
      ) : (
        <div
          className='shop-title__ope'
          onClick={() => {
            store.switchCollect();
          }}
        >
          收藏
        </div>
      )}
    </div>
  );
};

export default inject('shop')(observer(ShopTitle));

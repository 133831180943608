import React from 'react'

import NewsHeader from 'partials/news/news-header/NewsHeader'
import { parsePathVar } from 'utils/strings'

import 'styles/news.scss'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import NewsSwiper from 'partials/news/news-swiper/NewsSwiper'
import NewsItem from 'partials/news/news-item/NewsItem'
import { useEffect } from 'react'
import useQuery from 'hooks/useQuery'
import { useCallback } from 'react'
import { PullToRefresh } from 'antd-mobile'

function News(props) {
  const { news: store, app } = props
  const { clientWidth } = app

  const query = useQuery()

  /**
   * 初始化
   */
  useEffect(() => {
    store.init()

    if (!query.categoryId || query.categoryId === 'undefined') {
      store.categoryId = undefined
    } else {
      store.categoryId = Number(query.categoryId)
    }
  }, [query.categoryId, store])

  /**
   * 获取类别列表
   */
  useEffect(() => {
    store.fetchCategoryList()
  }, [])

  /**
   * 获取资讯列表
   */
  const fetchList = useCallback(() => {
    store.fetchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.categoryId, store.pageSize, store.pageNum])
  useEffect(() => {
    fetchList()
  }, [fetchList, store.pageSize, store.pageNum])

  return (
    <div className='news'>
      <NewsHeader />
      {/* {query.categoryId === undefined && <NewsSwiper width={clientWidth} />} */}

      <div className='news__body'>
        <PullToRefresh
          damping={60}
          direction='up'
          refreshing={store.refreshing}
          onRefresh={() => {
            console.log('???')
            store.pageSize += store.PAGE_SIZE
            store.refreshing = true
          }}
          style={{
            overflow: 'auto'
            // height: '100%'
          }}
        >
          {store.list.map((item, idx) => {
            return <NewsItem {...item} key={idx} />
          })}
        </PullToRefresh>
      </div>
    </div>
  )
}

export default inject('news', 'app')(observer(News))

import React, { Component } from "react";
import VideosHeader from "partials/videos/videos-header/VideosHeader";
import { observer, inject } from "mobx-react";
import VideoItem from "partials/videos/video-item/VideoItem";
import "video-react/dist/video-react.css";
import "styles/videos.scss";
import { useEffect } from "react";
import { useCallback } from "react";
import usePullToLoad from "hooks/usePullToLoad";
import Loading from "components/loading/Loading";

function Videos(props) {
  const { video: store } = props;
  usePullToLoad(()=>{
   store.pageSize += store.PAGE_SIZE;
   store.fetchList();
  },()=>{
  });

  /**
   * 初始化
   */
  useEffect(() => {
    store.init();
  }, []);

  /**
   * 获取视频列表
   */
  const fetchList = useCallback(() => {
    store.fetchList();
  }, [store.pageSize, store.pageNum]);
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <div className="videos">
      <VideosHeader />
      {store.list.map((item, i) => {
        return <VideoItem {...item} key={i} />;
      })}
      {store.refreshing && <Loading />}
    </div>
  );
}

export default inject("video")(observer(Videos));

import React, { useCallback, useEffect } from "react";
import { inject, observer, useObserver } from "mobx-react";
import "./live.scss";
import LiveList from "./partials/live-list/LiveList";
import VideoList from "./partials/video-list/VideoList";
import { useHistory } from "react-router-dom";
import * as Api from "utils/api/api";

const Live = ({ live: store }) => {
  const history = useHistory();

  const fetchLiveList = useCallback(async () => {
    await Api.liveList({
      page: 1,
      size: 100,
    }).then((data) => {
      const { list, totalNum } = data;
      store.list = list;
    });
  }, []);
  useEffect(() => {
    fetchLiveList();
  }, [fetchLiveList]);

  const fetchVideoList = useCallback(async () => {
    await Api.videoList({
      pageSize: 10000,
      pageNum: 1,
    }).then((data) => {
      store.video.list = data.list;
    });
  }, []);
  useEffect(() => {
    fetchVideoList();
  }, []);

  const onPlay = (id) => {
    history.push({
      pathname: `/live-room`,
      search: `?id=${id}`,
    });
  };

  return (
    <div className="glx-live-page">
      <img src={require("./img/live-icon.png")} alt="" className="live-icon" />
      {/* <div className="close-icon-wrapper">
                <img src={require('./img/close-icon.png')} alt="" className="close-icon"/>
            </div> */}

      <div className="live-list-container">
        <LiveList onPlay={onPlay} />
      </div>

      <div className="video-list-container">
        <VideoList videoList={store.video.list} />
      </div>
    </div>
  );
};

export default inject("live")(observer(Live));

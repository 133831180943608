import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';


export async function defaultLive() {
    return http({
        method: Method.GET,
        url: DOMAIN + '/api/live/default'
    }).then(res => res.data)
}

export async function defaultLiveContext() {
    return http({
        method: Method.GET,
        url: DOMAIN + '/api/live/default/context'
    }).then(res => res.data)
}
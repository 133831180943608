import React from "react";
import './gift-panel.scss'
import giftMap from 'pages/live-room/data/gift-map'
import {observer, useLocalStore} from "mobx-react";

const GiftPanel = ({onClose, onSendGift}) => {
    const localStore = useLocalStore(() => ({
        activeId: 0
    }))

    const onSelect = (id) => {
        localStore.activeId = id;
    }

    return (
        <div className="glx-live-room__gift-panel">
            <div className="close-container" onClick={onClose}>
                <img src={require('./img/关闭面板.png')} alt="" className="icon"/>
            </div>
            <div className="title-container">
                给主播赠送一个小礼物吧~
            </div>

            <div className="gift-container">
                {
                    [...giftMap.entries()].map(([key, value]) => (
                        <div className={`item ${localStore.activeId === key ? 'active' : ''}`}
                             key={key}
                             onClick={(e) => {
                                 if (e.target.id === 'send-btn') {
                                     return;
                                 }

                                 onSelect(key)
                             }}>
                            <div className="icon-wrapper">
                                <img src={value.bigIcon} alt="" className="icon"/>
                            </div>
                            <div className="free">免费</div>
                            <button
                                className="send-btn"
                                id='send-btn'
                                onClick={() => {
                                    onSendGift(key)
                                }}>赠送
                            </button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default observer(GiftPanel);
import {observable, computed, action, autorun, makeAutoObservable} from 'mobx';
import moment from 'moment';
import * as Api from 'utils/api/api';

class FlashSaleStore {
  @observable.ref PAGE_SIZE = 10;
  @observable _list = [];
  @observable _pageSize = this.PAGE_SIZE;
  @observable _pageNum = 1;
  @observable _keyword = '';
  @observable _refreshing = false;

  constructor() {
    makeAutoObservable(this)
  }
  get list() {
    return this._list;
  }
  set list(value) {
    this._list = value;
  }
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value) {
    this._pageSize = value;
  }
  get pageNum() {
    return this._pageNum;
  }
  set pageNum(value) {
    this._pageNum = value;
  }
  get keyword() {
    return this._keyword;
  }
  set keyword(value) {
    this._keyword = value;
  }
  get refreshing() {
    return this._refreshing;
  }
  set refreshing(value) {
    this._refreshing = value;
  }

  @action init() {
    this._list = [];
    this._pageSize = this.PAGE_SIZE;
    this._pageNum = 1;
    this._keyword = '';
    this._refreshing = false;
  }

  /**
   * 获取商品列表
   */
  @action async fetchList() {
    try {
      this._refreshing = true;
      const { pageSize, pageNum, keyword } = this;

      const { list } = await Api.oldSecKillList({
        pageSize,
        pageNum,
        keyword
      });

      this._list = list;
    } catch (error) {
    } finally {
      this.refreshing = false;
    }
  }

  
}

const store = new FlashSaleStore();

export default store;

import React, {useEffect, useMemo, useRef} from "react";
import {observer} from "mobx-react";
import useQuery from "hooks/useQuery";
import TIM, * as Tim from "utils/tim";
import * as TimEvent from "utils/timEvent";
import * as Api from "utils/api/api";
import Hls from "hls.js";
import DPlayer from "dplayer";
import "./live-room.scss";
import {
    useDidMount,
    useSessionStorage,
    useWillUnmount,
} from "beautiful-react-hooks";
import vhCheck from "vh-check";
import PlayingPanel from "./partials/playing-panel/PlayingPanel";
import {useLocalStore} from "mobx-react-lite";
import TimCustomerMessageType from "enum/TimCustomeMessageType";
import useMyStores from "hooks/useMyStores";
import {ONLINE} from "utils/api/api-utils";
import {nanoid} from "nanoid";
import * as Antd from "antd";
import {DOMAIN} from "utils/api/api-utils";
import {defaultAvatar} from "utils/api/api";

const defaultNickname = "人工智能用户";

const LiveRoom = () => {
    const {liveRoom: store, app} = useMyStores();
    const query = useQuery();
    const id = useMemo(() => {
        return Number(query.id);
    }, [query.id]);
    const chatMessageListRef = useRef(undefined);

    const [imUserId, setImUserId] = useSessionStorage("imUserId", "");
    const localStore = useLocalStore(() => ({
        didMount: false,
        isCurrentWebsiteLive: false,
        bulletCommentList: [],
        giftMessageList: [],
        loadingKey: "loading",
    }));

    const getCurrentGoods = () => {
        return Api.liveTaskCurrentGoods({liveTaskId: store.liveTask.id}).then(
            (goods) => {
                store.currentGoods = goods;
            }
        );
    };

    const getLiveInfo = () => {
        return Api.liveDto(id).then((data) => {
            store.live = data.live;
            store.liveTask = data.liveTask;

            if (data.live.domain.indexOf(DOMAIN) !== -1) {
                localStore.isCurrentWebsiteLive = true;
            }
        });
    };

    /**
     * 监听消息列表的变化
     */
    useEffect(() => {
        if (!store.timReady || !store.messageList.length) {
            return;
        }

        /*
         * 修改当前展示的商品
         * */
        const isCurrentGoodsChange = (message) => {
            return (
                message.type === TIM.TYPES.MSG_CUSTOM &&
                Number(message.payload.extension) ===
                TimCustomerMessageType.CHANGE_CURRENT_GOODS.value
            );
        };

        let getCurrentGoodsPromise = undefined;
        store.messageList.forEach((message) => {
            if (isCurrentGoodsChange(message)) {
                getCurrentGoodsPromise = () => getCurrentGoods();
            }
        });
        if (getCurrentGoodsPromise) {
            getCurrentGoodsPromise().then();
        }

        /*
         * 直播间信息更新
         * */
        const isLiveInfoUpdate = (message) => {
            return (
                message.type === TIM.TYPES.MSG_CUSTOM &&
                Number(message.payload.extension) ===
                TimCustomerMessageType.UPDATE_LIVE_INFO.value
            );
        };
        let getLiveInfoPromise = undefined;
        store.messageList.forEach((message) => {
            if (isLiveInfoUpdate(message)) {
                getLiveInfoPromise = () => getLiveInfo();
            }
        });
        if (getLiveInfoPromise) {
            getLiveInfoPromise().then();
        }

        const messageIsTip = (type) => {
            return (
                type === TIM.TYPES.MSG_GRP_TIP || type === TIM.TYPES.MSG_GRP_SYS_NOTICE
            );
        };

        /*
         * 获取弹幕消息列表
         * */
        const getBulletCommentList = async (messageList) => {
            localStore.bulletCommentList = (
                await Promise.all(
                    messageList.map(async (message) => {
                        let fromProfile = undefined;

                        let content = null;
                        switch (message.type) {
                            case TIM.TYPES.MSG_GRP_TIP:
                            case TIM.TYPES.MSG_GRP_SYS_NOTICE: {
                                switch (message.payload.operationType) {
                                    case TIM.TYPES.GRP_TIP_MBR_JOIN:
                                    default: {
                                        const joinProfile = await Tim.getUserProfile(
                                            message.payload.operatorID
                                        );
                                        content = `${joinProfile.nick} 进入直播间`;
                                    }
                                }
                                break;
                            }
                            case TIM.TYPES.MSG_TEXT:
                            default:
                                fromProfile = await Tim.getUserProfile(message.from);
                                content = message.payload.text;
                                break;
                        }

                        return {
                            id: message.ID,
                            isTip: messageIsTip(message.type),
                            from: message.from,
                            fromProfile,
                            content,
                        };
                    })
                )
            ).filter((message) => message.content);
        };
        getBulletCommentList(store.messageList).then();

        /*
         * 获取礼物消息列表
         * */
        const getGiftMessageList = async (messageList) => {
            localStore.giftMessageList = await Promise.all(
                messageList
                    .filter(
                        (message) =>
                            message.type === TIM.TYPES.MSG_CUSTOM &&
                            Number(message.payload.extension) ===
                            TimCustomerMessageType.SEND_GIFT.value
                    )
                    .map(async (message) => ({
                        id: message.ID,
                        isTip: messageIsTip(message.type),
                        from: message.from,
                        fromProfile: await Tim.getUserProfile(message.from),
                        content: message.payload.data,
                    }))
            );
        };
        getGiftMessageList(store.messageList).then();
    }, [store.timReady, store.messageList]);

    useDidMount(() => {
        /*
         * 解决手机浏览器 vh 不准的问题
         * */
        vhCheck();

        /*
         * 初始化
         * */
        store.init();

        localStore.didMount = true;
    });

    /**
     * 获取直播间详情
     */
    useEffect(() => {
        if (!id) {
            return;
        }

        getLiveInfo().then();
    }, [id]);

    /*
     * 登录im
     * */
    useEffect(() => {
        let innerImUserId = imUserId;
        if (!innerImUserId || innerImUserId === "") {
            innerImUserId = nanoid();
            setImUserId(innerImUserId);
        }

        Antd.message.loading({
            key: localStore.loadingKey,
            // 设置为0时不自动关闭
            duration: 0,
            content: "直播登录中...",
        });
        Tim.login({userId: innerImUserId}).then();
    }, []);

    /**
     * 完善用户资料
     */
    useEffect(() => {
        /**
         * timReady用处：
         * 用户获取自己的资料
         */
        if (!store.timReady) {
            return;
        }

        const updateProfile = async () => {
            let nickname = "";
            let avatar = "";
            let gender = "";
            if (app.hasLogin) {
                const info = await Api.userInfo().then((data) => data.info);
                nickname = info.nickname;
                avatar = info.avatar;
                gender = info.gender;
            }

            if (!nickname) {
                nickname = defaultNickname;
            }

            if (!avatar) {
                avatar = "";
            }

            if (gender === "男") {
                gender = TIM.TYPES.GENDER_MALE;
            } else if (gender === "女") {
                gender = TIM.TYPES.GENDER_FEMALE;
            } else {
                gender = TIM.TYPES.GENDER_UNKNOWN;
            }

            Tim.updateMyProfile({
                nickname,
                avatar,
                gender,
            });
        };

        updateProfile().then();
    }, [store.timReady]);

    /*
     * 监听tim事件
     * */
    useEffect(() => {
        /*
         * sdk ready
         * */
        TimEvent.onTimReady(() => {
            store.timReady = true;
            Antd.message.destroy();
        });

        /**
         * 群事件
         */
        TimEvent.onMessageReceived((messageList) => {
            const messageFilter = (message) => {
                if (
                    message.type === TIM.TYPES.MSG_GRP_TIP &&
                    message.payload.operationType ===
                    TIM.TYPES.GRP_TIP_MBR_PROFILE_UPDATED
                ) {
                    return false;
                } else {
                    return true;
                }
            };


            store.putMessageList(
                messageList.filter((message) => messageFilter(message))
            );
        });
    }, []);

    /**
     * 控制弹幕滚动条
     */
    useEffect(() => {
        if (!chatMessageListRef.current) {
            return;
        }

        chatMessageListRef.current.scrollTop =
            chatMessageListRef.current.scrollHeight;
    }, [localStore.bulletCommentList, chatMessageListRef.current]);

    /**
     * 获取弹幕群组详情
     * 获取弹幕群组成员列表
     */
    useEffect(() => {
        if (!store.timReady || !store.live?.groupId) {
            return;
        }

        /*
         * 获取弹幕组详情
         * */
        Tim.getGroupProfile({groupId: store.live.groupId}).then((group) => {
            store.group.info = group;
        });

        /*
         * 获取弹幕成员列表
         *  */
        Tim.getGroupMemberList({groupId: store.live.groupId}).then(
            (memberList) => {
                store.group.memberList = memberList;
            }
        );

        /*
         * 加入弹幕群组
         * */
        Tim.joinGroup({
            groupId: store.live.groupId,
        });
    }, [store.timReady, store.live?.groupId]);

    /**
     * 创建player对象
     */
    useEffect(() => {
        if (!store.live || !store.liveTask || !localStore.didMount) {
            return;
        }

        let {id, pullUrl} = store.live;
        let {poster} = store.liveTask;
        if (!poster) {
            poster = require("./img/default-poster.png");
        }

        store.player = new DPlayer({
            container: document.getElementById("glx-live-room-player"),
            // 是否是直播
            // live: true,
            // 设置自动播放会出现问题
            // autoplay: true,
            video: {
                pic: poster,
                url: pullUrl,
                type: "customHls",
                customType: {
                    customHls: function (video, player) {
                        const hls = new Hls();
                        hls.loadSource(video.src);
                        hls.attachMedia(video);
                    },
                },
            },
            hotkey: false,
        });
        store.player.on("play", () => {
            store.startingPlay = true;
            Antd.message.loading({
                key: localStore.loadingKey,
                // 设置为0时不自动关闭
                duration: 0,
                content: "拉取直播...",
            });
        });
        store.player.on("playing", () => {
            store.playing = true;
            Antd.message.destroy();
        });
    }, [store.live, store.liveTask, localStore.didMount]);

    /**
     * 获取当前直播的商品信息
     */
    useEffect(() => {
        if (!localStore.isCurrentWebsiteLive || !store.liveTask) {
            return;
        }

        getCurrentGoods().then();
    }, [localStore.isCurrentWebsiteLive, store.liveTask]);

    /*
     * 发送弹幕
     * */
    function onSendBulletComments(key) {
        if (store.bulletComment === "") {
            return;
        }

        if (key === "Enter") {
            /*
             * 发送弹幕
             * */
            Tim.sendMessage({
                groupId: store.live.groupId,
                text: store.bulletComment,
            }).then((message) => {
                /**
                 * 将发送的消息加入消息列表[因为自己发送的消息不会通知自己]
                 */
                store.putMessage(message);

                /*
                 * 清空input
                 * */
                store.bulletComment = "";
            });
        }
    }

    /**
     * 送礼物
     * */
    function onSendGift(id) {
        Tim.sendGift({
            groupId: store.live.groupId,
            giftId: id,
        }).then((message) => {
            store.putMessage(message);
        });
    }

    /**
     * 退出弹幕群组
     */
    const quitGroup = () => {
        Tim.quitGroup({
            groupId: store.live.groupId,
        });
        if (!ONLINE) {
            /*
             * 删除用户
             * */
            Tim.getMyProfile().then((profile) => {
                Api.imDeleteAccount({userId: profile.userID});
            });
        }
    };

    /*
     * 用户离开时
     * 1.退出群组
     * 2.退出im登录
     * */
    useEffect(() => {
        const beforeUnload = async () => {
            quitGroup();
            await Tim.logout();
        };
        window.addEventListener("beforeunload", beforeUnload);

        return () => {
            window.removeEventListener("beforeunload", beforeUnload);
        };
    }, []);

    return (
        <div className="glx-live-room-page" id="glx-live-room-page">
            <div className="player-container">
                <div className="player" id="glx-live-room-player"/>
                {!store.startingPlay && (
                    <div
                        className="play-panel-container"
                        onClick={() => {
                            store.player.play();
                        }}
                    >
                        <div className="play-icon-wrapper">
                            <img
                                src={require("./img/play-icon.png")}
                                alt=""
                                className="play-icon"
                            />
                        </div>
                    </div>
                )}

                {store.playing && (
                    <div className="playing-panel-container">
                        <PlayingPanel
                            chatMessageListRef={chatMessageListRef}
                            onSendBulletComments={onSendBulletComments}
                            onSendGift={onSendGift}
                            bulletCommentList={localStore.bulletCommentList}
                            bulletComment={store.bulletComment}
                            onBulletCommentChange={(value) => (store.bulletComment = value)}
                            giftMessageList={localStore.giftMessageList}
                            removeMessage={(id) => store.removeMessage(id)}
                            currentGoods={store.currentGoods}
                            goodsList={store.liveTask?.goodsList}
                            heat={store.live.heat}
                            liveName={store.live.liveName}
                            memberList={store.group.memberList}
                            defaultAvatar={defaultAvatar}
                            defaultNickname={defaultNickname}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default observer(LiveRoom);

import React from 'react'
import { observer } from 'mobx-react'
import { inject } from 'mobx-react'
import { Link } from 'react-router-dom'

import VideoIcon from '../images/videos/video-icon.png'
import HomeSelectedIcon from '../images/old-home/home-selected.png'
import HomeUnselectedIcon from '../images/old-home/home-unselected.png'
import CategorySelectedIcon from '../images/category/category-selected.png'
import CategoryUnselectedIcon from '../images/category/category-unselected.png'
import CartSelectedIcon from '../images/cart/cart-active.png'
import CartUnselectedIcon from '../images/cart/cart.png'
import MySelectedIcon from '../images/my/my-selected.png'
import MyUnselectedIcon from '../images/my/my-unselected.png'
import * as strings from '../utils/strings'

import './nav.scss'
@inject('app')
@observer
class Nav extends React.Component {
  constructor(props) {
    super(props)
    const tab = strings.parsePathVar(window.location.href)
    props.app.setTab('/' + tab)
  }
  switchToPage = (path: string) => {
    const { app } = this.props
    const { setTab } = app
    setTab(path)
  }

  renderItem = (
    title: string,
    path: string,
    icon: string,
    iconSelected: string
  ) => {
    const curTab = this.props.app.currentTab
    return (
      <Link to={path} className='bottom-nav-item'>
        <img
          className='bottom-nav-item__icon'
          alt={title}
          src={curTab === path ? iconSelected : icon}
        />
        <span
          className={`bottom-nav-item__title ${
            curTab === path ? 'bottom-nav-item__title--active' : ''
          }`}
        >
          {title}
        </span>
      </Link>
    )
  }

  // renderVideoEntry = () => {
  //   return (
  //     <div className='bottom-nav-video'>
  //       <Link to='/videos'>
  //         <img
  //           src={VideoIcon}
  //           className='bottom-nav-video__icon'
  //           alt='视频专区'
  //         />
  //       </Link>
  //     </div>
  //   )
  // }
  renderLiveEntry = () => {
    return (
      <div className='bottom-nav-live'>
        <Link to='/live'>
          <img
            src={VideoIcon}
            className='bottom-nav-live__icon'
            alt='直播专区'
          />
        </Link>
      </div>
    )
  }

  render() {
    const { app } = this.props
    const { currentTab } = app
    return (
      <div className='bottom-nav'>
        {this.renderItem('主页', '/home', HomeUnselectedIcon, HomeSelectedIcon)}
        {this.renderItem(
          '分类',
          '/category',
          CategoryUnselectedIcon,
          CategorySelectedIcon
        )}
        {/* {this.renderVideoEntry()} */}
        {this.renderLiveEntry()}
        {this.renderItem(
          '购物车',
          '/cart',
          CartUnselectedIcon,
          CartSelectedIcon
        )}
        {this.renderItem('我的', '/my', MyUnselectedIcon, MySelectedIcon)}
      </div>
    )
  }
}

export default Nav

import {makeAutoObservable} from "mobx";

class GlxQueueStore {
    items = [];

    // 向队列添加元素（一个或多个）
    enqueue(element) {
        if (element instanceof Array) this.items = this.items.concat(element);
        else this.items.push(element);
    };

    // 从队列移除元素
    dequeue() {
        return this.items.shift();
    };

    // 返回队列中的第一个元素
    get first() {
        return this.items[0];
    };

    // 判断队列是否为空
    get isEmpty() {
        return this.items.length === 0;
    };

    // 返回队列的长度
    get size() {
        return this.items.length;
    };

    // 清空队列
    clear() {
        this.items = [];
    };

    // 打印队列内的所有元素
    print() {
        console.log(JSON.stringify(this.items));
    };


    constructor() {
        makeAutoObservable(this)
    }
}

export default GlxQueueStore
import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';


export async function currentLiveRecord({liveId}) {
    return http({
        method: Method.GET,
        url: DOMAIN + '/api/live_record/current',
        params: {
            liveId
        }
    }).then(res => res.data)
}
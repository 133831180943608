import React, {useEffect} from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom'

import Home from './pages/home/Home'
import Category from './pages/category/Category'
import Cart from './pages/cart/Cart'
import My_v2 from './pages/my/My_v2'

import Search from './pages/search/Search'
import News from './pages/news/News'
import NewsInfo from './pages/news-info/NewsInfo'
import ProductDetail from './pages/product-detail/ProductDetail'
import Shops from './pages/shops/Shops'
import Shop from './pages/shop/Shop'
import Videos from './pages/videos/Videos'

import './App.scss'
import Account from './pages/account/Account'
import Login from './pages/login/Login'
import ModifyAvatar from './pages/modify-avatar/ModifyAvatar'
import ModifyGender from './pages/modify-gender/ModifyGender'
import ModifyNickname from './pages/modify-nickname/ModifyNickname'
import ModifyPassword from './pages/modify-password/ModifyPassword'
import Register from './pages/register/Register'
import SearchResult from './pages/search-result/SearchResult'
import GoodsCollect from './pages/goods-collect/GoodsCollect'
import ShopCollect from './pages/shop-collect/ShopCollect'
import Track from './pages/track/Track'
import ConfirmGoods from 'components/confirm-goods/ConfirmGoods'
import Service from 'components/service/Service'
import FlashSale from './pages/flash-sale/FlashSale'
import Help from './pages/help/Help'
import HelpInfo from './pages/help-info/HelpInfo'
import Merchant from './pages/merchant/Merchant'
import {inject, observer} from 'mobx-react'
import Orders from 'pages/orders/Orders'
import Order from 'pages/order/Order'
import Address from 'pages/orders/Address'
import AddAddress from 'pages/orders/AddAddress'
import ModifyAddressStore from "./stores/ModifyAddressStore";
import RefundApply from 'pages/orders/RefundApply'
import RefundDetail from 'pages/orders/RefundDetail'
import ModifyOrder from 'pages/orders/ModifyOrder'
import ConfirmReceive from 'pages/orders/ConfirmReceive'
import OrderPay from 'pages/orders/OrderPay'
import PaySuccess from 'partials/order/pay/pay-success/PaySuccess'
import Live from "./pages/live/Live.jsx";
import LiveRoom from 'pages/live-room/LiveRoom'
import ModifyAddress from "./pages/orders/ModifyAddress";
import SelectOrderAddress from "./pages/select-order-address/SelectOrderAddress";
import LivePoster from "./pages/live-poster/LivePoster";
import OpenLiveProcess from "./pages/open-live-process/OpenLiveProcess";
import PlayVideo from "./pages/play-video/PlayVideo";

const supportsHistory = 'pushState' in window.history

function App(props) {
    const {confirmGoods, service} = props
    return (
        <div className='app'>
            <Router forceRefresh={!supportsHistory} basename='/mobile'>
                <Switch>
                    <Route path='/home' exact>
                        <Home/>
                    </Route>

                    <Route path='/category' exact>
                        <Category/>
                    </Route>

                    <Route path='/cart' exact>
                        <Cart/>
                    </Route>

                    <Route path='/shops' exact>
                        <Shops/>
                    </Route>

                    <Route path='/shop'>
                        <Switch>
                            <Route path='/shop/:id'>
                                <Shop/>
                            </Route>
                            <Route path='/shop' exact>
                                <Redirect to='/shops'/>
                            </Route>
                        </Switch>
                    </Route>

                    <Route path='/my'>
                        <Switch>
                            <Route path='/my' exact>
                                <My_v2/>
                            </Route>
                            <Route path='/my/collect'>
                                <Switch>
                                    <Route path='/my/collect/goods' exact>
                                        <GoodsCollect/>
                                    </Route>
                                    <Route path='/my/collect/shop' exact>
                                        <ShopCollect/>
                                    </Route>
                                </Switch>
                            </Route>
                            <Route path='/my/track'>
                                <Track/>
                            </Route>
                            <Route path='/my/account'>
                                <Switch>
                                    <Route path='/my/account/login' exact>
                                        <Login/>
                                    </Route>
                                    <Route path='/my/account/register' exact>
                                        <Register/>
                                    </Route>
                                    <Route path='/my/account' exact>
                                        <Account/>
                                    </Route>
                                </Switch>
                            </Route>
                            <Route path='/my/modify-avatar'>
                                <ModifyAvatar/>
                            </Route>
                            <Route path='/my/modify-gender'>
                                <ModifyGender/>
                            </Route>
                            <Route path='/my/modify-nickname'>
                                <ModifyNickname/>
                            </Route>
                            <Route path='/my/modify-password'>
                                <ModifyPassword/>
                            </Route>
                        </Switch>
                    </Route>

                    <Route path='/detail/:id'>
                        <ProductDetail/>
                    </Route>

                    <Route path='/search'>
                        <Switch>
                            <Route path='/search' exact>
                                <Search/>
                            </Route>
                            <Route path='/search/result'>
                                <SearchResult/>
                            </Route>
                        </Switch>
                    </Route>

                    <Route path='/news'>
                        <Switch>
                            <Route path='/news' exact>
                                <News/>
                            </Route>
                            <Route path='/news/info/:id'>
                                <NewsInfo/>
                            </Route>
                        </Switch>
                    </Route>

                    <Route path='/flash-sale'>
                        <FlashSale/>
                    </Route>

                    <Route path='/videos'>
                        <Switch>
                            <Route path='/videos/:type'>
                                <Videos/>
                            </Route>
                            <Route path='/videos' exact>
                                <Redirect to='/videos/recommend'/>
                            </Route>
                        </Switch>
                    </Route>

                    <Route path='/merchant'>
                        <Merchant/>
                    </Route>

                    <Route path='/help'>
                        <Switch>
                            <Route path='/help' exact>
                                <Help/>
                            </Route>
                            <Route path='/help/info/:id'>
                                <HelpInfo/>
                            </Route>
                        </Switch>
                    </Route>

                    <Route path='/orders'>
                        <Switch>
                            <Route path='/orders' exact>
                                <Orders/>
                            </Route>
                            <Route path='/orders/address' exact>
                                <Address/>
                            </Route>
                            <Route path='/orders/add-address' exact>
                                <AddAddress/>
                            </Route>
                            <Route path='/orders/modify-address/:id' exact>
                                <ModifyAddress/>
                            </Route>
                            <Route path='/orders/select-address'>
                                <SelectOrderAddress/>
                            </Route>

                            <Route path='/orders/:id/refund/apply' exact>
                                <RefundApply/>
                            </Route>
                            <Route path='/orders/:orderId/refund' exact>
                                <RefundDetail/>
                            </Route>
                            <Route path='/orders/:id/modify' exact>
                                <ModifyOrder/>
                            </Route>
                            <Route path='/orders/:id/confirm' exact>
                                <ConfirmReceive/>
                            </Route>
                            <Route path='/orders/pay/:id' exact>
                                <OrderPay/>
                            </Route>
                            <Route path='/orders/:id' exact>
                                <Order/>
                            </Route>
                        </Switch>
                    </Route>

                    <Route path='/pay/success' exact>
                        <PaySuccess/>
                    </Route>

                    <Route path="/live">
                        <Switch>
                            <Route path="/live" exact>
                                <Live/>
                            </Route>
                        </Switch>
                    </Route>

                    <Route path="/live-room">
                        <LiveRoom/>
                    </Route>

                    <Route path='/live-poster'>
                        <LivePoster/>
                    </Route>

                    <Route path='/open-live-process'>
                        <OpenLiveProcess/>
                    </Route>

                    <Route path='/play-video'>
                        <PlayVideo/>
                    </Route>

                    <Route path="/">
                        <Redirect to="/home"/>
                    </Route>
                </Switch>
            </Router>

            {/* confirm goods */}
            {confirmGoods.show && (
                <div className="confirm-goods-mask">
                    <ConfirmGoods/>
                </div>
            )}
            {/* service */}
            {service.show && (
                <div className="service-mask">
                    <Service/>
                </div>
            )}
        </div>
    )
}

export default inject('confirmGoods', 'service')(observer(App))

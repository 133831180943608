import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';

/**
 * 获取省份列表
 */
export const provinceList = () => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/area/getAll'
    }).then(res => res.data)
        .then(data => data.list);
};

/**
 * 获取地址列表
 */
export const areaList = ({parentId}) => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/area/getByParentId',
        params: {
            parentId
        }
    }).then(res => res.data)
        .then(data => data.list);
};

import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import classNames from "classnames";
import css from "./videoPanel.module.scss";
import {makeAutoObservable, runInAction} from "mobx";
import {DateTime} from "luxon";
import VideoStatusEnum from "enum/VideoStatusEnum";
import {useWillUnmount} from "beautiful-react-hooks";
import {liveGoodsOnTableImg, liveTableImg} from "utils/api/commonApi";

class Store {
    video0Flag = true;
    video0Status = VideoStatusEnum.NO_VIDEO
    video0CurrentTime = 0;
    video0FirstPlay = false


    video1Flag = false;
    video1Status = VideoStatusEnum.NO_VIDEO;
    video1Src = ""


    setVideo0Flag(video0Flag) {
        this.video0Flag = video0Flag
    }

    setVideo1Flag(video1Flag) {
        this.video1Flag = video1Flag
    }


    constructor() {
        makeAutoObservable(this)
    }
}

const store = new Store()

// const VideoStatusEnum = {
//     NO_VIDEO: 'no_video',
//     LOADING: 'loading',
//     READY: 'ready',
//     PLAYING: 'playing'
// }


const Index = ({bgImgUrl, liveRecord, liveContext, aivideoQueue, setCurLiveGoodsIdxByLiveGoodsId}) => {
    const video0Ref = useRef(null)
    const video1Ref = useRef(null)

    /**
     * 执行播放视频0，这个方法会计算视频的当前播放时间
     * 这里要请求后台的redis记录，
     * （当前时间s - 开始时间s - 暂停时间s）% 视频长度
     * @param liveContext
     */
    function doPlayVideo0(liveContext) {
        const now = DateTime.now();
        const startTime = DateTime.fromSQL(liveContext.startTime)
        const pauseSeconds = liveContext.pauseSeconds
        const diffSeconds = Math.round(now.toSeconds() - startTime.toSeconds() - pauseSeconds)

        const videoDuration = Math.round(video0Ref.current.duration)

        let currentPlaySeconds = Math.round(diffSeconds % videoDuration)

        if (currentPlaySeconds < 0) {
            currentPlaySeconds = 0;
        }
        video0Ref.current.currentTime = currentPlaySeconds
        video0Ref.current.play()
    }

    const prepareVideo1 = (src) => {
        runInAction(() => {
            store.video1Src = src
            store.video1Status = VideoStatusEnum.LOADING
            video1Ref.current.load()
        })
    }

    const playVideo1 = () => {
        runInAction(() => {
            video0Ref.current.pause()
            video1Ref.current.play()

            store.video0Flag = false
            store.video1Flag = true
        })
    }

    const onVideo1Ended = () => {
        runInAction(() => {
            store.video0Flag = true
            store.video1Flag = false

            video0Ref.current?.play()

            store.video1Status = VideoStatusEnum.NO_VIDEO
            store.video1Src = ''
        })
    }

    // 监听 插播视频的队列，并准备插播视频
    useEffect(() => {
        if (!video1Ref.current) {
            return;
        }
        if (aivideoQueue.size === 0) {
            return;
        }

        aivideoQueue.print()

        //  这里准备插播视频
        const aivideo1 = aivideoQueue.dequeue()
        prepareVideo1(aivideo1.file)
    }, [aivideoQueue.size, video1Ref.current]);

    // 监听主视频的播放时间，并切换插播视频
    useEffect(() => {
        if (store.video1Status !== VideoStatusEnum.READY) {
            return;
        }

        const pauseSecondsList = liveRecord.pauseSecondsList

        const curTime = Math.ceil(store.video0CurrentTime)

        if (pauseSecondsList.map(item => item.secounds).includes(curTime)) {
            playVideo1()
        }

    }, [store.video1Status, store.video0CurrentTime]);

    // 监听主视频的播放时间，并切换当前商品
    useEffect(() => {
        const pauseSecondsList = liveRecord.pauseSecondsList

        const curTime = Math.ceil(store.video0CurrentTime)

        for (const pauseSeconds of pauseSecondsList) {
            const {seconds, liveGoodsId} = pauseSeconds;

            if (seconds === curTime) {
                setCurLiveGoodsIdxByLiveGoodsId(liveGoodsId)
            }
        }

    }, [store.video0CurrentTime]);

    useEffect(() => {
        if (store.video0Status !== VideoStatusEnum.READY) {
            return;
        }
        try {
            doPlayVideo0()
        } catch (e) {
            console.log('自动播放失败')
        }
    }, [store.video0Status, liveContext])

    useWillUnmount(() => {
        if (store.video0Status === VideoStatusEnum.PLAYING) {
            video0Ref.current.pause()
            store.video0Status = VideoStatusEnum.READY
        }
        if (store.video1Status === VideoStatusEnum.PLAYING) {
            video1Ref.current.pause()
            store.video1Status = VideoStatusEnum.READY
        }
    })

    return (
        <div
            className={css.videoWrapper}
            onClick={() => {
                // if (store.video0Status !== VideoStatusEnum.READY) {
                //     return;
                // }
                //
                // if (!store.video0FirstPlay) {
                //     doPlayVideo0(liveContext)
                // }
            }}
        >
            {/*<img src={bgImgUrl} className={css.bgImg} alt='直播间背景'/>*/}
            <img src={liveTableImg} className={css.table}/>
            <img src={liveGoodsOnTableImg} className={css.goodsOnTable}/>
            {
                store.video0Status === VideoStatusEnum.READY &&
                store.video1Status !== VideoStatusEnum.PLAYING &&
                <div
                    className={css.playPanel}
                    onClick={() => {
                        doPlayVideo0(liveContext)
                    }}
                >
                    <img src={require('./img/播放.png')} className={css.iconPlay}/>
                </div>
            }
            <video
                ref={video0Ref}
                className={classNames(css.video, {
                    [css.videoHide]: !store.video0Flag
                })}
                // style={{
                //     // 直播间背景
                //     backgroundImage: `url(${bgImgUrl})`
                // }}
                // autoPlay
                loop
                onPlay={(e) => {
                    runInAction(() => {
                        store.video0Status = VideoStatusEnum.PLAYING

                        if (!store.video0FirstPlay) {
                            store.video0FirstPlay = true
                        }
                    })
                }}
                onLoadedData={(e) => {
                    runInAction(() => {
                        if (store.video0Status === VideoStatusEnum.PLAYING) {
                            return;
                        }
                        store.video0Status = VideoStatusEnum.READY
                    })
                }}
                onPause={() => {
                    runInAction(() => {
                        store.video0Status = VideoStatusEnum.READY
                    })
                }}
                onTimeUpdate={(e) => {
                    runInAction(() => {
                        store.video0CurrentTime = e.currentTarget.currentTime
                    })
                }}
            >
                <source src={liveRecord.video} type='video/mp4'/>
            </video>

            <video
                ref={video1Ref}
                preload='auto'
                // muted
                className={classNames(css.video, {
                    [css.videoHide]: !store.video1Flag
                })}
                // style={{
                //     // 直播间背景
                //     backgroundImage: `url(${bgImgUrl})`
                // }}
                onCanPlay={(e) => {
                    if (store.video1Status !== VideoStatusEnum.LOADING) {
                        return;
                    }
                    runInAction(() => {
                        e.currentTarget.pause();
                        store.video1Status = VideoStatusEnum.READY
                    })
                }}
                onPlay={() => {
                    runInAction(() => {
                        store.video1Status = VideoStatusEnum.PLAYING
                    })
                }}
                onEnded={() => onVideo1Ended()}
            >
                <source src={store.video1Src} type='video/mp4'/>
                您的浏览器不支持播放该视频！
            </video>
        </div>
    )
}

export default observer(Index)
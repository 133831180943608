import React from "react"
import {observer} from "mobx-react";
import css from './goodsListPanel.module.scss'
import classNames from "classnames";
import LiveGoodsType from "enum/LiveGoodsType";
import * as antd from "antd";
import {goodsDefaultImg} from "../../utils/api/commonApi";

const Index = ({store, onClose, toBuyLiveGoods}) => {

    // store.liveGoodsList = [
    //     ...store.liveGoodsList,
    //     ...store.liveGoodsList,
    //     ...store.liveGoodsList,
    //     ...store.liveGoodsList,
    //     ...store.liveGoodsList,
    //     ...store.liveGoodsList,
    //     ...store.liveGoodsList,
    // ];

    return (
        <div className={css.goodsListPanel} onClick={onClose}>
            <div className={css.card}>

                <div className={css.titleFloor}>
                    <div className={css.text}>
                        {`共${store.liveGoodsCount}件商品`}
                    </div>
                </div>

                <div className={css.goodsList} onClick={e => e.stopPropagation()}>
                    {
                        store.liveGoodsList.map((item,i) => {

                            let {id, name, pic, price, oldPrice = 0,type:liveGoodsType} = item

                            const disable = !name;

                            price = price.toFixed(2)
                            if (oldPrice) {
                                oldPrice = oldPrice.toFixed(2)
                            }

                            const curId = store.curLiveGoods?.id

                            const active = curId === id

                            let skuId;
                            switch (liveGoodsType){
                                case LiveGoodsType.PDD:
                                    skuId = item.pddGoods?.goodsSign;
                                    break;
                                case LiveGoodsType.JD:
                                    skuId = item.jdGoods?.skuId
                                    break;
                                case LiveGoodsType.SELF:
                                default:
                                    skuId = item.selfGoods?.skuId
                                    break;
                            }

                            return (
                                <div
                                    key={id}
                                    className={classNames(css.goods, {
                                        [css.active]: active
                                    })}
                                >
                                    <div className={css.imgWrapper}>
                                        {
                                            disable ? <img src={goodsDefaultImg} alt='' className={css.img} />
                                                : <img src={pic} alt='' className={css.img}/>
                                        }

                                        <div className={css.seqWrapper}>
                                            <div className={css.seq}>
                                                {i + 1}
                                            </div>
                                        </div>

                                        {
                                            active &&
                                            <div className={css.speakingWrapper}>
                                                <img src={require('./img/讲解中icon.png')} alt='' className={css.icon}/>
                                                <div className={css.text}>讲解中</div>
                                            </div>
                                        }
                                    </div>

                                    <div className={css.right}>
                                        <div className={css.name}>
                                            {disable ? '该商品已下架' : name }
                                        </div>
                                        <div className={css.tags}>
                                            <div className={css.item}>
                                                <span>30天最低价</span>
                                            </div>
                                            <div className={css.item}>
                                                <span>正品保障</span>
                                            </div>
                                            <div className={css.item}>
                                                <span>极速退款</span>
                                            </div>
                                        </div>
                                        <div className={css.footer}>
                                            <div className={css.price}>
                                                {`￥${price}`}
                                            </div>
                                            {
                                                oldPrice &&
                                                <div className={css.oldPrice}>{`￥${oldPrice}`}</div>
                                            }

                                            <div
                                                className={classNames(css.btnToBuy,{
                                                    [css.disable]: disable
                                                })}
                                                onClick={e => {
                                                    if (disable) {
                                                        // antd.message.warn("商品已经下架");
                                                        return;
                                                    }
                                                    toBuyLiveGoods({liveGoodsType,skuId})
                                                }}
                                            >
                                                去抢购
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    )
}

export default observer(Index)
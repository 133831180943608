import React, {useCallback, useEffect} from 'react'

import './order-detail.scss'
import moment from 'moment'
import Price from 'components/common/price/Price'
import {inject, observer} from "mobx-react";
import * as api from "utils/api/api";
import PayTypeEnum from "enum/PayTypeEnum";
import AlipayIcon from "images/order-pay/alipay.jpg";
import WechatIcon from "images/order-pay/wechat.jpg";

const OrderDetail = ({order: store}) => {

    return (
        <div className='order-detail'>
            <div>
                <span className='order-detail__key'>订单编号：</span>
                <span className='order-detail__value'>
          {store.sn}
                    <span className='order-detail__copy'>复制</span>
        </span>
            </div>
            <div>
                <span className='order-detail__key'>下单时间：</span>
                <span className='order-detail__value'>
          {moment(store.createTime).format('YYYY-MM-DD hh:mm:ss')}
        </span>
            </div>
            {/*<div>*/}
            {/*    <span className='order-detail__key'>支付方式</span>*/}
            {/*    <span className='order-detail__value'>{store.payType?.name}</span>*/}
            {/*</div>*/}
            <SelectPayType/>
            {/*<div>*/}
            {/*  <span className='order-detail__key'>配送方式</span>*/}
            {/*  <span className='order-detail__value'>{store.delivery?.deliveryCompany}</span>*/}
            {/*</div>*/}
            <div className='order-detail__full-line'>
                <span>商品总额</span>
                <Price price={store.price}/>
            </div>
            {/*<div className='order-detail__full-line'>*/}
            {/*  <span>运费</span>*/}
            {/*  <Price price={store.delivery?.cost} />*/}
            {/*</div>*/}
            <div className='order-detail__pay'>
                <span>需支付</span>
                <Price price={store.price}/>
            </div>
        </div>
    )
}

export default inject('order')(observer(OrderDetail))

const SelectPayType = inject('order')(
    observer(({order: store}) => {
        const fetchPayType = useCallback(() => {
            api.isOpenPayTypeList().then(list => {
                store.payTypeList = list;
                store.payType = list?.[0];
                store.currentPayTypeId = list?.[0].id;
            })
        }, [])

        useEffect(() => {
            fetchPayType();
        }, [fetchPayType])

        const getIcon = payTypeId => {
            switch (payTypeId) {
                case PayTypeEnum.A_LI_PAY.value:
                    return AlipayIcon;
                case PayTypeEnum.WE_CHAT.value:
                    return WechatIcon;
                case PayTypeEnum.UNION_PAY.value:
                default:
                    return ''
            }
        }

        const RadioBtn = ({active}) => {
            return <div className={`radio-btn ${active && 'active'}`}/>
        }

        const onSelect = payTypeId => () => {
            store.currentPayTypeId = payTypeId;
        }

        return (
            <div className='order-pay-select'>
                {
                    store.payTypeList.map(({id, name}) => {
                        return (
                            <div className='option'
                                 key={id}
                                 onClick={onSelect(id)}
                            >
                                <img className='pay-icon' src={getIcon(id)} alt=''/>
                                <span className='pay-name'>{name}</span>
                                <RadioBtn active={id === store.currentPayTypeId}/>
                            </div>
                        )
                    })
                }
            </div>
        )
    })
)
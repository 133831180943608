import {observable, observe, action, makeAutoObservable} from 'mobx';
import * as Api from 'utils/api/api';

class Shop {
  @observable _companyName = '';
  @observable _officialWebsite = '';
  @observable _saleroom = '';
  @observable _hasExperience = false;
  @observable _shopWebsite = '';
  @observable _personNumber = '';
  @observable _goodsNumber = 0;
  @observable _predictAveragePrice = 0;
  @observable _stock = 0;
  @observable _warehouseAddress = '';
  @observable _generalLogistics = '';
  @observable _erpType = 0;
  @observable _thirdPartnarCompany = '';
  @observable _selectOurWarehouse = false;
  @observable _selectOurLogistics = false;
  @observable _openSaleQualification = false;
  @observable _receivedInvite = 0;

  @observable _goodsNumberList = [];
  @observable _predictAveragePriceList = [];
  @observable _stockList = [];
  @observable _erpTypeList = [];
  @observable _receivedInviteList = [];

  constructor() {
    makeAutoObservable(this)
  }
  get receivedInvite() {
    return this._receivedInvite;
  }
  set receivedInvite(value) {
    this._receivedInvite = value;
  }

  get companyName() {
    return this._companyName;
  }

  set companyName(value) {
    this._companyName = value;
  }

  get officialWebsite() {
    return this._officialWebsite;
  }
  set officialWebsite(value) {
    this._officialWebsite = value;
  }

  get saleroom() {
    return this._saleroom;
  }
  set saleroom(value) {
    this._saleroom = value;
  }
  get hasExperience() {
    return this._hasExperience;
  }
  set hasExperience(value) {
    this._hasExperience = value;
  }
  get shopWebsite() {
    return this._shopWebsite;
  }
  set shopWebsite(value) {
    this._shopWebsite = value;
  }
  get personNumber() {
    return this._personNumber;
  }
  set personNumber(value) {
    this._personNumber = value;
  }
  get goodsNumber() {
    return this._goodsNumber;
  }
  set goodsNumber(value) {
    this._goodsNumber = value;
  }
  get predictAveragePrice() {
    return this._predictAveragePrice;
  }
  set predictAveragePrice(value) {
    this._predictAveragePrice = value;
  }
  get stock() {
    return this._stock;
  }
  set stock(value) {
    this._stock = value;
  }
  get warehouseAddress() {
    return this._warehouseAddress;
  }
  set warehouseAddress(value) {
    this._warehouseAddress = value;
  }
  get generalLogistics() {
    return this._generalLogistics;
  }
  set generalLogistics(value) {
    this._generalLogistics = value;
  }
  get erpType() {
    return this._erpType;
  }
  set erpType(value) {
    this._erpType = value;
  }
  get thirdPartnarCompany() {
    return this._thirdPartnarCompany;
  }
  set thirdPartnarCompany(value) {
    this._thirdPartnarCompany = value;
  }
  get selectOurWarehouse() {
    return this._selectOurWarehouse;
  }
  set selectOurWarehouse(value) {
    this._selectOurWarehouse = value;
  }
  get selectOurLogistics() {
    return this._selectOurLogistics;
  }
  set selectOurLogistics(value) {
    this._selectOurLogistics = value;
  }
  get openSaleQualification() {
    return this._openSaleQualification;
  }
  set openSaleQualification(value) {
    this._openSaleQualification = value;
  }
  get goodsNumberList() {
    return this._goodsNumberList;
  }
  set goodsNumberList(value) {
    this._goodsNumberList = value;
  }
  get predictAveragePriceList() {
    return this._predictAveragePriceList;
  }
  set predictAveragePriceList(value) {
    this._predictAveragePriceList = value;
  }
  get stockList() {
    return this._stockList;
  }
  set stockList(value) {
    this._stockList = value;
  }
  get warehouseAddressList() {
    return this._warehouseAddressList;
  }
  set warehouseAddressList(value) {
    this._warehouseAddressList = value;
  }
  get erpTypeList() {
    return this._erpTypeList;
  }
  set erpTypeList(value) {
    this._erpTypeList = value;
  }
  get receivedInviteList() {
    return this._receivedInviteList;
  }
  set receivedInviteList(value) {
    this._receivedInviteList = value;
  }

  @action async fetchGoodsNumberList() {
    try {
      const { list } = await Api.goodsNumberList();

      this._goodsNumberList = list.map(item => {
        const { id: value, numberScope: label } = item;

        return {
          value,
          label
        };
      });
      this._predictAveragePriceList = list.map(item => {
        const { id: value, numberScope: label } = item;

        return {
          value,
          label
        };
      });
    } catch (error) {}
  }

  @action async fetchStockList() {
    try {
      const { list } = await Api.stockList();

      this._stockList = list.map(item => {
        const { id: value, warehouse: label } = item;

        return {
          value,
          label
        };
      });
    } catch (error) {}
  }

  @action async fetchErpTypeList() {
    try {
      const { list } = await Api.erpTypeList();

      this._erpTypeList = list.map(item => {
        const { id: value, erp: label } = item;

        return {
          value,
          label
        };
      });
    } catch (error) {}
  }

  @action async fetchReceivedInvitedList() {
    try {
      const { list } = await Api.receivedInviteList();

      this._receivedInviteList = list.map(item => {
        const { id: value, stands: label } = item;

        return {
          value,
          label
        };
      });
    } catch (error) {}
  }

  @action fetchData() {
    this.fetchGoodsNumberList();
    this.fetchStockList();
    this.fetchErpTypeList();
    this.fetchReceivedInvitedList();
  }
}

let shop = new Shop();

export default shop;

import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from "./api-utils";

export const receiveDelivery = ({id}) => {
    return http({
        method: Method.PUT,
        url: DOMAIN + `/delivery/receive/${id}`
    })
}
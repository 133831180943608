import React, {useEffect, useCallback, useState} from "react";
import {inject} from "mobx-react";
import {observer} from "mobx-react";

import SearchInput from "components/search/search-input/SearchInput";
import Back from "components/common/back/Back";

import HotSearch from "partials/search/hot-search/HotSearch";
import HistorySearch from "partials/search/history-search/HistorySearch";
import RecommendWordList from "partials/search/recommend-word-list/RecommendWordList";

import "styles/search.scss";
import {useHistory} from "react-router-dom";

function Search(props) {
    const {search: store} = props;
    const [keyword, setKeyword] = useState("");
    let history = useHistory();

    /**
     * 历史搜索记录和获取热搜词
     */
    useEffect(() => {
        store.fetchHistoryWordList();
        store.fetchHotWordList();
    }, [store]);

    useEffect(() => {
        store.fetchRecommendWordList({keyword});
    }, [keyword]);

    return (
        <div className="search">
            <div className="search-head">
                <SearchInput
                    keyword={keyword}
                    onChange={e => {
                        setKeyword(e.currentTarget.value);
                        store.showRecommendWordList = true;
                    }}
                    onSubmit={e => {
                        e.preventDefault();
                        store.keyword = keyword;
                        history.push({pathname: "/search/result"});
                    }}
                    onClear={() => {
                        setKeyword("");
                    }}
                />
                <Back className="search-head__cancel" hideBackIcon={true}>
                    <span>取消</span>
                </Back>
            </div>
            <div className="search-content">
                {store.showRecommendWordList ? (
                    <RecommendWordList/>
                ) : (
                    <>
                        <HistorySearch/>
                        <HotSearch/>
                    </>
                )}
            </div>
        </div>
    );
}

export default inject("search")(observer(Search));

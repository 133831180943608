import React, {useCallback, useEffect, useMemo} from 'react'
import 'styles/orders/refund-detail.scss';
import {inject, observer} from 'mobx-react'
import SimpleOrderHeader from 'components/order/simple-order-header/SimpleOrderHeader'
import {useParams} from 'react-router-dom';
import AfterSaleStatusEnum from "enum/AfterSaleStatusEnum";
import showPrice from "utils/show-price";
import * as api from 'utils/api/api';

const Address = ({afterSale: store}) => {
    const params = useParams();

    const orderId = useMemo(() => {
        return Number(params.orderId)
    }, [params.orderId]);

    const fetchInfo = useCallback(() => {
        api.afterSaleInfo({orderId}).then((info) => {
            const {id, orderId, reason, status, createTime, denialReason, afterSaleImgList, order} = info;

            store.id = id;
            store.orderId = orderId;
            store.reason = reason;
            store.status = status;
            store.createTime = createTime;
            store.denialReason = denialReason;
            store.afterSaleImgList = afterSaleImgList;
            store.order = order;

            if (status === AfterSaleStatusEnum.CHECKED.value) {
                api.contactInfo().then(info => {
                    store.contact = info;
                })
            }
        })
    }, [orderId])

    useEffect(() => {
        fetchInfo();
    }, [fetchInfo]);

    return (
        <div className='glx-order-refund-detail'>
            <SimpleOrderHeader text='退款明细'/>

            <div className="card">
                <div className="floor">
                    <div className="line">
                        <span className="label">订单编号：</span>
                        <span className="value">{store.order?.sn}</span>
                    </div>
                    <div className="line">
                        <span className="label">退款进度：</span>
                        <span className="value status">{AfterSaleStatusEnum.getLabelByValue(store.status)}</span>
                    </div>
                    {
                        store.status === AfterSaleStatusEnum.CHECKED.value &&
                        <div className="line">
                            <span className="value">请联系售后电话【{store.contact?.phone}】进行后续操作</span>
                        </div>
                    }
                </div>
                <div className="floor">
                    <div className="line">
                        <span className="label">退款明细</span>
                    </div>
                    <div className="line">
                        <span className="label">退款金额：</span>
                        <span className="value">
                            {store.order?.money && showPrice(store.order?.money)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default inject('afterSale')(observer(Address))

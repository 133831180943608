import React from 'react'

import './stop-pay.scss'
import { preventAndStopPropagation } from 'utils/doms'

const StopPay = (props) => {
  const { continuePay, goToOrder } = props
  return (
    <div className='stop-pay' onMouseMove={preventAndStopPropagation}>
      <div className='stop-pay-content'>
        <div>确认要退出支付？</div>
        <div>您的订单在23小时59分钟内未支付将被取消,请尽快完成支付。</div>
        <div>
          <div onClick={continuePay || (() => {})}>继续支付</div>
          <div onClick={goToOrder || (() => {})}>前往订单</div>
        </div>
      </div>
    </div>
  )
}

export default StopPay

import React, { useEffect } from "react";
import "./select.scss";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import reg from "utils/reg";

function Select(props) {
  const { search: store } = props;
  const [smallPricePlaceholder, setSmallPricePlaceholder] = useState("最低价");
  const [bigPricePlaceholder, setBigPricePlaceholder] = useState("最高价");
  const [smallPrice, setSmallPrice] = useState(store.fromPrice);
  const [bigPrice, setBigPrice] = useState(store.toPrice);

  // useEffect(() => {
  //   store.fetchCategoryList();
  // }, []);

  function renderCategoryList() {
    return store.categoryList.filter(item=>item.optName).map((item, i) => {
      const { id, optId, optName, isSelf } = item;

      return (
        <div
          className={`item ${store.currentCategory.id === id && "active"}`}
          key={id}
          onClick={() => {
            if (store.currentCategory.id === id) {
              // 取消选择
              store.currentCategory = {
                id: -1,
                optId: -1,
                isSelf: false
              };
            } else {
              // 选中
              store.currentCategory = {
                id,
                optId,
                isSelf,
                optName
              };
            }
          }}
        >
          {optName}
        </div>
      );
    });
  }
  function renderChildCategoryList() {
    return store.childCategoryList.filter(item=>item.optName).map((item, i) => {
      const { id, optId, optName, isSelf } = item;

      return (
        <div
          className={`item ${store.currentChildCategory.id === id && "active"}`}
          key={id}
          onClick={() => {
            if (store.currentChildCategory.id === id) {
              store.currentChildCategory = {
                id: -1,
                optId: -1,
                isSelf: false
              };
            } else {
              store.currentChildCategory = {
                id,
                optId,
                isSelf,
                optName
              };
            }
          }}
        >
          {optName}
        </div>
      );
    });
  }
  return (
    <div className="select">
      <div className="category-container">
        <div className="title-floor">分类</div>
        <div className="content-floor">{renderCategoryList()}</div>
      </div>
      {store.childCategoryList.length > 0 && (
        <div className="category-container">
          <div className="title-floor">子分类</div>

          <div className="content-floor">{renderChildCategoryList()}</div>
        </div>
      )}
      <div className="price-container">
        <div className="title-floor">价格区间</div>
        <div className="content-floor">
          <input
            type="text"
            className="price-input"
            placeholder={smallPricePlaceholder}
            onChange={e => {
              setSmallPrice(e.currentTarget.value);
            }}
            onFocus={() => {
              setSmallPricePlaceholder("");
            }}
            onBlur={() => {
              setSmallPricePlaceholder("最低价");
              if (!smallPrice || !reg.isNum(smallPrice)) {
                setSmallPrice(store.fromPrice);
              } else {
                store.fromPrice = Number(smallPrice);
              }
            }}
            value={smallPrice}
          />
          <div className="split-line"></div>
          <input
            type="text"
            className="price-input"
            placeholder={bigPricePlaceholder}
            onChange={e => {
              setBigPrice(e.currentTarget.value);
            }}
            onFocus={() => {
              setBigPricePlaceholder("");
            }}
            onBlur={() => {
              setBigPricePlaceholder("最高价");

              if (!bigPrice || !reg.isNum(bigPrice)) {
                setBigPrice(store.bigPrice);
              } else {
                store.toPrice = Number(bigPrice);
              }
            }}
            value={bigPrice}
          />
        </div>
      </div>
    </div>
  );
}

export default inject("search")(observer(Select));

import React, {useCallback, useEffect, useState} from 'react'
import 'styles/order.scss'
import {inject, observer} from 'mobx-react'
import {useParams, useHistory} from 'react-router-dom'
import OrderHeader from 'partials/order/order-header/OrderHeader'
import OrderDeliver from 'partials/order/order-deliver/OrderDeliver'
import OrderGoods from 'partials/order/order-goods/OrderGoods'
import OrderDetail from 'partials/order/order-detail/OrderDetail'
import OrderOperate from 'partials/order/order-operate/OrderOperate'
import * as api from 'utils/api/api';
import useQuery from "hooks/useQuery";
import axios from "axios";
import PayTypeEnum from "enum/PayTypeEnum";
import isWeChat from "utils/isWeChat";
import myLog from "utils/myLog";

const Order = ({order: store, orderPay, constant}) => {

    const params = useParams();
    const history = useHistory();
    const query = useQuery();
    const [payResultBody, setPayResultBody] = useState(null);

    const fetchInfo = useCallback(() => {
        const id = Number(params.id);
        if (!id) {
            return;
        }
        if (query.state === "queryPay") {
            return;
        }

        api.orderInfo({id}).then(info => {
            const {
                sn, price, status, createTime, delivery, orderAddress, orderProductList, payType
            } = info;

            store.id = id;
            store.sn = sn;
            store.price = price;
            store.status = status;
            store.createTime = createTime;
            store.delivery = delivery;
            store.orderAddress = orderAddress;
            store.orderProductList = orderProductList;
            store.payType = payType;
        });


    }, [params.id, query.state]);

    useEffect(() => {
        fetchInfo();
    }, [fetchInfo])

    useEffect(() => {
        const id = Number(params.id);

        if (query.state !== 'queryPay') {
            return;
        }

        if (!id) {
            return;
        }

        api.queryPay({orderId: id}).then(data => {
            if (!data.success) {
                history.push("/pay/fail");
            } else {
                history.push("/pay/success");
            }
        })
    }, [query.state, params.id]);

    /*
    * 查询openId
    * */
    useEffect(() => {
        if (!query.state && query.state !== 0) {
            return;
        }

        if (!store.currentPayTypeId || !store.price || !store.sn) {
            return;
        }

        if (query.state === "queryOpenId") {
            api.getOpenId({code: query.code}).then(data => {
                const {openId} = data;
                myLog("openId:" + openId)

                api.pay({
                    price: store.price,
                    orderSn: store.sn,
                    payTypeId: store.currentPayTypeId,
                    openId
                }).then((data) => {
                    myLog("发起支付成功")

                    const {
                        appId,
                        timeStamp,
                        nonceStr,
                        package: packAge,
                        signType,
                        paySign
                    } = data;

                    myLog("paySign:" + appId);
                    myLog("timeStamp" + timeStamp);
                    myLog("nonceStr" + nonceStr);
                    myLog("packAge" + packAge);
                    myLog("signType" + signType);
                    myLog("paySign" + paySign);

                    api.jsApi({
                        appId,
                        timeStamp,
                        nonceStr,
                        packAge,
                        signType,
                        paySign
                    });

                }).catch(error => {
                    myLog("发起支付失败")
                });
            }).error(error => {
                myLog("获取openId失败")
                myLog(error || error.response.data || error.message);
            })
        }
    }, [query.code, query.state, store.currentPayTypeId, store.price, store.sn]);

    const pay = () => {
        if (isWeChat()) {
            api.appId({payTypeId: PayTypeEnum.WE_CHAT.value})
                .then(data => {
                    myLog("请求appId成功")
                    myLog("appId:" + data.appId);

                    const appId = data.appId;
                    const redirectUrl = encodeURIComponent(`${window.location.href}`)
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize
                        ?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code
                        &scope=snsapi_base&state=queryOpenId#wechat_redirect`;
                })
                .catch(error => {
                    myLog("请求appId失败")
                    myLog(error.response.data || error.message);
                })
        } else {
            api.pay({
                price: store.price,
                orderSn: store.sn,
                payTypeId: store.currentPayTypeId
            }).then(data => {
                if (store.currentPayTypeId === PayTypeEnum.A_LI_PAY.value) {
                    const {body} = data;
                    setPayResultBody(body);
                    const timer = setInterval(() => {
                        let dom = document.getElementById("bestPayForm");
                        if (!dom) {
                            return;
                        }

                        dom.submit();
                        clearInterval(timer);
                    }, 200)
                } else if (store.currentPayTypeId === PayTypeEnum.WE_CHAT.value) {
                    if (data.mwebUrl) {
                        const redirectUrl = encodeURIComponent(`${window.location.href}?state=queryPay`);
                        window.location.href = `${data.mwebUrl}&redirect_url=${redirectUrl}`;
                    }
                }
            });
        }

    }

    useEffect(() => {
        constant.fetchJson();
    }, []);

    return (
        <div className='glx-order'>
            <OrderHeader fetchInfo={fetchInfo} pay={pay}/>
            <div className='order-body'>
                <OrderDeliver fetchInfo={fetchInfo}/>
                {
                    store.orderProductList.map((item => {
                        if (!item.shopName) {
                            item.shopName = constant.app.websiteName;
                            item.isSelf = true;
                        }

                        return <OrderGoods key={item.id} orderSku={item}/>;
                    }))
                }
                <OrderDetail/>
                <OrderOperate fetchInfo={fetchInfo} pay={pay}/>
            </div>
            {payResultBody && <div dangerouslySetInnerHTML={{__html: payResultBody}}/>}
        </div>
    )
}

export default inject('order', 'orderPay', 'constant')(observer(Order))

import {observable, action, makeAutoObservable} from 'mobx';

class Login {
  @observable _username = '';
  @observable _password = '';
  @observable _usernameFocus;
  @observable _passwordFocus = false;

  constructor() {
    makeAutoObservable(this)
  }
  get username() {
    return this._username;
  }
  set username(value) {
    this._username = value;
  }
  get password() {
    return this._password;
  }
  set password(value) {
    this._password = value;
  }
  get usernameFocus() {
    return this._usernameFocus;
  }
  set usernameFocus(value) {
    this._usernameFocus = value;
  }
  get passwordFocus() {
    return this._passwordFocus;
  }
  set passwordFocus(value) {
    this._passwordFocus = value;
  }
}

let store = new Login();

export default store;

import React, {useCallback, useMemo} from 'react'

import BackIcon from 'images/orders/back.png'

import './orders-header.scss'
import {NavLink, useHistory} from 'react-router-dom'
import OrderStatusEnum from "enum/OrderStatusEnum";
import useQuery from "hooks/useQuery";

const OrdersHeader = () => {
    // const isActive = (type) => activeType == type
    const history = useHistory()
    const goBack = () => history.goBack()
    const toTypeFunc = (type) => () => history.push('/orders/' + type)

    const query = useQuery();

    const isActive = useCallback((value) => {
        return query.status === value;
    }, [query.status])

    const isAll = useMemo(() => {
        return query.status !== 0 && !query.status;
    }, [query.status])

    return (
        <div className='orders-header'>
            <div className='orders-title'>
                <img
                    className='orders-title__back-icon'
                    src={BackIcon}
                    onClick={goBack}
                />
                <span className='orders-title__title'>我的订单</span>
            </div>
            <div className='orders-tabs'>
                <NavLink
                    className={`orders-tabs__tab${
                        isAll ? ' orders-tabs__tab--active' : ''
                    }`}
                    key='all'
                    to='/orders'
                >
                    <span>全部</span>
                </NavLink>
                {Object.keys(OrderStatusEnum)
                    .map(key => OrderStatusEnum[key])
                    .filter(({value}) => value !== OrderStatusEnum.CANCELED.value
                        && value !== OrderStatusEnum.WAIT_DELIVER.value)
                    .map(({value, label}) => (
                        <NavLink
                            className={`orders-tabs__tab${
                                isActive(value) ? ' orders-tabs__tab--active' : ''
                            }`}
                            key={value}
                            to={`/orders?status=${value}`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.replace(`/orders?status=${value}`);
                            }}
                        >
                            <span>{label}</span>
                        </NavLink>
                    ))}
            </div>
        </div>
    )
}

export default OrdersHeader;

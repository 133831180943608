import React, {useCallback, useEffect} from 'react'
import {inject, observer} from 'mobx-react'

import 'styles/orders.scss'
import OrdersHeader from 'partials/orders/orders-header/OrdersHeader'
import OrdersPlaceholder from 'partials/orders/orders-placeholder/OrdersPlaceholder'
import OrdersItem from 'partials/orders/orders-item/OrdersItem'
import OrdersRecommend from 'partials/orders/orders-recommend/OrdersRecommend'
import OrdersDeliver from 'partials/orders/orders-deliver/OrdersDeliver'
import useQuery from "hooks/useQuery";
import * as api from 'utils/api/api';
import {reaction} from "mobx";

const Orders = ({orderList: store}) => {

    const query = useQuery();

    const fetchList = useCallback(() => {
        const {status} = query;
        const {pageSize, pageNum} = store;

        api.orderList({pageSize, pageNum, status}).then(({list}) => {
            store.list = list;
        })
    }, [query.status]);

    reaction(() => store.list, list => {
        if (!list) {
            return;
        }

        list.forEach(({id}) => {
            store.showDeliveryMap.set(id, false);
        });
    });

    useEffect(() => {
        fetchList();
    }, [fetchList]);

    return (
        <div className='orders'>
            <OrdersHeader/>

            {store.list?.length ? (
                store.list?.map((item) => <OrdersItem order={item} key={item.id} fetchList={fetchList}/>)
            ) : (
                <OrdersPlaceholder/>
            )}
            {store.list?.length ? '' : <OrdersRecommend/>}
            {store.ifShowDeliveryModal && (
                <OrdersDeliver/>
            )}
        </div>
    )
}

export default inject('orderList')(observer(Orders))

import React from 'react';
import './tab.scss';
import { useHistory, NavLink } from 'react-router-dom';

const Tab = props => {
  const { currentIndex, store } = props;

  let history = useHistory();

  function onClickDefault() {
    store.sortField = undefined;
    store.order = undefined;
  }

  function onClickPrice() {
    if (store.sortField !== store.SortField.CURRENT_PRICE) {
      store.sortField = store.SortField.CURRENT_PRICE;
      store.order = store.Order.ASC;
    } else {
      if (store.order === store.Order.ASC) {
        store.order = store.Order.DESC;
      } else {
        store.order = store.Order.ASC;
      }
    }
  }

  function onClickSoldNum() {
    if (store.sortField !== store.SortField.SOLD_NUM) {
      store.sortField = store.SortField.SOLD_NUM;
      store.order = store.Order.ASC;
    } else {
      if (store.order === store.Order.ASC) {
        store.order = store.Order.DESC;
      } else {
        store.order = store.Order.ASC;
      }
    }
  }

  function renderPriceText() {
    if (store.sortField === store.SortField.CURRENT_PRICE) {
      if (store.order === store.Order.ASC) {
        return '价格升序';
      } else {
        return '价格降序';
      }
    } else {
      return '价格';
    }
  }

  function renderSoldNumText() {
    if (store.sortField === store.SortField.SOLD_NUM) {
      if (store.order === store.Order.ASC) {
        return '销量升序';
      } else {
        return '销量降序';
      }
    } else {
      return '销量';
    }
  }

  return (
    <div className='tab'>
      <div className='tab-bar-floor'>
        <div
          className='back'
          onClick={() => {
            history.goBack();
          }}
        />
        <NavLink
          className={`item ${currentIndex === 0 && 'active'}`}
          to='/my/collect/goods'
          replace
        >
          <div className='text'>商品</div>
        </NavLink>
        <NavLink
          className={`item ${currentIndex === 1 && 'active'}`}
          to='/my/collect/shop'
          replace
        >
          <div className='text'>店铺</div>
        </NavLink>
      </div>
      <div className='condition-floor'>
        <div
          className={`item ${store.sortField !==
            store.SortField.CURRENT_PRICE &&
            store.sortField !== store.SortField.SOLD_NUM &&
            'active'}`}
          onClick={onClickDefault}
        >
          <div className='text'>默认</div>
        </div>
        <div
          className={`item ${store.sortField ===
            store.SortField.CURRENT_PRICE && 'active'}`}
          onClick={onClickPrice}
        >
          <div className='text'>{renderPriceText()}</div>
        </div>
        <div
          className={`item ${store.sortField === store.SortField.SOLD_NUM &&
            'active'}`}
          onClick={onClickSoldNum}
        >
          <div className='text'>{renderSoldNumText()}</div>
        </div>
      </div>
    </div>
  );
};

export default Tab;

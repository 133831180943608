const AdvImgType = {
    // 首页轮播
    web_home_carousel:'web_home_carousel',

    // 首页广告1
    web_home_adv_1:'web_home_adv_1',
    // 首页广告2
    web_home_adv_2:'web_home_adv_2',

    // 首页自营广告1
    web_home_self_adv_1:'web_home_self_adv_1',
    // 首页自营广告2
    web_home_self_adv_2:'web_home_self_adv_2',
    // 首页自营广告3
    web_home_self_adv_3:'web_home_self_adv_3',

    // 首页楼层广告1
    web_home_floor_adv_1:'web_home_floor_adv_1',
    // 首页楼层广告2
    web_home_floor_adv_2:'web_home_floor_adv_2',

    // 移动端首页轮播
    app_home_carousel:'app_home_carousel',

    // 移动端首页胶囊
    app_home_capsule:'app_home_capsule',

    // 移动端楼层广告
    app_home_floor_adv_1:'app_home_floor_adv_1'
}

export default AdvImgType
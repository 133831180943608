const tabs = [
  {
    name: '精选',
    keyword: 'recommend'
  },
  {
    name: '美食',
    keyword: 'food'
  },
  {
    name: '运动',
    keyword: 'sport'
  },
  {
    name: '旅游',
    keyword: 'travel'
  },
  {
    name: '科学',
    keyword: 'science'
  },
  {
    keyword: 'entertainment',
    name: '娱乐'
  },
  {
    keyword: 'data',
    name: '数码'
  }
]

export default tabs

import React from 'react'
import './my-orders.scss'
import {inject, observer} from 'mobx-react'
import {NavLink} from 'react-router-dom'
import {ArrowRightOutlined} from '@ant-design/icons'
import OrderStatusEnum from "enum/OrderStatusEnum";
import WaitPayIcon from './img/wait-pay.png';
import WaitReceiveIcon from './img/wait-receive.png';
import HasCompleteIcon from './img/has-complete.png';
import AfterSaleIcon from './img/after-sale.png';

const getIcon = (status) => {
    switch (status) {
        case OrderStatusEnum.WAIT_PAY.value:
            return WaitPayIcon;
        case OrderStatusEnum.HAS_DELIVERED.value:
            return WaitReceiveIcon;
        case OrderStatusEnum.HAS_COMPLETED.value:
            return HasCompleteIcon;
        case OrderStatusEnum.AFTER_SALE.value:
            return AfterSaleIcon;
        default:
            return "";
    }
}

const MyEntries = ({app}) => {

    return (
        <NavLink className='my-orders' to={'/orders'}>
            <div className='my-orders-title'>
                <span className='my-orders-title__text'>我的订单</span>
                <ArrowRightOutlined/>
            </div>
            <div className='my-orders-entries'>
                {Object.keys(OrderStatusEnum)
                    .map(key => OrderStatusEnum[key])
                    .filter(({value}) => value !== OrderStatusEnum.CANCELED.value
                        && value !== OrderStatusEnum.WAIT_DELIVER.value)
                    .map(({value, label}, idx) => {
                        return (
                            <NavLink
                                to={`/orders?status=${value}`}
                                key={idx}
                                onClick={(e) => {
                                    if (!app.hasLogin) {
                                        e.preventDefault()
                                    }
                                }}
                            >
                                <div className='my-entry'>
                                    <img
                                        src={getIcon(value)}
                                        className={`my-entry__img${!app.hasLogin ? '--guest' : ''}`}
                                        alt=''
                                    />
                                    <span
                                        className={`my-entry__text${!app.hasLogin ? '--guest' : ''}`}
                                    >{label}</span>
                                </div>
                            </NavLink>
                        )
                    })}
            </div>
        </NavLink>
    )
}

export default inject('app')(observer(MyEntries))

import React, { useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter, useParams } from 'react-router-dom';

import BannerTemp from 'images/shops/banner-temp.jpg';
import BackIcon from 'images/common/back.png';

import 'styles/shop.scss';
import Back from 'components/common/back/Back';
import SearchInput from 'components/search/search-input/SearchInput';
import ProductCard from 'components/home/product-card/ProductCard';
import ShopTitle from 'partials/shop/shop-title/ShopTitle';
import { useEffect } from 'react';
import useQuery from 'hooks/useQuery';

const Shop = props => {
  const { shop: store } = props;
  let params = useParams();
  let query = useQuery();

  useEffect(() => {
    store.shopId = Number(params.id);
    store.type = Number(query.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, query.type]);

  /**
   * 获取店铺详情
   */
  const fetchInfo = useCallback(() => {
    store.fetchInfo();
  }, []);
  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  return (
    <div className='glx-shop'>
      <div className='header-floor'>
        <Back className='back'>
          <img className='img' src={BackIcon} alt='' />
        </Back>
        <SearchInput placeHolder='查找店内好货' />
      </div>
      <ShopTitle />
      <div className='banner-block'>
        <img className='banner' src={BannerTemp} alt='' />
      </div>
      <div className='shop-content'>
        {store.list.map((goods, idx) => (
          <ProductCard {...goods} showSoldNum={true} key={idx} />
        ))}
        <div className='shop-content__clear-float'>&nbsp; </div>
      </div>
    </div>
  );
};

export default inject('shop')(observer(Shop));

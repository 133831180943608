import TIM, {tim} from 'utils/tim'

/**
 * sdk ready
 * @param callback
 */
export const onTimReady = callback => {
    tim.on(TIM.EVENT.SDK_READY, () => {
        callback()
    })
}

/**
 * 群系统通知
 * @param callback
 */
export const onGroupSystemNoticeReceived = callback => {
    tim.on(TIM.EVENT.GROUP_SYSTEM_NOTICE_RECEIVED, e => {
        callback(e.data);
    });
}

/**
 * 接收到消息
 * @param callback
 */
export const onMessageReceived = callback => {
    tim.on(TIM.EVENT.MESSAGE_RECEIVED, e => {
        callback(e.data);
    });
}


import {
    DOMAIN,
    Header,
    ContentType,
    GetFormData,
    Method,
    http
} from './api-utils';
import PayTypeEnum from "enum/PayTypeEnum";
import * as antd from 'antd';

export const pay = ({price, orderSn, payTypeId, openId}) => {
    return http({
        url: DOMAIN + '/pay',
        method: Method.POST,
        params: {
            price,
            orderSn,
            payTypeId,
            openId
        }
    }).then(res => res.data);
}

export const jsApi = ({
                          appId,
                          timeStamp,
                          nonceStr,
                          packAge,
                          signType,
                          paySign,
                          onSuccess,
                          onFailed,
                          onCancel
                      }) => {
    function onBridgeReady() {
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
                appId,
                timeStamp,
                nonceStr,
                package: decodeURIComponent(packAge),
                signType,
                paySign
            },
            function (res) {
                if (res.err_msg === "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    if (onSuccess) {
                        onSuccess();
                    }else{
                        window.location.href = "/pay/success";
                    }
                } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                    if (onFailed) {
                        onFailed();
                    }else{
                        window.location.href = "/pay/fail";
                    }
                } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
                    if (onCancel) {
                        onCancel();
                    }else{

                    }
                }
            });
    }

    if (typeof window.WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
    } else {
        onBridgeReady();
    }
}

export const appId = ({payTypeId}) => {
    return http({
        url: `${DOMAIN}/pay/${payTypeId}/appId`,
        method: Method.GET
    }).then(res => res.data);
}

export const appSecret = ({payTypeId}) => {
    return http({
        method: Method.GET,
        url: `${DOMAIN}/pay/${payTypeId}/appSecret`,
    }).then(res => res.data);
}

export const getOpenId = ({code}) => {
    return http({
        method: Method.GET,
        url: DOMAIN + '/pay/wx-open-id',
        params: {
            code
        }
    }).then(res => res.data)
}

// export const getOpenId = ({appId, appSecret, code}) => {
//     return http({
//         method: Method.GET,
//         url: 'https://api.weixin.qq.com/sns/oauth2/access_token',
//         params: {
//             appid: appId,
//             secret: appSecret,
//             code,
//             grant_type: 'authorization_code'
//         }
//     })
// }

export const queryPay = ({orderId}) => {
    return http({
        url: DOMAIN + '/pay',
        params: {
            orderId
        }
    }).then(res => res.data)
}


import EnumUtil from "./EnumUtil";

const type = {
    WAIT_CHECK: {
        value: 1,
        label: '等待审核'
    },
    CHECKED: {
        value: 2,
        label: '审核通过'
    },
    REFUSED: {
        value: 3,
        label: '拒绝'
    },
    COMPLETE: {
        value: 4,
        label: '完成'
    }
}

type.getByValue = EnumUtil.getByValue.bind(type);
type.getLabelByValue = EnumUtil.getLabelByValue.bind(type);

export default type;
import React from 'react'
import 'styles/refund-apply.scss';
import {observer, inject} from 'mobx-react'
import Back from 'components/common/back/Back'
import {createForm} from 'rc-form';
import * as antdMobile from 'antd-mobile';
import {addAfterSale} from "utils/api/api";
import {useParams, useHistory} from 'react-router-dom';
import {message} from "antd";

const RefundApply = ({form}) => {

    const params = useParams();
    const orderId = Number(params.id);
    const history = useHistory();
    const {getFieldProps, getFieldError} = form;

    const onSubmit = () => {
        form.validateFields((errors, values) => {
            if (errors) {
                for(let key in errors){
                    message.error(errors[key].errors?.[0].message);
                    return;
                }
            }

            const {reason} = values;

            addAfterSale({orderId, reason}).then(() => {
                message.success("提交成功");
                history.goBack();
            })
        });
    }

    return (
        <div className='glx-refund-apply'>
            <Back>
                <div className="title">申请退款</div>
            </Back>
            <div className="form">
                <antdMobile.List>
                    <antdMobile.TextareaItem
                        {...getFieldProps('reason', {
                            rules: [
                                {required: true, message: '请填写退款原因'}
                            ]
                        })}
                        clear
                        title='退款原因'
                        placeholder="请输入退款原因"
                        rows={4}
                    />
                </antdMobile.List>
            </div>
            <div className="btn-floor">
                <button className="submit-btn" onClick={onSubmit}>申请退款</button>
            </div>
        </div>
    )
}

export default createForm()(inject('address')(observer(RefundApply)));

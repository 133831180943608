import {makeAutoObservable, observable} from "mobx";

class ModifyAddressStore {

    @observable _isDefault = false;
    @observable _provinceList = [];
    @observable _cityList = [];
    @observable _districtList = [];

    @observable _provinceId = 0;
    @observable _cityId = 0;
    @observable _districtId = 0;
    @observable _currentProvinceId = 0;
    @observable _currentCityId = 0;
    @observable _currentDistrictId = 0;

    constructor() {
        makeAutoObservable(this)
    }
    get isDefault() {
        return this._isDefault;
    }

    set isDefault(value) {
        this._isDefault = value;
    }

    get provinceList() {
        return this._provinceList;
    }

    set provinceList(value) {
        this._provinceList = value;
    }

    get cityList() {
        return this._cityList;
    }

    set cityList(value) {
        this._cityList = value;
    }

    get districtList() {
        return this._districtList;
    }

    set districtList(value) {
        this._districtList = value;
    }

    get provinceId() {
        return this._provinceId;
    }

    set provinceId(value) {
        this._provinceId = value;
    }

    get cityId() {
        return this._cityId;
    }

    set cityId(value) {
        this._cityId = value;
    }

    get districtId() {
        return this._districtId;
    }

    set districtId(value) {
        this._districtId = value;
    }

    get currentProvinceId() {
        return this._currentProvinceId;
    }

    set currentProvinceId(value) {
        this._currentProvinceId = value;
    }

    get currentCityId() {
        return this._currentCityId;
    }

    set currentCityId(value) {
        this._currentCityId = value;
    }

    get currentDistrictId() {
        return this._currentDistrictId;
    }

    set currentDistrictId(value) {
        this._currentDistrictId = value;
    }
}

export default new ModifyAddressStore();
import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from './api-utils';

/**
 * 资讯类别列表
 * @param {*} param0
 */
export const newsCategoryList = async () => {
  const { data } = await http({
    method: Method.POST,
    url: DOMAIN + '/news/category/list',
    data: {
      //   name
    }
  });

  const list = data.msg.objectList.map(item => {
    const { id, name, netEase: isUrl } = item;

    return { id, name, isUrl };
  });

  const { totalNum } = data.msg;

  return {
    list,
    totalNum
  };
};

import {observable, action, makeAutoObservable} from 'mobx';
import * as Api from 'utils/api/api';

class Account {
  @observable _avatar = null;
  @observable _avatarUrl = '';
  @observable _nickname = '';
  @observable _gender = '';

  constructor() {
    makeAutoObservable(this)
  }
  get avatar() {
    return this._avatar;
  }
  set avatar(value) {
    this._avatar = value;
  }
  get avatarUrl() {
    return this._avatarUrl;
  }
  set avatarUrl(value) {
    this._avatarUrl = value;
  }
  get nickname() {
    return this._nickname;
  }
  set nickname(value) {
    this._nickname = value;
  }
  get gender() {
    return this._gender;
  }
  set gender(value) {
    this._gender = value;
  }

  /**
   * 修改昵称
   */
  @action async doModifyNickname() {
    try {
      const { nickname } = this;
      await Api.modifyUserInfo({
        nickname
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * 修改性别
   */
  @action async doModifyGender() {
    try {
      let { gender } = this;
      if (gender === 0) {
        gender = '男';
      } else {
        gender = '女';
      }
      await Api.modifyUserInfo({
        gender
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * 修改头像
   */
  @action async doModifyAvatar() {
    try {
      const { avatar } = this;
      await Api.modifyUserInfo({ avatar });
      return true;
    } catch (error) {
      return false;
    }
  }
}

const store = new Account();

export default store;

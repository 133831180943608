import React from 'react'

import './orders-recommend.scss'
import RecommendIcon from 'images/orders/recommend-icon.png'

import ProductCard from '../../../components/home/product-card/ProductCard'
import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
const OrdersRecommend = (props) => {
  const { my: store } = props
  useEffect(() => {
    store.recommendListStore.list.length || store.fetchRecommendList()
  }, [])
  return (
    <div className='my-recommend'>
      <div className='my-recommend__title'>
        <img className='my-recommend__icon' src={RecommendIcon} />
        <span>为你推荐</span>
      </div>
      <div className='my-recommend__content'>
        {store.recommendListStore.list.map((item, idx) => {
          return <ProductCard {...item} key={idx} />
        })}
      </div>
      <div className='my-recommend__placeholder'></div>
    </div>
  )
}

export default inject('my')(observer(OrdersRecommend))

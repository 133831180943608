import React, { useState } from 'react';

import 'styles/register.scss';
import { inject, observer } from 'mobx-react';
import * as antd from 'antd';
import { useHistory } from 'react-router-dom';

const BgImg = require('images/register/bg.png');
const InputBg = require('images/register/input-bg.png');
const InputActiveBg = require('images/register/input-active-bg.png');
const VerifyInputBg = require('images/register/verify-input-bg.png');
const VerifyInputActiveBg = require('images/register/verify-input-active-bg.png');
const UsernameIcon = require('images/register/username.png');
const UsernameActiveIcon = require('images/register/username-active.png');
const PasswordIcon = require('images/register/password.png');
const PasswordActiveIcon = require('images/register/password-active.png');
const VerifyIcon = require('images/register/verify.png');
const VerifyActiveIcon = require('images/register/verify-active.png');
const EnterBtnBg = require('images/register/enter.png');
const Register = props => {
  const { register: store } = props;

  const history = useHistory();

  return (
    <div className='glx-register'>
      <img src={BgImg} alt='' className='bg' />
      <button
        className='login-btn'
        onClick={() => {
          history.replace({
            pathname: '/my/account/login'
          });
        }}
      >
        已有账号？去登录
      </button>
      <div className='title-text'>5G商城网</div>
      <div className='welcome-text'>欢迎您</div>
      <div className='login-text'>Register</div>

      <div className='card'>
        <div className='floor username'>
          <div className='input-wrapper'>
            <img
              src={store.usernameFocus ? InputActiveBg : InputBg}
              alt=''
              className='bg-img'
            />
            <img
              src={store.usernameFocus ? UsernameActiveIcon : UsernameIcon}
              alt=''
              className='icon username'
            />
            <div className={`label ${store.usernameFocus && 'active'}`}>
              用户名
            </div>
            <input
              type='text'
              className='input'
              value={store.username}
              onFocus={() => {
                store.usernameFocus = true;
              }}
              onBlur={() => {
                store.usernameFocus = false;
              }}
              onChange={e => {
                store.username = e.currentTarget.value;
              }}
            />
          </div>
        </div>
        {/*<div className='floor verify'>*/}
        {/*  <div className='input-wrapper'>*/}
        {/*    <img*/}
        {/*      src={store.verifyCodeFocus ? VerifyInputActiveBg : VerifyInputBg}*/}
        {/*      alt=''*/}
        {/*      className='bg-img'*/}
        {/*    />*/}
        {/*    <img*/}
        {/*      src={store.verifyCodeFocus ? VerifyActiveIcon : VerifyIcon}*/}
        {/*      alt=''*/}
        {/*      className='icon username'*/}
        {/*    />*/}
        {/*    <div className={`label ${store.verifyCodeFocus && 'active'}`}>*/}
        {/*      验证码*/}
        {/*    </div>*/}
        {/*    <input*/}
        {/*      type='text'*/}
        {/*      className='input'*/}
        {/*      value={store.verifyCode}*/}
        {/*      onFocus={() => {*/}
        {/*        store.verifyCodeFocus = true;*/}
        {/*      }}*/}
        {/*      onBlur={() => {*/}
        {/*        store.verifyCodeFocus = false;*/}
        {/*      }}*/}
        {/*      onChange={e => {*/}
        {/*        store.verifyCode = e.currentTarget.value;*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*  <div className='verify-img-block'>*/}
        {/*    <img*/}
        {/*      src={store.verifyImg}*/}
        {/*      alt=''*/}
        {/*      className='verify-img'*/}
        {/*      onClick={() => {*/}
        {/*        store.refreshVerifyImg();*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className='floor password-1'>
          <div className='input-wrapper'>
            <img
              src={store.password1Focus ? InputActiveBg : InputBg}
              alt=''
              className='bg-img'
            />
            <img
              src={store.password1Focus ? PasswordActiveIcon : PasswordIcon}
              alt=''
              className='icon username'
            />
            <div className={`label ${store.password1Focus && 'active'}`}>
              密码
            </div>
            <input
              type='password'
              className='input'
              value={store.password1}
              onFocus={() => {
                store.password1Focus = true;
              }}
              onBlur={() => {
                store.password1Focus = false;
              }}
              onChange={e => {
                store.password1 = e.currentTarget.value;
              }}
            />
          </div>
        </div>
        <div className='floor password-2'>
          <div className='input-wrapper'>
            <img
              src={store.password2Focus ? InputActiveBg : InputBg}
              alt=''
              className='bg-img'
            />
            <img
              src={store.password2Focus ? PasswordActiveIcon : PasswordIcon}
              alt=''
              className='icon username'
            />
            <div className={`label ${store.password2Focus && 'active'}`}>
              确认密码
            </div>
            <input
              type='password'
              className='input'
              value={store.password2}
              onFocus={() => {
                store.password2Focus = true;
              }}
              onBlur={() => {
                store.password2Focus = false;
              }}
              onChange={e => {
                store.password2 = e.currentTarget.value;
              }}
            />
          </div>
        </div>

        <div className='floor'>
          <button
            className='enter-btn'
            onClick={async () => {
              if (await store.doRegister()) {
                antd.message.success('注册成功');

                history.replace({
                  pathname: '/home'
                });
              }
            }}
          >
            <img src={EnterBtnBg} alt='' className='bg-img' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default inject('register')(observer(Register));

import React, { useState, createRef, useEffect } from 'react';
import './css/upload-img.scss';

function UploadImg(props) {
  const { setImg, src, className, text } = props;
  const [imgSrc, setImgSrc] = useState('');
  const [loading, setLoading] = useState(false);

  const fileRef = createRef(null);

  const MAX_SIZE = 2;

  useEffect(() => {
    if (src) {
      setImgSrc(src);
    } else {
      setImgSrc('');
    }
  }, [src]);

  const uploadButton = (
    <div className='upload-button'>
      <div className='text'>{text}</div>
    </div>
  );

  function onClick() {
    fileRef.current.dispatchEvent(new MouseEvent('click'));
  }

  function onChange() {
    const { files } = fileRef.current;
    if (!files || files.length === 0) {
      return;
    }

    let img = files[0];
    setImg(img);

    const imgSrc = URL.createObjectURL(img);
    setImgSrc(imgSrc);
  }

  return (
    <div
      className={`glx-merchant-add-license-upload-img ${className}`}
      onClick={onClick}
    >
      <input
        className='file'
        ref={fileRef}
        type='file'
        // accept='image/png, image/jpeg'
        accept='image/*'
        onChange={onChange}
      />
      {uploadButton}
    </div>
  );
}

export default UploadImg;

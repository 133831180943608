import {observable, action, computed, makeAutoObservable} from 'mobx';
import * as Api from 'utils/api/api';
import * as Bean from './bean';

class Shop2 {
  @observable _shopTypeList = [];
  @observable _categoryList = [];
  @observable _childCategoryList = [];
  @observable _shopType = null;
  @observable _mainCategory = null;
  @observable _selectedCategoryId = 0;
  @observable _selectedChildCategoryMap = new Map();
  @observable _hasLicense = null;
  @observable _licenseMap = new Map();

  constructor() {
    makeAutoObservable(this)
  }
  get shopTypeList() {
    return this._shopTypeList;
  }
  set shopTypeList(value) {
    this._shopTypeList = value;
  }
  get categoryList() {
    return this._categoryList;
  }
  set categoryList(value) {
    this._categoryList = value;
  }
  get childCategoryList() {
    return this._childCategoryList;
  }
  set childCategoryList(value) {
    this._childCategoryList = value;
  }
  @computed get childCategoryListAllActive() {
    if (this._childCategoryList.length === 0) {
      return false;
    }

    return this._childCategoryList.every(item => {
      return item.active;
    });
  }
  set childCategoryListAllActive(value) {
    if (value) {
      this._childCategoryList.forEach(item => {
        item.active = true;
      });
    } else {
      this._childCategoryList.forEach(item => {
        item.active = false;
      });
    }
  }

  get shopType() {
    return this._shopType;
  }
  set shopType(value) {
    this._shopType = value;
  }
  @computed get shopTypeId() {
    if (!this._shopType) {
      return 0;
    } else {
      return this._shopType.id;
    }
  }
  get mainCategory() {
    return this._mainCategory;
  }
  set mainCategory(value) {
    this._mainCategory = value;
  }
  @computed get mainCategoryId() {
    if (!this._mainCategory) {
      return 0;
    } else {
      return this._mainCategory.id;
    }
  }
  get selectedCategoryId() {
    return this._selectedCategoryId;
  }
  set selectedCategoryId(value) {
    this._selectedCategoryId = value;
  }
  get _selectedChildCategoryMap() {
    return this._selectedChildCategoryMap;
  }
  @computed get selectedCategoryListData() {
    let list = [];

    for (const [parentId, categoryList] of this._selectedChildCategoryMap) {
      list.push({
        levelOneOptId: parentId,
        levelTwoOptIds: categoryList
          .filter(item => item.active)
          .map(item => item.id)
      });
    }

    return JSON.stringify(list);
  }
  set _selectedChildCategoryMap(value) {
    this._selectedChildCategoryMap = value;
  }
  @computed get selectedCategoryList() {
    let list = [];

    for (const [parentId, categoryList] of this._selectedChildCategoryMap) {
      list.push(
        ...categoryList
          .filter(item => item.active)
          .map(item => ({ ...item, parentId }))
      );
    }

    return list;
  }

  get hasLicense() {
    return this._hasLicense;
  }
  set hasLicense(value) {
    this._hasLicense = value;
  }
  get licenseMap() {
    return this._licenseMap;
  }
  set licenseMap(value) {
    this._licenseMap = value;
  }
  @computed get licenseListData() {
    let list = [];

    for (let [categoryId, license] of this._licenseMap) {
      const { picture, endDate, isInfinite } = license;
      list.push({
        categoryId,
        qualificationPic: picture,
        maturitydate: isInfinite ? -1 : endDate.valueOf()
      });
    }

    return list;
  }

  @action async fetchShopTypeList() {
    try {
      const { list } = await Api.shopTypeList();
      this._shopTypeList = list.map(item => {
        const { id, shopType: value } = item;

        return {
          id,
          value
        };
      });
    } catch (error) {}
  }

  @action selectShopType(id) {
    this._shopType = this._shopTypeList.find(item => item.id === id);
  }

  @action async fetchCategoryList() {
    try {
      const { list } = await Api.pddMainGoodsCategoryList();

      this._categoryList = list.map(item => {
        const { id, name: value, level, icon, iconActive } = item;

        return {
          id,
          value
        };
      });
    } catch (error) {}
  }

  @action async fetchChildCategoryList({ parentId }) {
    try {
      let parentValue = this._categoryList.find(item => item.id === parentId)
        .value;

      const { list } = await Api.pddChildGoodsCategoryList({ parentId });

      if (this._selectedChildCategoryMap.has(parentId)) {
        this._childCategoryList = this._selectedChildCategoryMap.get(parentId);
      } else {
        this._childCategoryList = list.map(item => {
          const { id, optName: value } = item;

          return {
            id,
            value,
            active: false,
            parentValue
          };
        });
      }
    } catch (error) {}
  }

  @action async fetchData() {
    this.fetchShopTypeList();
    this.fetchCategoryList();
  }

  @action selectChildCategory({ parentId, childList }) {
    this._selectedChildCategoryMap.set(parentId, childList);

    //更新 license map
    if (childList.filter(item => item.active).length === 0) {
      this._licenseMap.delete(parentId);
    } else if (!this._licenseMap.has(parentId)) {
      this._licenseMap.set(
        parentId,
        new Bean.License(childList[0].parentValue)
      );
    }
  }

  @action deleteChildCategory({ parentId, id }) {
    this._selectedChildCategoryMap.get(parentId).forEach(item => {
      if (item.id === id) {
        item.active = false;
        return;
      }
    });

    //删除对应的license
    if (
      this._selectedChildCategoryMap
        .get(parentId)
        .every(item => item.active === false)
    ) {
      this._licenseMap.delete(parentId);
    }
  }
}

let shop2 = new Shop2();

export default shop2;

const EnumUtil = {
    getByValue(value) {
        for (let k in this) {
            if (this[k].value === value) {
                return this[k];
            }
        }

        return null;
    },
    getLabelByValue(value) {
        const type = this.getByValue(value);
        if (type === null) {
            return "类型错误！";
        }

        return type.label;
    }
}

export default EnumUtil;
import {useEffect} from "react";
import * as Api from "utils/api/api";

function useFetchLiveRecord({liveId, setLiveRecord, setLiveGoodsList}) {
    useEffect(() => {
        if (!liveId) {
            return;
        }
        console.log(`liveIdddddddddddd:${liveId}`)

        Api.currentLiveRecord({liveId}).then(data => {
            setLiveRecord(data.liveRecord)
            setLiveGoodsList(data.liveGoodsList)
        })
    }, [liveId])

}

export default useFetchLiveRecord;
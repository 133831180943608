import {observable, action, computed, makeAutoObservable} from 'mobx';
import * as Api from 'utils/api/api';
import * as antd from 'antd';
import RecommendListStore from 'stores/RecommendListStore';

class ShopCollect {
  @observable.ref SortField = {
    CURRENT_PRICE: 'currentPrice',
    SOLD_NUM: 'soldNum'
  };
  @observable.ref Order = {
    ASC: 'asc',
    DESC: 'desc'
  };
  @observable _list = [];
  @observable _pageSize = 1000;
  @observable _pageNum = 1;
  @observable _order = undefined;
  @observable _sortField = undefined;
  @observable.ref _recommendListStore = new RecommendListStore();

  constructor() {
    makeAutoObservable(this)
  }
  get list() {
    return this._list;
  }
  set list(value) {
    this._list = value;
  }
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value) {
    this._pageSize = value;
  }
  get pageNum() {
    return this._pageNum;
  }
  set pageNum(value) {
    this._pageNum = value;
  }
  get order() {
    return this._order;
  }
  set order(value) {
    this._order = value;
  }
  get sortField() {
    return this._sortField;
  }
  set sortField(value) {
    this._sortField = value;
  }
  get recommendListStore() {
    return this._recommendListStore;
  }
  set recommendListStore(value) {
    this._recommendListStore = value;
  }

  @action async fetchRecommendList() {
    this._recommendListStore.fetchList();
  }

  @action async fetchList() {
    try {
      const { pageSize, pageNum, order, sortField } = this;

      const { list } = await Api.shopCollectList({
        pageSize,
        pageNum,
        order,
        sortField
      });

      this._list = list;
    } catch (error) {}
  }

  /**
   * 删除
   * @param {*} id 
   */
  @action async doDelete(id) {
    try {
      await Api.cancelCollect({
        collectId: id
      });
      antd.message.success('删除成功!');

      await this.fetchList();
    } catch (error) {}
  }
}

const store = new ShopCollect();

export default store;

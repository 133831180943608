import React, {useEffect, useCallback} from "react";
import "styles/search-result.scss";
import {inject, observer} from "mobx-react";
import * as antd from "antd";
import InputFloor from "partials/search-result/input-floor/InputFloor";
import Condition from "partials/search-result/condition/Condition";
import GoodsList from "partials/search-result/goods-list/GoodsList";
import Select from "partials/search-result/select/Select";
import RecommendWordList from "partials/search/recommend-word-list/RecommendWordList";
import useQuery from "hooks/useQuery";

function SearchResult(props) {
    const {search: store} = props;
    let query = useQuery();


    /**
     * 初始化
     */
    useEffect(() => {
        if (query.keyword) {
            store.keyword = query.keyword;
        }
    }, []);

    /**
     * 搜索函数
     */
    const doSearch = useCallback(() => {
        store.fetchGoodsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // store,
        store.categoryId,
        store.isNew,
        store.isHot,
        store.order,
        store.sortFiled,
        store.realFromPrice,
        store.realToPrice,
        store.pageSize,
        store.pageNum,
        store.isSpecialPrice,
        store.hasSelfCategory
    ]);

    useEffect(() => {
        store.fetchCategoryList();
    }, [])

    /**
     * 执行搜索
     */
    useEffect(() => {
        doSearch();
    }, [doSearch]);

    return (
        <div className="search-result">
            <div className="head">
                <InputFloor/>
                {!store.showRecommendWordList && <Condition/>}
            </div>
            {store.showRecommendWordList ? (
                <RecommendWordList/>
            ) : (
                <div className="goods-list-container">
                    <GoodsList/>
                </div>
            )}

            <antd.Drawer
                className="select-drawer"
                placement="right"
                closable={false}
                maskClosable={true}
                onClose={() => {
                    store.showSelect = false;
                }}
                visible={store.showSelect}
                getContainer={false}
            >
                <Select/>
            </antd.Drawer>
        </div>
    );
}

export default inject("search")(observer(SearchResult));

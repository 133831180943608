import React, {useState} from "react";
import {observer} from "mobx-react";
import css from './imUserListPanel.module.scss'
import classNames from "classnames";

const Index = ({userList, onClose, defaultAvatar}) => {

    const [curIndex, setCurIndex] = useState(0)

    return (
        <div className={css.imUserListPanel} onClick={onClose}>
            <div className={css.card} onClick={e => e.stopPropagation()}>

                <div className={css.titleFloor}>
                    <span className={css.title}>
                        {`在线用户(${userList.length})`}
                    </span>
                </div>

                <div className={css.userList}>
                    {
                        userList.map((item, i) => {

                            const {id, nickname, avatar} = item;

                            const active = i === curIndex

                            return (
                                <div
                                    key={id}
                                    className={classNames(css.user, {
                                        [css.active]: active
                                    })}
                                    onClick={() => setCurIndex(i)}
                                >
                                    <img src={avatar || defaultAvatar} alt="" className={css.avatar}/>
                                    <div className={css.nickname}>{nickname}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(Index)
import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from './api-utils';

/**
 * 帮助中心菜单列表
 */
export const helpMenuList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/help/center/index/category/article'
  });

  const list = data.msg;

  return { list };
};

/**
 * 帮助中心详情
 */
export const helpInfo = async ({ id }) => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/help/center/article/info',
    params: {
      id
    }
  });

  const info = data.msg;

  return { info };
};

import {
  DOMAIN,
  Header,
  ContentType,
  GetFormData,
  Method,
  http
} from './api-utils';

export const askQuestion = async ({ question }) => {
  const { data } = await http({
    method: Method.GET,
    url: DOMAIN + '/intelligent/question/info',
    params: {
      question
    }
  });

  const { answer } = data;

  return {
    answer
  };
};

import {action, makeAutoObservable, observable} from 'mobx'

class LookAround {
    @observable _list = [];
    @observable _PAGE_SIZE = 6;
    @observable _pageSize = this._PAGE_SIZE;
    @observable _pageNum = 1;
    @observable _loading = false;

    constructor() {
        makeAutoObservable(this)
    }
    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }

    get PAGE_SIZE() {
        return this._PAGE_SIZE;
    }

    set PAGE_SIZE(value) {
        this._PAGE_SIZE = value;
    }

    get pageSize() {
        return this._pageSize;
    }

    set pageSize(value) {
        this._pageSize = value;
    }

    get pageNum() {
        return this._pageNum;
    }

    set pageNum(value) {
        this._pageNum = value;
    }

    get loading() {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
    }
}

class HomeStore {
    @observable _topBannerList = [];
    @observable _advList1 = [];
    @observable _swiperList1 = [];
    @observable _seckillList = [];
    @observable _newsList = [];
    @observable _categoryFloorList = [];
    @observable _recommendList = [];
    @observable lookAround = new LookAround();

    constructor() {
        makeAutoObservable(this)
    }
    @action
    modifyGoodsFloorList(floor) {
        const innerFloorList = [...this._categoryFloorList];
        for (let i = 0; i < innerFloorList.length; i++) {
            if (innerFloorList[i].id === floor.id) {
                innerFloorList[i] = {...innerFloorList[i],...floor};
            }
        }

        this._categoryFloorList = innerFloorList;
    }

    get topBannerList() {
        return this._topBannerList;
    }

    set topBannerList(value) {
        this._topBannerList = value;
    }

    get advList1() {
        return this._advList1;
    }

    set advList1(value) {
        this._advList1 = value;
    }

    get swiperList1() {
        return this._swiperList1;
    }

    set swiperList1(value) {
        this._swiperList1 = value;
    }

    get seckillList() {
        return this._seckillList;
    }

    set seckillList(value) {
        this._seckillList = value;
    }

    get newsList() {
        return this._newsList;
    }

    set newsList(value) {
        this._newsList = value;
    }

    get categoryFloorList() {
        return this._categoryFloorList;
    }

    set categoryFloorList(value) {
        this._categoryFloorList = value;
    }

    get recommendList() {
        return this._recommendList;
    }

    set recommendList(value) {
        this._recommendList = value;
    }
}

const store = new HomeStore()

export default store

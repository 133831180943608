import React from 'react';
import './index.scss';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Category1 = require('./img/category-1.png');
const Category2 = require('./img/category-2.png');
const Category3 = require('./img/category-3.png');
const Category4 = require('./img/category-4.png');
const Category5 = require('./img/category-5.png');
const Category6 = require('./img/category-6.png');
const Category7 = require('./img/category-7.png');
const Category8 = require('./img/category-8.png');

const Index = props => {
  const { merchant: store, app } = props;
  const history = useHistory();
  const location = useLocation();

  /**
   * 获取类别列表
   */
  useEffect(() => {
    store.fetchCategoryList();
  }, []);

  const showCategoryBg = index => {
    switch (index) {
      case 0:
        return Category1;
      case 1:
        return Category2;
      case 2:
        return Category3;
      case 3:
        return Category4;
      case 4:
        return Category5;
      case 5:
        return Category6;
      case 6:
        return Category7;
      case 7:
        return Category8;
      default:
        return Category1;
    }
  };

  const renderCategoryList = list => {
    return list.map((item, i) => {
      const { id, optName, icon, iconActive } = item;

      return (
        <div className='item' key={i}>
          <div className='category-block'>
            <div className='name'>{optName}</div>
            <img src={showCategoryBg(i)} alt='' className='bg-img' />
          </div>
          {/* <img src={icon} alt='' className='img' /> */}
        </div>
      );
    });
  };

  return (
    <div className='glx-merchant-index'>
      <div className='floor1'>
        <img
          src={require('images/merchant/index/img-floor-1.png')}
          alt=''
          className='content-img'
        />
        <img
          src={require('images/merchant/index/click-btn.png')}
          alt=''
          className='click-img'
          onClick={() => {
            if (app.hasLogin) {
              store.query();
            } else {
              history.push({
                pathname: '/my/account/login',
                state: { from: location }
              });
            }
          }}
        />
      </div>
      <div className='floor2'>
        <img
          src={require('images/merchant/index/category-bg.png')}
          alt=''
          className='content-img'
        />
        <img
          src={require('images/merchant/index/category-title.png')}
          alt=''
          className='title-img'
        />
        <div className='container'>
          {renderCategoryList(store.categoryList)}
        </div>
      </div>
      <div className='floor3'>
        <img
          src={require('images/merchant/index/img-floor-3.png')}
          alt=''
          className='content-img'
        />
      </div>
      <div className='bottom-block'>
        <img
          src={require('images/merchant/index/bottom-cloud.png')}
          alt=''
          className='bg-img'
        />
        <button
          className='btn'
          onClick={() => {
            if (app.hasLogin) {
              store.query();
            } else {
              history.push({
                pathname: '/my/account/login',
                state: { from: location }
              });
            }
          }}
        >
          <div className='bg-wrapper'>
            <img
              src={require('images/merchant/index/start-btn.png')}
              alt=''
              className='bg'
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export default inject('merchant', 'app')(observer(Index));

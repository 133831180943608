import useDidMount from "beautiful-react-hooks/useDidMount";
import * as Api from "utils/api/api";
import {useEffect} from "react";

function useImUser({imUserId,setImUserId,userStore,store}){
    useEffect(() => {
        if (!imUserId) {
            return;
        }
        Api.imUserInfo({imUserId}).then(imUser => {
            store.updateImUser(imUser)
        })
    }, [imUserId])


    // 更新 imUser信息
    const updateImUser = async () => {

        if (userStore.isLogin && !store.imUser.userId) {
            const userInfo = await Api.userInfo().then((data) => data.info);

            Api.modifyImUser({
                userId: userInfo.id,
                imUserId,
                nickname: userInfo.nickname,
                avatar: userInfo.avatar,
                gender: userInfo.gender
            }).then(imUser => {
                // debugger
                store.updateImUser(imUser)
            })
        }
    };

    useDidMount(()=>{
        updateImUser().then()

        // 如果 session 里没有 imUserId 则创建一个 imUser
        if (!imUserId) {
            Api.addImUser().then(data => {
                const innerImUserId = data.id;
                setImUserId(innerImUserId)
            });
        }
    })
}

export default useImUser
import React from 'react';
import 'styles/track.scss';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import ShowDate from 'utils/show-date';
import showPrice from 'utils/show-price';
import { PullToRefresh } from 'antd-mobile';
import { useCallback } from 'react';
// import { DatePicker } from 'antd-mobile';

const Track = props => {
  let { track: store } = props;
  let history = useHistory();

  /**
   * 获取数据
   */
  const fetchMap = useCallback(() => {
    store.fetchMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.pageSize]);
  useEffect(() => {
    fetchMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMap]);

  function toDetail(id, pddShop) {
    history.push({
      pathname: `/detail/${id}`,
      search: `?type=${pddShop === true ? 1 : 0}`
    });
  }

  function renderMap() {
    const domList = [];

    for (let key in store.map) {
      let list = store.map[key];

      domList.push(
        <div className='day-item' key={key}>
          <div className='title'>{ShowDate(new Date(key))}</div>
          <div className='container'>
            {list.map((item, i) => {
              let {
                id,
                goodsId,
                time,
                name,
                mainPic,
                currentPrice,
                pddShop
              } = item;

              return (
                <div
                  key={id}
                  className='item'
                  onClick={() => {
                    toDetail(id, pddShop);
                  }}
                >
                  <div className='img-block'>
                    <img src={mainPic} alt='' className='img' />
                  </div>
                  <div className='info-block'>
                    <div className='name-floor'>{name}</div>
                    <div className='price-floor'>{showPrice(currentPrice)}</div>
                    <div className='dot-floor'>
                      <div
                        className={`dots-wrapper ${item.showHandle &&
                          'active'}`}
                        onClick={e => {
                          e.stopPropagation();
                          if (item.showHandle) {
                            item.showHandle = false;
                            store.showMask = false;
                          } else {
                            store.initShowHandle();
                            item.showHandle = true;
                            store.showMask = true;
                          }
                        }}
                      >
                        <img
                          src={require('images/track/dots.png')}
                          alt=''
                          className='dots'
                        />

                        {item.showHandle && (
                          <div className='handle'>
                            <img
                              src={require('images/track/handle-bg.png')}
                              alt=''
                              className='bg-img'
                            />
                            <div className='item'>
                              <img
                                src={require('images/track/cart.png')}
                                alt=''
                                className='icon'
                              />
                              <div className='text'>加入购物车</div>
                            </div>
                            <div className='item'>
                              <img
                                src={require('images/track/collect.png')}
                                alt=''
                                className='icon'
                              />
                              <div className='text'>收藏</div>
                            </div>
                            <div className='item'>
                              <img
                                src={require('images/track/delete.png')}
                                alt=''
                                className='img'
                              />
                              <div className='text'>删除</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return domList;
  }

  return (
    <div className='track'>
      <div className='head'>
        <div className='title-floor'>
          <div
            className='back'
            onClick={() => {
              history.goBack();
            }}
          >
            <img
              src={require('images/track/back.png')}
              alt=''
              className='img'
            />
          </div>
          <div className='text'>我的足迹</div>
        </div>
        <div className='condition-floor'>
          {/* <DatePicker
            mode='date'
            title='选择日期'
            extra='Optional'
            // value={store.date}
            onChange={date => (store.date = date)}
          >
            选择日期
          </DatePicker> */}
        </div>
      </div>
      <PullToRefresh
        className='shops-content-wrapper'
        damping={60}
        direction='up'
        refreshing={store.refreshing}
        onRefresh={() => {
          store.pageSize += store.PAGE_SIZE;
        }}
      >
        <div className='map-wrapper'>{renderMap()}</div>
      </PullToRefresh>
      {store.showMask && <div className='mask'></div>}
    </div>
  );
};

export default inject('track')(observer(Track));

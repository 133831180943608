import React, {useEffect} from 'react';
import 'styles/cart.scss';
import {inject, observer} from 'mobx-react';
import Nav from 'components/BottomNav';
import showPrice from 'utils/show-price';
import * as antd from 'antd';
import * as api from 'utils/api/api';
import {useHistory} from 'react-router-dom';

const CartContainer = inject('cart')(observer(({cart: store}) => {
    return <div className='cart-container'>
        {
            store.list.map((item, i) => {
                const {
                    id: cartId,
                    goodsId,
                    productId: skuId,
                    number,
                    name,
                    skuName,
                    mainPic,
                    currentPrice,
                    goodsTotalPrice,
                    checked,
                    active,
                    skuList,
                    specificationNameAndValues
                } = item;

                return (
                    <div className='item' key={cartId}>
                        <div
                            className={`dot ${active && 'active'}`}
                            onClick={() => {
                                store.modifyActive(i, !active);
                            }}
                        />
                        <div className='img-block'>
                            <img src={mainPic} alt='' className='img'/>
                        </div>
                        <div className='info-block'>
                            <div className='name-floor'>{skuName || name}</div>
                            <div className='specification-floor'>
                                {specificationNameAndValues.map(item => item.value).join(' ')}
                            </div>
                            <div className='price-floor'>
                                <div className='current-price'>{showPrice(goodsTotalPrice)}</div>
                                <div className='num-block'>
                                    <button
                                        className={`subtract ${number <= 1 && 'disable'}`}
                                        onClick={() => {
                                            if (number <= 1) {
                                                return;
                                            }

                                            store.modifyGoodsCount(cartId, goodsId, skuId, number - 1);
                                        }}
                                    >
                                        -
                                    </button>
                                    <input
                                        type='text'
                                        className='input-num'
                                        value={number}
                                        readOnly
                                    />
                                    <button
                                        className='add'
                                        onClick={() => {
                                            store.modifyGoodsCount(cartId, goodsId, skuId, number + 1);
                                        }}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
        }
    </div>
}))

const Cart = ({cart: store}) => {
    const history = useHistory();

    /**
     * 获取购物车信息
     */
    useEffect(() => {
        store.init();
        store.fetchInfo();
    }, []);


    function createOrder() {
        if (store.activeCount === 0) {
            antd.message.info("请选择商品");
            return;
        }

        const orderProductList = store.activeList.map(item => ({productId: item.productId, count: item.number}));
        api.addOrder({orderProductList}).then((id) => {
            api.deleteCartByOrderId({orderId: id}).then(() => {
                antd.message.success("提交成功");
                history.push({
                    pathname: `/orders/pay/${id}`,
                });
            })
        });
    }

    return (
        <div className='glx-cart'>
            <div className='head'>
                <div className='title-block'>
                    <img src={require('images/cart/title.png')} alt='' className='img'/>
                </div>
                <div className='handle-block'>
                    <div
                        className='text'
                        onClick={() => {
                            store.switchEdit();
                        }}
                    >
                        {store.edit ? '取消' : '编辑'}
                    </div>
                </div>
            </div>
            <CartContainer/>
            <div className='handle-block'>
                <div
                    className='select-block'
                    onClick={() => {
                        store.modifyAllActive(!store.allActive);
                    }}
                >
                    <div className={`dot ${store.allActive && 'active'}`}></div>
                    <div className='text'>全选</div>
                </div>

                {store.edit ? (
                    <React.Fragment>
                        <button
                            className={`delete-btn ${store.activeCount > 0 && 'delete-btn-active'}`}
                            onClick={() => {
                                if (store.activeCount === 0) {
                                    return;
                                }
                                store.deleteCart(store.activeList.map(item => item.id));
                            }}
                        >
                            删除
                        </button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className='info-block'>
                            <div className='top'>选中{store.activeCount}件商品</div>
                            <div className='bottom'>
                                共计：
                                <span className='price'>
                  {showPrice(store.activeTotalPrice)}
                </span>
                            </div>
                        </div>
                        <button className='pay-btn' onClick={createOrder}>
                            生成订单
                        </button>
                    </React.Fragment>
                )}
            </div>
            <Nav/>
        </div>
    );
};

export default inject('cart')(observer(Cart));

import React from 'react';
import './add-category.scss';
import { inject, observer } from 'mobx-react';
import Panel from './partials/panel/Panel';
import { useState } from 'react';

const AddCategory = props => {
  const { className, merchant } = props;
  const { shop2 } = merchant;
  const [showPanel, setShowPanel] = useState(false);

  const renderCategoryList = list => {
    return list.map((item, i) => {
      const { id, value, active, parentId, parentValue } = item;

      return (
        <div className='floor' key={id}>
          <div className='item'>{i + 1}</div>
          <div className='item'>{parentValue}</div>
          <div className='item'>{value}</div>
          <div
            className='item delete'
            onClick={() => {
              shop2.deleteChildCategory({ id, parentId });
            }}
          >
            删除
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`glx-merchant-shop2-add-category ${className}`}>
      <div className='title-floor'>
        <span className='title'>详细类目</span>
        <button
          className='add-btn'
          onClick={() => {
            setShowPanel(true);
          }}
        >
          添加
        </button>
      </div>
      <div className='table-header'>
        <div className='item'>序号</div>
        <div className='item'>1级类目</div>
        <div className='item'>2级类目</div>
        <div className='item'>操作</div>
      </div>
      <div className='table-body'>
        {renderCategoryList(shop2.selectedCategoryList)}
      </div>

      {/* panel */}
      {showPanel && (
        <div className='panel-mask'>
          <Panel
            className='panel'
            onClose={() => {
              setShowPanel(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default inject('merchant')(observer(AddCategory));

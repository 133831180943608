import React from 'react';
import { observer, inject } from 'mobx-react';
import { useEffect } from 'react';
import Index from 'partials/merchant/index/Index';
import NeedKnow from 'partials/merchant/need-know/NeedKnow';
import Company from 'partials/merchant/company/Company';
import Company2 from 'partials/merchant/company-2/Company2';
import Shop from 'partials/merchant/shop/Shop';
import Shop2 from 'partials/merchant/shop-2/Shop2';
import Shop3 from 'partials/merchant/shop-3/Shop3';
import Wait from 'partials/merchant/wait/Wait';
import Complete from 'partials/merchant/complete/Complete';

const Merchant = props => {
  const { merchant: store } = props;

  useEffect(() => {
    store.fetchMerchantStatus();
  }, []);

  return (
    <div className='merchant'>{renderContent(store.currentStepIndex)}</div>
  );
};

export default inject('merchant')(observer(Merchant));

const renderContent = currentStepIndex => {
  switch (currentStepIndex) {
    case 0:
      return <Index />;
    case 1:
      return <NeedKnow />;
    case 2:
      return <Company />;
    case 3:
      return <Company2 />;
    case 4:
      return <Shop />;
    case 5:
      return <Shop2 />;
    case 6:
      return <Shop3 />;
    case 7:
      return <Wait />;
    case 8:
      return <Complete />;
    default:
      return null;
  }
};

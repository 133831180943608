import React, {useRef} from 'react'
import './video-list.scss'
import {useHistory} from 'react-router-dom'
import {useDidMount} from "beautiful-react-hooks";
import Swiper from "swiper";
import {observer} from "mobx-react";

const VideoList = ({videoList}) => {

    const history = useHistory();
    const swiperRef = useRef(null);

    useDidMount(() => {
        new Swiper(swiperRef.current, {
            slidesPerView: 'auto'
        });
    })

    return (
        <div className="glx-live__video-list">
            <div className="title-container">
                <img src={require('./img/title.png')} alt="" className="title-img"/>
                <div className="more-wrapper" onClick={() => {
                    history.push({
                        pathname: '/videos'
                    })
                }}>
                    <div className="text">更多</div>
                    <img src={require('./img/more-icon.png')} alt="" className="more-icon"/>
                </div>
            </div>

            <div className="swiper-container" ref={swiperRef}>
                <div className="swiper-wrapper">
                    {
                        videoList.map(item => (
                            <div className="swiper-slide" key={item.id}>
                                <div className="video-wrapper" onClick={() => {
                                    history.push({
                                        pathname: '/play-video',
                                        search: `?id=${item.id}`
                                    })
                                }}>
                                    <img src={item.videoFrame} alt="" className="poster"/>
                                    <img src={require('./img/play-icon.png')} alt="" className="play-icon"/>
                                    <div className="title-container">
                                        <div className="title">{item.title}</div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(VideoList)
import React from "react";
import {observer} from "mobx-react";
import css from "./avatarFloor.module.scss";
import classNames from "classnames";
import useSessionStorage from "beautiful-react-hooks/useSessionStorage";
import {anchorDefaultAvatar} from "../../utils/api/commonApi";
import useMyStores from "../../hooks/useMyStores";

const Index = ({store, defaultAvatar, onClickHomeIcon, openUserPanel}) => {

    // 是否关注
    const [follow, setFollow] = useSessionStorage("follow", false)

    const {constant: constantStore} = useMyStores();

    return (
        <div className={css.avatarFloor}>
            {/*主页图标*/}
            <div className={css.homeWrapper} onClick={onClickHomeIcon}>
                <img src={require('./img/主页icon.png')} className={css.icon}/>
            </div>

            {/*主播头像等*/}
            <div className={css.anchorWrapper}>
                <img src={store.imUser.avatar || anchorDefaultAvatar} className={css.avatar}/>

                <div className={css.center}>
                    <div className={css.floor1}>
                        <img src={require('./img/直播间标题icon.png')} className={css.icon}/>
                        <div className={css.title}>{constantStore.app?.websiteName}</div>
                    </div>
                    <div className={css.floor2}>
                        <img src={require('./img/热度icon.png')} className={css.icon}/>
                        <div className={css.value}>{store.heat}</div>
                    </div>
                </div>
                {/*关注按钮*/}
                <div
                    className={classNames(css.followWrapper, {
                        [css.follow]: follow,
                        [css.unfollow]: !follow
                    })}
                    onClick={() => {
                        setFollow(!follow)
                    }}
                >
                    {
                        follow ? '已关注' : '关注'
                    }
                </div>
            </div>

            {/*观众头像*/}
            {
                store.imUserList.length &&
                <div className={css.memberAvatarList}>
                    <div className={css.list}>
                        {
                            store.imUserList.slice(0, 3).map((item) => {
                                return (
                                    <img key={item.id} src={item.avatar || defaultAvatar} alt=''
                                         className={css.avatar}/>
                                )
                            })
                        }
                    </div>
                    <div className={css.count} onClick={openUserPanel}>
                        {store.imUserList.length}
                    </div>
                </div>
            }

        </div>
    )
}

export default observer(Index)
const VideoTypes = [
  {
    name: '全部视频'
  }
  //   {
  //     name: '推荐',
  //     keyword: 'recommend'
  //   },
  //   {
  //     name: '科技',
  //     keyword: 'tech'
  //   },
  //   {
  //     name: '体育',
  //     keyword: 'sport'
  //   },
  //   {
  //     name: '娱乐',
  //     keyword: 'enjoy'
  //   },
  //   {
  //     name: '美食',
  //     keyword: 'food'
  //   },
  //   {
  //     name: '旅游',
  //     keyword: 'tourism'
  //   }
];

export default VideoTypes;

import React, {useEffect, useMemo} from 'react'
import OrderContact from '../order-contact/OrderContact'
import Price from 'components/common/price/Price'

import ShopNameIcon from './image/shopName.png'
import EnterIcon from './image/enter.png'

import './order-goods.scss'
import {inject, observer} from "mobx-react";
import showPrice from "utils/show-price";

const OrderGoods = ({orderSku}) => {

    return (
        <div className='order-goods'>
            <div className='order-goods-title'>
                <img className='order-goods-title__icon' src={ShopNameIcon}/>
                <span className='order-goods-title__name'>{orderSku.shopName}</span>
                <img className='order-goods-title__enter' src={EnterIcon}/>
                {
                    !orderSku.isSelf &&
                    <div className="self">平台自营</div>
                }
            </div>
            <div className='order-goods-body'>
                <img className='order-goods-body__img' src={orderSku?.mainPic}/>
                <div className='order-goods-body__info'>
                    <p className='order-goods-body__name'>{orderSku?.skuName}</p>
                    <p className='order-goods-body__desc'>
                        <span className='order-goods-body__num'>数量:{orderSku?.count} </span>
                        <span className='order-goods-body__spec'>
                            {orderSku?.specificationNameAndValueList.map(({id, name, value}) => (
                                <span key={id}>{name}:{value} </span>
                            ))}
                        </span>
                    </p>
                    <p className='order-goods-body__price'>
                        {orderSku?.price && <Price price={orderSku?.price}/>}
                    </p>
                </div>
            </div>
            {/*<OrderContact/>*/}
        </div>
    )
}

export default OrderGoods;
// export default inject('order')(observer(OrderGoods));

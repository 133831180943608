import {
    LIVE_BASE_URL,
    MINI_BASE_URL,
    ONLINE,
    Header,
    ContentType,
    GetFormData,
    Method,
    http,
} from "./api-utils";

/**
 * 获取im的用户签名
 * @param userId userId
 */
export const imUserSign = ({userId}) => {
    return http({
        method: Method.GET,
        url: LIVE_BASE_URL + '/im/user-sign',
        params: {
            userId
        }
    }).then(res => res.data)
}

/**
 * 删除im用户账户
 * @param userId 用户id
 * @returns {AxiosPromise}
 */
export const imDeleteAccount = ({userId}) => {
    return http({
        method: Method.DELETE,
        url: LIVE_BASE_URL + `/im/account/${userId}`
    })
}
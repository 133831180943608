import {observable, computed, action, autorun, reaction, makeAutoObservable} from 'mobx';
import * as Api from 'utils/api/api';
import GoodsType from 'enum/GoodsType';
import CollectEnum from 'enum/CollectEnum';

class ShopStore {
  @observable _shopId = 0;
  @observable _pageSize = 16;
  @observable _pageNum = 1;
  @observable _type = 0;
  @observable _list = [];
  @observable _shopInfo = null;
  @observable _hasCollect = false;
  @observable _collectId = 0;
  @observable refreshing = false;

  constructor() {
    makeAutoObservable(this)
  }
  get shopId() {
    return this._shopId;
  }
  set shopId(value) {
    this._shopId = value;
  }
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value) {
    this._pageSize = value;
  }
  get pageNum() {
    return this._pageNum;
  }
  set pageNum(value) {
    this._pageNum = value;
  }
  get type() {
    return this._type;
  }
  set type(value) {
    this._type = value;
  }
  get list() {
    return this._list;
  }
  set list(value) {
    this._list = value;
  }
  get shopInfo() {
    return this._shopInfo;
  }
  set shopInfo(value) {
    this._shopInfo = value;
  }
  get hasCollect() {
    return this._hasCollect;
  }
  set hasCollect(value) {
    this._hasCollect = value;
  }
  get collectId() {
    return this._collectId;
  }
  set collectId(value) {
    this._collectId = value;
  }

  @action async fetchInfo() {
    try {
      this._refreshing = false;
      const { pageNum, pageSize, shopId, type } = this;
      let pddShop = type === GoodsType.PDD;

      const {
        list,
        shopInfo,
        totalNum,
        collectId,
        hasCollect
      } = await Api.shopInfo({ pageNum, pageSize, shopId, pddShop });

      this._list = list;
      this._shopInfo = shopInfo;
      this._collectId = collectId;
      this._hasCollect = hasCollect;
    } catch (error) {

    } finally {
      this._refreshing = true;
    }
  }

  @action async switchCollect() {
    try {
      const { hasCollect, collectId, shopId } = this;

      if (hasCollect) {
        await Api.cancelCollect({
          collectId
        });
        this._hasCollect = false;
      } else {
        const { collectId: cid } = await Api.addCollect({
          id: shopId,
          type: CollectEnum.SHOP
        });

        this._hasCollect = true;
        this._collectId = cid;
      }
    } catch (error) {}
  }
}

const store = new ShopStore();

export default store;

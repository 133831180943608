import React from 'react';
import './goods-list.scss';
import { inject, observer } from 'mobx-react';
import showPrice from 'utils/show-price';
import { useHistory } from 'react-router-dom';

function GoodsList(props) {
  const { search: store } = props;
  let history = useHistory();

  function toDetail(id, pddShop) {
    history.push({
      pathname: `/detail/${id}`,
      search: `?type=${pddShop === true ? 1 : 0}`
    });
  }

  function renderGoodsList() {
    return store.goodsList.map((item, i) => {
      const {
        id,
        name,
        originPrice,
        currentPrice,
        shopId,
        soldNum,
        mainPic,
        hasCollect,
        collectId,
        categoryId,
        isHot,
        isNew,
        isSpecialPrice,
        serviceTags,
        mallName,
        pddShop
      } = item;

      return (
        <div
          className='item'
          key={id}
          onClick={() => {
            toDetail(id, pddShop);
          }}
        >
          <div className='img-block'>
            <img src={mainPic} alt='' className='img' />
          </div>
          <div className='info-block'>
            <div className='name-floor'>{name}</div>
            <div className='price-floor'>{showPrice(currentPrice)}</div>
            <div className='self-floor'>
              {!pddShop && <div className='self'>自营</div>}
              {isHot === 1 && <div className='hot'>热卖</div>}
            </div>
            <div className='sold-floor'>
              <div className='sold-num'>已售{soldNum}</div>
            </div>
          </div>
        </div>
      );
    });
  }

  function renderGoodsBlockList() {
    return store.goodsList.map((item, i) => {
      const {
        id,
        name,
        originPrice,
        currentPrice,
        shopId,
        soldNum,
        mainPic,
        hasCollect,
        collectId,
        categoryId,
        isHot,
        isNew,
        isSpecialPrice,
        serviceTags,
        mallName,
        pddShop
      } = item;
      return (
        <div
          className='item'
          key={i}
          onClick={() => {
            toDetail(id, pddShop);
          }}
        >
          <div className='img-block'>
            <img src={mainPic} alt='' className='img' />
          </div>
          <div className='info-block'>
            <div className='name-floor'>{name}</div>
            <div className='price-floor'>
              <div className='current-price'>{showPrice(currentPrice)}</div>
              {!pddShop && <div className='self'>自营</div>}
              {isHot === 1 && <div className='hot'>热卖</div>}
            </div>
            <div className='sold-floor'>
              <div className='sold-num'>销量{soldNum}</div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className='goods-list'>
      {store.listType === store.listTypeEnum.LIST ? (
        <div className='list-list'>{renderGoodsList()}</div>
      ) : (
        <div className='block-list'>{renderGoodsBlockList()}</div>
      )}
    </div>
  );
}

export default inject('search')(observer(GoodsList));

import React, {useEffect, useRef} from "react";
import css from 'styles/home/homeLookAround.module.scss'
import {observer} from "mobx-react";
import GoodsType, {goodsTypeEnumMap} from "enum/GoodsType";
import classNames from "classnames";
import showPrice from "utils/show-price";
import MyRouterUtil from "utils/MyRouterUtil";
import {useHistory} from "react-router-dom";

const Index = ({lookAroundStore}) => {
    const {loading, canLoadMoreData, list} = lookAroundStore
    const goodsListContainer = useRef()

    const history = useHistory();

    const onScroll = e => {
        const rect = goodsListContainer.current.getBoundingClientRect()
        const top = rect.top
        const height = rect.height
        const clientHeight = document.body.clientHeight
        if (clientHeight - top >= height && lookAroundStore.canLoadMoreData && !lookAroundStore.loading) {
            lookAroundStore.fetchData()
        }
    }

    useEffect(() => {
        window.addEventListener('touchmove', onScroll)
        return () => {
            window.removeEventListener('touchmove', onScroll)
        }
    }, [])

    const toGoodsInfo = ({goodsType, id}) => {
        MyRouterUtil.build({history}).toGoodsInfo({goodsType, id})
    }

    return (
        <div className={css.index}>
            <div className={css.container}>
                <div className={css.titleFloor}>
                    <div className={css.title}>随便看看</div>
                </div>
                <div className={css.goodsListContainer} ref={goodsListContainer}>
                    <div className={css.goodsList}>
                        {
                            list.map((item, i) => {
                                const {id, mainPic, name, originPrice, currentPrice, isHot, isPddShop, isJdShop} = item;
                                const isSelf = !isPddShop && !isJdShop

                                let goodsType;
                                if (isPddShop) {
                                    goodsType = GoodsType.PDD
                                } else if (isJdShop) {
                                    goodsType = GoodsType.JD
                                } else {
                                    goodsType = GoodsType.SELF
                                }

                                return (
                                    <div
                                        key={i}
                                        className={css.goods}
                                        onClick={() => toGoodsInfo({goodsType, id})}
                                    >
                                        <div className={css.top}>
                                            <div className={css.imgWrapper}>
                                                <img src={mainPic} alt=""/>
                                            </div>
                                        </div>
                                        <div className={css.bottom}>
                                            <div className={css.nameWrapper}>
                                                <div className={css.name}>
                                            <span className={classNames({
                                                [css.self]: goodsType === GoodsType.SELF,
                                                [css.pdd]: goodsType === GoodsType.PDD,
                                                [css.jd]: goodsType === GoodsType.JD
                                            })}>{goodsTypeEnumMap.get(goodsType)}</span>
                                                    {name}
                                                </div>
                                            </div>
                                            <div className={css.priceFloor}>
                                                <div className={css.price}>
                                                    {showPrice(currentPrice)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Index)
import React, { useCallback, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
import useSecKill from 'hooks/useSecKill'
import 'styles/flash-sale.scss'
import { useHistory } from 'react-router-dom'
import showPrice from 'utils/show-price'
import { PullToRefresh } from 'antd-mobile'

const BannerImg = require('images/flash-sale/banner.png')

const FlashSale = props => {
  const { flashSale: store } = props
  const secKill = useSecKill()
  const history = useHistory()

  const [startTimeList, setStartTimeList] = useState([])
  const [hour, setHour] = useState(0)
  const [minute, setMinute] = useState(0)
  const [second, setSecond] = useState(0)

  useEffect(() => {
    let list = []
    list.push(secKill.startTime)

    for (let i = 0; i < 4; i++) {
      let time = new Date(list[list.length - 1].getTime())

      time.setHours(time.getHours() + 2)

      list.push(time)
    }

    setStartTimeList(list)
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      const time = secKill.getTime()

      setHour(time.hour)
      setMinute(time.minute)
      setSecond(time.second)
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [secKill])

  /**
   * 初始化
   */
  useEffect(() => {
    store.init()
  }, [])
  /**
   * 获取商品列表
   */
  const fetchList = useCallback(() => {
    store.fetchList()
  }, [store.pageSize, store.pageNum])
  useEffect(() => {
    fetchList()
  }, [fetchList])

  function renderHeaderRight() {
    return startTimeList.map((item, index) => {
      if (index === 0) {
        return null
      }

      return (
        <div className='item'>
          <div className='top'>
            {item.getHours() === 0 ? '明日' : ''}
            {item.getHours()}:00
          </div>
          <div className='bottom'>即将开始</div>
        </div>
      )
    })
  }

  function renderTimeList() {
    return startTimeList.map((item, i) => {
      return (
        <div className={`item ${i === 0 && 'current'}`}>
          <div className='time'>
            {item.getHours() === 0 ? '明日' : ''}
            {item.getHours()}:00
          </div>
          <div className='text'>{i === 0 ? '抢购中' : '即将开始'}</div>
        </div>
      )
    })
  }

  function toDetail(id, pddShop) {
    history.push({
      pathname: `/detail/${id}`,
      search: `?type=${pddShop === true ? 1 : 0}`
    })
  }

  function renderGoodsList() {
    return store.list.map((item, i) => {
      const {
        id,
        startTime,
        endTime,
        goodsName,
        originPrice,
        currentPrice,
        mainPic,
        totalStocks,
        secKillPrice,
        shopId,
        pddShop,
        progress
      } = item
      return (
        <div className='item' key={id}>
          <div className='img-block'>
            <img src={mainPic} alt='' className='img' />
          </div>
          <div className='info-block'>
            <div className='name-floor'>{goodsName}</div>
            <div className='price-floor'>
              <div className='label'>秒杀价：</div>
              <div className='value'>{showPrice(secKillPrice)}</div>
            </div>
            <div className='handle-floor'>
              <div className='current-price'>{showPrice(currentPrice)}</div>
              <button
                className='to-info-btn'
                onClick={() => {
                  toDetail(id, pddShop)
                }}
              >
                去抢购
              </button>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <div className='glx-flash-sale'>
      <div className='top'>
        <div className='head'>
          <div
            className='back'
            onClick={() => {
              history.goBack()
            }}
          >
            <img
              src={require('images/flash-sale/back.png')}
              alt=''
              className='img'
            />
          </div>
          <img
            src={require('images/flash-sale/title.png')}
            alt=''
            className='title-img'
          />
        </div>
        <div className='banner'>
          <img src={BannerImg} alt='' className='img' />
        </div>
      </div>
      <div className='time-floor'>
        <div className='current-img'>
          <div className='bg'></div>
        </div>
        {renderTimeList()}
      </div>
      <div className='left-time-floor'>
        <div className='bg'></div>
        <img
          src={require('images/flash-sale/clock.png')}
          alt=''
          className='lock-img'
        />
        <div className='title'>本场剩余时间</div>
        <div className='time'>
          {hour}:{minute}:{second}
        </div>
      </div>
      <div className='glx-flash-sale-goods'>
        <PullToRefresh
          damping={60}
          direction='up'
          refreshing={store.refreshing}
          onRefresh={() => {
            store.pageSize += store.PAGE_SIZE
          }}
          style={{
            height: '100%',
            overflow: 'auto'
          }}
        >
          <div className='goods-list-container'>{renderGoodsList()}</div>
        </PullToRefresh>
      </div>
    </div>
  )
}

export default inject('flashSale')(observer(FlashSale))

import * as Api from 'utils/api/api';
import {action, makeAutoObservable, observable} from 'mobx';

class NewsInfo {
  @observable _title = '';
  @observable _content = '';
  @observable _publishTime = '';
  @observable _updateTime = '';
  @observable _source = '';
  @observable _author = '';
  @observable _watchTimes = '';
  @observable _thumbnail = '';
  @observable _categoryId = '';
  @observable _categoryName = '';
  @observable _pre = '';
  @observable _next = '';
  @observable _recommendList = [];

  constructor() {
    makeAutoObservable(this)
  }
  get title() {
    return this._title;
  }
  set title(value) {
    this._title = value;
  }
  get content() {
    return this._content;
  }
  set content(value) {
    this._content = value;
  }
  get publishTime() {
    return this._publishTime;
  }
  set publishTime(value) {
    this._publishTime = value;
  }
  get updateTime() {
    return this._updateTime;
  }
  set updateTime(value) {
    this._updateTime = value;
  }
  get source() {
    return this._source;
  }
  set source(value) {
    this._source = value;
  }
  get author() {
    return this._author;
  }
  set author(value) {
    this._author = value;
  }
  get watchTimes() {
    return this._watchTimes;
  }
  set watchTimes(value) {
    this._watchTimes = value;
  }
  get thumbnail() {
    return this._thumbnail;
  }
  set thumbnail(value) {
    this._thumbnail = value;
  }
  get categoryId() {
    return this._categoryId;
  }
  set categoryId(value) {
    this._categoryId = value;
  }
  get categoryName() {
    return this._categoryName;
  }
  set categoryName(value) {
    this._categoryName = value;
  }
  get pre() {
    return this._pre;
  }
  set pre(value) {
    this._pre = value;
  }
  get next() {
    return this._next;
  }
  set next(value) {
    this._next = value;
  }
  get recommendList() {
    return this._recommendList;
  }
  set recommendList(value) {
    this._recommendList = value;
  }

  @action init() {
    this._title = '';
    this._content = '';
    this._publishTime = '';
    this._updateTime = '';
    this._source = '';
    this._author = '';
    this._watchTimes = '';
    this._thumbnail = '';
    this._categoryId = '';
    this._categoryName = '';
    this._pre = '';
    this._next = '';
  }

  @action async fetchInfo(id) {
    try {
      const { info } = await Api.newsInfo({ id });
      const {
        title,
        content,
        publishTime,
        updateTime,
        source,
        author,
        watchTimes,
        thumbnail,
        categoryId,
        categoryName,
        pre,
        next
      } = info;

      this._title = title;
      this._content = content;
      this._publishTime = publishTime;
      this._updateTime = updateTime;
      this._source = source;
      this._author = author;
      this._watchTimes = watchTimes;
      this._thumbnail = thumbnail;
      this._categoryId = categoryId;
      this._categoryName = categoryName;
      this._pre = pre;
      this._next = next;
    } catch (error) {}
  }
  @action async fetchRecommendList() {
    try {
      try {
        const { list } = await Api.newsList({
          pageNum: 2,
          pageSize: 4
        });

        this._recommendList = list;
      } catch (error) {}
    } catch (error) {}
  }
}

const store = new NewsInfo();

export default store;

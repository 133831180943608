import {useRequest} from "ahooks";
import * as api from 'utils/api/api'

export default function useFetchDefaultLive() {
    const {data, loading, run} = useRequest(
        () => api.defaultLive()
    )

    return {
        data,
        loading,
        run
    }
}
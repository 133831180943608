import React, {useMemo} from 'react'

import './address-item.scss'
import {useHistory} from "react-router-dom";

const AddressItem = ({address,onSelect}) => {

    const history = useHistory();

    const fullAddress = useMemo(() => {
        return address.province + address.city + address.district + address.detailInfo;
    }, [address]);

    return (
        <div className='address-item' onClick={()=>{
            onSelect(address.id);
        }}>
            <p className='row'>
                <span className='address-item__name'>{address.name}</span>
                <span className='address-item__phone'>{address.telNumber}</span>
                {address.isDefault && <span className='address-item__is-default'>默认</span>}
            </p>
            <p className='row'>
                <span className='address-item__detail'>{fullAddress}</span>
            </p>
        </div>
    )
}

export default AddressItem
